// import {getBannerData} from '../../redux/action/topbarAction'
import {connect} from 'react-redux'
import TopBanner from './TopBanner'


const mapStoreToProps = state => ({
  // bannerImage: state.topbar.topbars,
})
const mapDispatchToProps = dispatch => ({
  // getBannerData: ()=>dispatch(getBannerData()),
})

export default connect(mapStoreToProps,mapDispatchToProps)(TopBanner)