import serverCall from '../../modules/serverCall'

export const getfeaturedData=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_FEATURED_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/marketplace/featured-products`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_FEATURED_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_FEATURED_FAIL,
      payload: error
    })
    return error
  })
}

export const GET_FEATURED_BEGIN = 'GET_FEATURED_BEGIN'
export const GET_FEATURED_SUCCESS='GET_FEATURED_SUCCESS'
export const GET_FEATURED_FAIL='GET_FEATURED_FAIL'