import platform from 'platform';
import serverUrl from '../../configs/constant'

class Auth {
  constructor() {
    this.user_token = JSON.parse(localStorage.getItem('auth'))||{}
  }         
  getToken() {
    return localStorage.getItem('csa');
  }
  getAuthorizationToken() {
    console.log('auth');
      fetch(serverUrl.serverUrl+'/api/commen/user-token', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => response.json())
        .then(data => {
          if(data.status === '1'){
            var responce_data = data.token;
            // console.log('responce_data',responce_data);
            localStorage.setItem('csa',responce_data);
            
          }else if(data.status === '0'){
              // MySwal.fire('<p>'+data.msg+'</p>')
          }
          // console.log('Success:', data);
        })
        .catch((error) => {
          // console.error('Error:', error);
        });
  }
  getUserId() {
    return this.user_token.user_id
  }
  setUserToken(new_token) {
    this.user_token = new_token
    localStorage.setItem('auth', JSON.stringify(new_token))
  }
  logout() {
    localStorage.removeItem('auth') 
  }
  getLocation() {
    navigator.geolocation.getCurrentPosition(function(position) {
      var latitude = localStorage.getItem('latitude');
      var longitude = localStorage.getItem('longitude');
      if(latitude !== '' && latitude !== undefined && longitude !== '' && longitude !== undefined){
        if(latitude === position.coords.latitude && longitude === position.coords.longitude){

        }else{
          localStorage.setItem('latitude',position.coords.latitude);
          localStorage.setItem('longitude',position.coords.longitude);
          localStorage.setItem('devicebrowser',platform.name);
          localStorage.setItem('deviceos',platform.os.family);
          localStorage.setItem('devicetype','WEB');
        }

      }else{
        localStorage.setItem('latitude',position.coords.latitude);
        localStorage.setItem('longitude',position.coords.longitude);
        localStorage.setItem('devicebrowser',platform.name);
        localStorage.setItem('deviceos',platform.os.family);
        localStorage.setItem('devicetype','WEB');

      }
    });
  }
  getDevice(){
    navigator.geolocation.getCurrentPosition(function(position) {
      // console.log('position',navigator.userAgent);
      var latitude = localStorage.getItem('latitude');
      var longitude = localStorage.getItem('longitude');
      var userdevice = localStorage.getItem('userdevice');
        if(userdevice === '' || userdevice === undefined || userdevice === null){
          let requestdata= {};
            requestdata.devicetype= localStorage.getItem('devicetype');
            requestdata.deviceos= localStorage.getItem('deviceos');
            requestdata.devicebrowser= localStorage.getItem('devicebrowser');
            requestdata.latitude= localStorage.getItem('latitude');
            requestdata.longitude= localStorage.getItem('longitude');
            fetch(serverUrl.serverUrl+'/api/commen/user-device', {
              method: 'POST', // or 'PUT'
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestdata),
            })
            .then(response => response.json())
            .then(data => {
              if(data.status === '1'){
                var responce_data = data.deviceData;
                localStorage.setItem('userdevice',JSON.stringify(responce_data));
                localStorage.setItem('languageName',responce_data.languages_name);
                localStorage.setItem('countryLanguage',responce_data.languages_code);
                localStorage.setItem('api_type',responce_data.api_type);
                localStorage.setItem('selectedLanguage','en')
                localStorage.setItem('preselectedLanguage',responce_data.languages_code.toLowerCase())
                window.location.reload(false);
              }else if(data.status === '0'){
                  // MySwal.fire('<p>'+data.msg+'</p>')
              }
              // console.log('Success:', data);
            })
            .catch((error) => {
              // console.error('Error:', error);
            });
        }
      if(latitude !== '' && latitude !== undefined && longitude !== '' && longitude !== undefined){
        if(parseFloat(latitude) === position.coords.latitude && parseFloat(longitude) === position.coords.longitude){

        }else{
            let requestdata= {};
            requestdata.devicetype= localStorage.getItem('devicetype');
            requestdata.deviceos= localStorage.getItem('deviceos');
            requestdata.devicebrowser= localStorage.getItem('devicebrowser');
            requestdata.latitude= localStorage.getItem('latitude');
            requestdata.longitude= localStorage.getItem('longitude');
            fetch(serverUrl.serverUrl+'/api/commen/user-device', {
              method: 'POST', // or 'PUT'
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestdata),
            })
            .then(response => response.json())
            .then(data => {
              if(data.status === '1'){
                var responce_data = data.deviceData;
                localStorage.setItem('userdevice',JSON.stringify(responce_data));
                localStorage.setItem('languageName',responce_data.languages_name);
                localStorage.setItem('countryLanguage',responce_data.languages_code);
                localStorage.setItem('api_type',responce_data.api_type);
                localStorage.setItem('selectedLanguage','en')
                localStorage.setItem('preselectedLanguage',responce_data.languages_code.toLowerCase())
                window.location.reload(false);
              }else if(data.status === '0'){
                  // MySwal.fire('<p>'+data.msg+'</p>')
              }
              // console.log('Success:', data);
            })
            .catch((error) => {
              // console.error('Error:', error);
            });
        }

      }else{

      }
    });
  }
  getLocationUpdate(){
    let userdeviceToken = localStorage.getItem('api_type');
    if(userdeviceToken === '1'){
      console.log('in')
        this.getupdateData();
        return '1';
    }
  }
  getupdateData(){
    let requestdata= {};
    requestdata.devicetype= localStorage.getItem('devicetype');
    requestdata.deviceos= localStorage.getItem('deviceos');
    requestdata.devicebrowser= localStorage.getItem('devicebrowser');
    requestdata.latitude= localStorage.getItem('latitude');
    requestdata.longitude= localStorage.getItem('longitude');
    fetch(serverUrl.serverUrl+'/api/commen/user-device', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestdata),
    })
    .then(response => response.json() )
    .then(data => {
      if(data.status === '1'){
        var responce_data = data.deviceData;
        localStorage.setItem('userdevice',JSON.stringify(responce_data));
        localStorage.setItem('languageName',responce_data.languages_name);
        localStorage.setItem('countryLanguage',responce_data.languages_code);
        localStorage.setItem('api_type',responce_data.api_type);
      }else if(data.status === '0'){
          // MySwal.fire('<p>'+data.msg+'</p>')
      }
      // console.log('Success:', data);
    })
    .catch((error) => {
      // console.error('Error:', error);
    });
  }
}
export default new Auth()