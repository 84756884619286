import {
  POST_SIGNIN_BEGIN,
  POST_SIGNIN_SUCCESS,
  POST_SIGNIN_FAIL,
  POST_SIGNINVENDOR_BEGIN,
  POST_SIGNINVENDOR_SUCCESS,
  POST_SIGNINVENDOR_FAIL,
  POST_AFFILATE_BEGIN,
  POST_AFFILATE_SUCCESS,
  POST_AFFILATE_FAIL,
  GET_ALL_SIGNIN_BEGIN,
  GET_ALL_SIGNIN_SUCCESS,
  GET_ALL_SIGNIN_FAIL

} from '../action/signinAction'

const initialState = {
  signin_loading: false,
  signin_data: null,
  affliate_data: '',
  vendor_data: '',
  buyer_data: '',
  error: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_SIGNIN_BEGIN:
      return {
        ...state,
        signin_loading: true
      }
    case POST_SIGNIN_SUCCESS:
      return {
        ...state,
        signin_loading: false,
      }
    case POST_SIGNIN_FAIL:
      return {
        ...state,
        signin_loading: false,
        error: action.payload.error.response.data
      }   
    case POST_SIGNINVENDOR_BEGIN:
      return {
        ...state,
        signin_loading: true
      }
    case POST_SIGNINVENDOR_SUCCESS:
      return {
        ...state,
        vendor_data: action.payload.data,
      }
    case POST_SIGNINVENDOR_FAIL:
      return {
        ...state,
        signin_loading: false,
        error: action.payload.data
      }    
    case POST_AFFILATE_BEGIN:
      return {
        ...state,
        signin_loading: true
      }
    case POST_AFFILATE_SUCCESS:
      return {
        ...state,
        affliate_data: action.payload,
      }
    case POST_AFFILATE_FAIL:
      return {
        ...state,
        signin_loading: false,
        error: action.payload.error.response.data
      }
    case GET_ALL_SIGNIN_BEGIN:
      return {
        ...state,
        signin_loading: true
      }
    case GET_ALL_SIGNIN_SUCCESS:
      return {
        ...state,
        signin_loading: true,
        signin_data: action.payload.data
      }
    case GET_ALL_SIGNIN_FAIL:
      return {
        ...state,
        signin_loading: false,
        error: action.payload.error.response.data
      }
    default:
      return state
  }
}