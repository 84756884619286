import { getProductCountCity} from '../../redux/action/productAction'
import { connect } from 'react-redux'
import landingShop from './landingShop'
const mapStoreToProps = state => ({
  productCount: state.product.products,
})
const mapDispatchToProps = dispatch => ({
  getProductCountCity: (sendData)=>dispatch(getProductCountCity(sendData)),
  // applyFilters:(filter_string)=>dispatch(applyFilters(filter_string))
})

export default connect(mapStoreToProps, mapDispatchToProps)(landingShop)