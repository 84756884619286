import serverCall from '../../modules/serverCall'

export const getAdvanceSearch=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_ADVANCESEARCH_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/advance-search/advance-search`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_ADVANCESEARCH_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_ADVANCESEARCH_FAIL,
      payload: error
    })
    return error
  })
}

export const getAdvanceSearchClick=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_CLICK_ADVANCESEARCH_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/advance-search/advance-search-click`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_CLICK_ADVANCESEARCH_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_CLICK_ADVANCESEARCH_FAIL,
      payload: error
    })
    return error
  })
}

export const getAdvancesearchCity=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_CLICK_ADVANCESEARCH_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/commen/cities-list`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_CLICK_ADVANCESEARCH_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_CLICK_ADVANCESEARCH_FAIL,
      payload: error
    })
    return error
  })
}


export const GET_ADVANCESEARCH_BEGIN = 'GET_ADVANCESEARCH_BEGIN'
export const GET_ADVANCESEARCH_SUCCESS='GET_ADVANCESEARCH_SUCCESS'
export const GET_ADVANCESEARCH_FAIL='GET_ADVANCESEARCH_FAIL'

export const GET_CLICK_ADVANCESEARCH_BEGIN = 'GET_CLICK_ADVANCESEARCH_BEGIN'
export const GET_CLICK_ADVANCESEARCH_SUCCESS='GET_CLICK_ADVANCESEARCH_SUCCESS'
export const GET_CLICK_ADVANCESEARCH_FAIL='GET_CLICK_ADVANCESEARCH_FAIL'
