import serverCall from '../../modules/serverCall'

export const getViewSite=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_HEADER_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/header/site-view`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_HEADER_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_HEADER_FAIL,
      payload: {error}
    })
    return error
  })
}

export const getWishList=(requestdata)=>dispatch=>{
   dispatch({
    type:GET_WISH_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/header/whish-list-count`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_WISH_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_WISH_FAIL,
      payload: {error}
    })
    return error
  })
}

export const GET_HEADER_BEGIN = 'GET_HEADER_BEGIN'
export const GET_HEADER_SUCCESS='GET_HEADER_SUCCESS'
export const GET_HEADER_FAIL='GET_HEADER_FAIL'

export const GET_WISH_BEGIN = 'GET_WISH_BEGIN'
export const GET_WISH_SUCCESS='GET_WISH_SUCCESS'
export const GET_WISH_FAIL='GET_WISH_FAIL'