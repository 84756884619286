import React, { Component } from 'react'
import FooterContainer from '../../components/footer/footerContainer'
import HeaderContainer from '../../components/header/headerContainer'
import './stylesheets/verifyEmail.css'
import jumpTo,{go} from '../../modules/Navigation'
import {Redirect} from 'react-router-dom';
import Modal from "react-bootstrap/Modal"
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../configs/constant'


export default class verifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isOpen: true,
        selectedLanguage:localStorage.getItem('selectedLanguage'),
        preselectedLanguage:localStorage.getItem('preselectedLanguage'),
      };

    let requestdata = this.props.location.pathname;
    let requestdataMain = requestdata.split("/");
    this.props.getVerifyData(requestdataMain[2]);
  }

  openModal = () => this.setState({ isOpen: true });
  
  closeModal = () =>{ 
    this.setState({ isOpen: false });
    this.props.history.push('/landing-marketplace')
  }

  render() {
    const verifyemailData = this.props.verifyemailData;
    let msgData = '';

    if(verifyemailData !=='' && verifyemailData !== undefined && verifyemailData !==null){
      if(verifyemailData.status === 0 || verifyemailData.status === 1){
          msgData = verifyemailData.msg;
      }
    }

    return (
      <span>
      <HeaderContainer />
      <Translator 
          from={this.state.preselectedLanguage}
          to={this.state.selectedLanguage}
          googleApiKey={serverUrl.googleApiTranslation}
        >
       <div className="email_verify_wrapper pro_midcontianer mid_container" align='center'>
          <div className="container">
              <div className="email_verify_block">
                  <span className="fa fa-cog fa-spin fa-3x fa-fw margin-bottom"></span>
                  <h1><Translate>Email Verification in progress</Translate></h1>
              </div>
          </div>
        </div>
        {msgData !=='' ?
              <Modal show={this.state.isOpen} onHide={this.closeModal} >
                <Modal.Header closeButton>
                    <Modal.Title><Translate>Success</Translate></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                   <p><Translate>{msgData}</Translate></p>
              </Modal.Body>
              <Modal.Footer>
                <button variant="secondary" onClick={this.closeModal}>
                  <Translate>Close</Translate>
                </button>
              </Modal.Footer>
            </Modal>
           :"" }
           </Translator>
        {/* Footer */}
          <FooterContainer />
      </span>
    )
  }
}





