import React, { Component } from 'react'
import './stylesheets/featured.css'
import Carousel from "react-multi-carousel";
import jumpTo from '../../modules/Navigation'
import "react-multi-carousel/lib/styles.css";

export default class Featured extends Component {
  // const isLoggedin = 0;

  constructor(props) {
      super(props)
        var latitude = localStorage.getItem('latitude');
        var longitude = localStorage.getItem('longitude');
        var devicebrowser = localStorage.getItem('devicebrowser');
        var deviceos = localStorage.getItem('deviceos');
        var devicetype = localStorage.getItem('devicetype');
        var sendData = {
          "devicetype":devicetype,
          "deviceos":deviceos,
          "devicebrowser":devicebrowser,
          "latitude":latitude,
          "longitude":longitude
        }
      // this.toggleMenu = this.toggleMenu.bind(this);
      this.props.getfeaturedData(sendData);
    }

  componentDidMount() {
  }

  render() {
    const featuredDetails  = this.props.featuredData;
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 1000, min: 1024},
        items: 6,
        partialVisibilityGutter: 30,
        slidesToSlide:1, 
        infinite: true
      },
      desktop: {
        breakpoint: { max: 3000, min: 600 },
        items: 6,
        partialVisibilityGutter: 30,
        slidesToSlide:1, 
        infinite: true
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, mfeaturedDetailsin: 0 },
        items: 1
      }
    };
    var featuredData = {}
    const featured = [];
    if(featuredDetails !== null && featuredDetails !== undefined){
      var featuredDataMain = featuredDetails.featuredData;
      var featuredUrl = featuredDetails.imagePath;
      for(var i = 0; i < featuredDataMain.length; i++) {
        var featuredData = {};
        featuredData['name'] = featuredUrl+featuredDataMain[i].UserImageName;
        featuredData['productid'] = featuredDataMain[i].GBXProductID;
        featured.push(featuredData);
      }
    }
    return (
      <span>
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}


            itemClass="carousel-item-padding-40-px"
            
             
          >
          {featuredDetails !== null && featuredDetails !== undefined ? featured.map(({ name, productid}, index) => <div key={index} ><img onClick={() => jumpTo('/product-detail/'+productid)}  src={name}/></div>) : <div></div>}
            
          </Carousel>
      </span>
    )
  }
}



