import { getProductCountSagment } from '../../redux/action/productAction'
import { connect } from 'react-redux'
import landingMarketsegment from './landingMarketsegment'
const mapStoreToProps = state => ({
  productCount: state.product.sagment,
})
const mapDispatchToProps = dispatch => ({
  getProductCountSagment: ()=>dispatch(getProductCountSagment()),
  // applyFilters:(filter_string)=>dispatch(applyFilters(filter_string))
})

export default connect(mapStoreToProps, mapDispatchToProps)(landingMarketsegment)