import React, { Component } from 'react'
import './stylesheets/buyerDashboard.css'
import jumpTo,{go} from '../../../../modules/Navigation'
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../../../configs/constant'
import { Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import SideMenuBuyerContainer from '../../../../components/backend/sideMenuBuyer/sideMenuBuyerContainer'
import HeaderBackContainer from '../../../../components/backend/headerBack/headerBackContainer'

export default class buyerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selectedLanguage:localStorage.getItem('selectedLanguage'),
        preselectedLanguage:localStorage.getItem('preselectedLanguage'),
      };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
   handlegoBack = (data) => {
    localStorage.setItem("BackVar",1);
    window.history.back();
  }
  render() {
    const products = [];
    const columns = [{
      dataField: 'id',
      text: 'Product ID'
    }, {
      dataField: 'name',
      text: 'Product Name'
    }, {
      dataField: 'price',
      text: 'Product Price'
    }];
    return (
     <div className="">
      <HeaderBackContainer />
      <SideMenuBuyerContainer />
    </div>
    )
  }
}





