import React from 'react'
import jumpTo from '../../../modules/Navigation'
import SimpleImageSlider from "react-simple-image-slider";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';



export default function ProductSliderList({productImage,productUrl,productId}) {
  if(productImage !== null && productImage !== undefined){
  	var imageData = [];
    	for(var i = 0; i < productImage.length; i++) {
          var imageDataMain = {};
          imageDataMain['url'] = productUrl+productImage[i].UserImageName;
          imageDataMain['key'] = i;
          imageData.push(imageDataMain);
      }

    return(
	   <span>
        <AwesomeSlider animation="cubeAnimation">
      {imageData.length > 0 ? 
        imageData.map(imageMain=> 
            <div key={imageMain.key}>
              <a onClick={() => jumpTo('/product-detail/'+productId)}>
                <img src={imageMain.url} />
              </a>
            </div>
          )
        : ""}
        </AwesomeSlider>
      </span>
    )
  }
}
