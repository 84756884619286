import serverCall from '../../modules/serverCall'

export const getCategoryData=()=>dispatch=>{
  dispatch({
    type:GET_CATEGORY_BEGIN,
  })
  return serverCall({
    method:'GET',
    url:`/api/commen/category-list`
  })
  .then(res=>{
    dispatch({
      type: GET_CATEGORY_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_CATEGORY_FAIL,
      payload: {error}
    })
    return error
  })
}


export const GET_CATEGORY_BEGIN = 'GET_CATEGORY_BEGIN'
export const GET_CATEGORY_SUCCESS='GET_CATEGORY_SUCCESS'
export const GET_CATEGORY_FAIL='GET_CATEGORY_FAIL'