import React, { Component } from 'react'
import './stylesheets/landingProvince.css'
import jumpTo,{jumpToData} from '../../modules/Navigation'
import { TbHeartPlus } from 'react-icons/tb';
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../configs/constant'
import HeaderContainer from '../../components/header/headerContainer'


export default class landingProvince extends Component {
  constructor(props) {
    super(props);
    this.state = {
        devicetype:localStorage.getItem('devicetype'),
        deviceos:localStorage.getItem('deviceos'),
        devicebrowser:localStorage.getItem('devicebrowser'),
        latitude: localStorage.getItem('latitude'),
        longitude:localStorage.getItem('longitude'),
        userdevice:localStorage.getItem('userdevice'),
        selectedLanguage:localStorage.getItem('selectedLanguage'),
        preselectedLanguage:localStorage.getItem('preselectedLanguage'),
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    var sendData = {
      "devicetype":this.state.devicetype,
      "deviceos":this.state.deviceos,
      "devicebrowser":this.state.devicebrowser,
      "latitude":this.state.latitude,
      "longitude":this.state.longitude,
      "userdevice":this.state.userdevice
    }
    this.props.getProductCountProvince(sendData);
  }
  render() {
    const logocompany = require('../../assets/images/d_logo.png');
    const dart = require('../../assets/images/dartt.png');
    console.log('this.props.productCount',this.props.productCount);
    return (
      <span>
      <HeaderContainer />
          <Translator 
                from={this.state.preselectedLanguage}
                to={this.state.selectedLanguage}
                googleApiKey={serverUrl.googleApiTranslation}
              >
          <a href="/home" className="GBX_logo provinceLogo">
            <img src={logocompany}/>
          </a>

          <div className="shopIn_heading container pt-4">
            <div className="sub_back text-left intr_pges">
              <a onClick={() => jumpTo('landing-marketplace')} className="land_back shopBackBtn"><i className="fas fa-arrow-left"></i><span><Translate>Back</Translate></span></a>
              <h2 className="shop_head provinceHead"><Translate>Shop In: </Translate><br/> <h2 className= "boldHeading"><Translate>{this.props.productCount !== null ? this.props.productCount.state+", "+this.props.productCount.countryName :''}</Translate></h2></h2>
            </div>
          </div>
          <div className="shopby provinceBody">
            <div className="HomeContainer">
                <div className="row margin_7">
                  <div className="col-sm-6 provinceContainer">
                    <a className="recommend_box locate_jaland provinceBox" onClick={() => jumpToData('/product-result/'+this.props.productCount.state,{statename:this.props.productCount.state,name:this.props.productCount.state,code:this.props.productCount.countryCode})}>          
                      <div className="recommend_txt">
                        <div className="recommend-icon provinceIcon"><i className="fas fa-map-marked-alt"></i></div>
                         <h2 className="numberCity">
                          {this.props.productCount !== null ? this.props.productCount.ProvinceStateProductCount :''}
                         </h2>
                        <span className="recommend-border"></span>
                        <p className="recommend-below-title"><Translate>Products LOCATED in </Translate><br/><Translate>{this.props.productCount !== null ? this.props.productCount.state :''}</Translate></p>
                      </div>  
                    </a>
                  </div>
                  
                 
                  <div className="col-sm-6 provinceContainer">
                    <a className="recommend_box vendors_lo provinceBox" onClick={() => jumpToData('/vendor-result/'+this.props.productCount.state,{statename:this.props.productCount.state,name:this.props.productCount.state,code:this.props.productCount.countryCode})}>
                    <div className="hexa_logo">
                      </div>
                    <div className="recommend_txt">
                      <div className="recommend-icon provinceIcon "><i className="fas fa-user-tie"></i></div>
                      <h2 className="numberCity">
                      {this.props.productCount !== null ? this.props.productCount.ProvinceStateVendorCount :''}
                      </h2>
                      <span className="recommend-border"></span>
                      <p className="recommend-below-title"><Translate>Vendors LOCATED in</Translate><br/><Translate> {this.props.productCount !== null ? this.props.productCount.state :''}</Translate></p>
                    </div>  
                    </a>
                  </div>
                  <div className="col-sm-6 provinceContainer">
                    <a className="recommend_box vendors_lo provinceBox" title="Coming Soon">
                    <div className="hexa_logo">
                      </div>
                    <div className="recommend_txt">
                      <div className="recommend-icon provinceIcon"><TbHeartPlus className="heartIcon"/></div>
                      <span className="recommend-border popularBorder"></span>
                      <p className="recommend-below-title"><Translate>POPULAR PRODUCTS in</Translate><br/><Translate>{this.props.productCount !== null ? this.props.productCount.state :''}</Translate></p>
                    </div>  
                    </a>
                  </div>
                  <div className="col-sm-6 provinceContainer">
                    <a className="recommend_box vendors_lo provinceBox" title="Coming Soon">
                    <div className="hexa_logo">
                      </div>
                    <div className="recommend_txt">
                      <div className="recommend-icon cityIconDart">
                          <i className="fas fa-sitemap dartIcon"></i>
                      </div>
                      <img className="provinceDart" src={dart}/>
                      <span className="recommend-border popularCats"></span>
                      <p className="recommend-below-title"><Translate>POPULAR CATEGORIES in</Translate><br/><Translate>{this.props.productCount !== null ? this.props.productCount.state :''}</Translate></p>
                    </div>  
                    </a>
                  </div>
                </div>
            </div>
          </div>
          <div className="partner_footer_label">
            <p className="partner_contentCookies">
              OFFICIAL PARTNER OF THE ROMANIAN RUGBY FEDERATION
            </p>
          </div>
          </Translator>
      </span>
    )
  }
}





