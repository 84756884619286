import React, { Component } from 'react'
import FooterContainer from '../../components/footer/footerContainer'
import HeaderContainer from '../../components/header/headerContainer'
import './stylesheets/newPassword.css'
import jumpTo,{go} from '../../modules/Navigation'
import {Redirect} from 'react-router-dom';
import Modal from "react-bootstrap/Modal"
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../configs/constant'


export default class newPassword extends Component {
  constructor(props) {
    super(props);
    let requestdata = this.props.location.pathname;
    let requestdataMain = requestdata.split("/");
    this.state = {
        isOpen: true,
        email:requestdataMain[2],
        devicetype:localStorage.getItem('devicetype'),
        deviceos:localStorage.getItem('deviceos'),
        devicebrowser:localStorage.getItem('devicebrowser'),
        latitude: localStorage.getItem('latitude'),
        longitude:localStorage.getItem('longitude'),
        userdevice:localStorage.getItem('userdevice'),
        selectedLanguage:localStorage.getItem('selectedLanguage'),
        preselectedLanguage:localStorage.getItem('preselectedLanguage'),
      };

  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleFormSubmit = event => {
    event.preventDefault();
    this.props.resetPasswordData(this.state);
  }

  handleInputChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  openModal = () => this.setState({ isOpen: true });
  
  closeModal = () =>{ 
    this.setState({ isOpen: false });
    this.props.history.push('/landing-marketplace')
  }

  render() {
    const logocompany = require('../../assets/images/global-logo.png');
    const resetPassword = this.props.resetPassword;
    let msgData = '';
    let msgDataError = '';

    if(resetPassword !=='' && resetPassword !== undefined && resetPassword !==null){
      if(resetPassword.status === '1'){
          msgData = resetPassword.msg;
      }else if(resetPassword.status === '0'){
        msgDataError = resetPassword.msg;
      }
    }

    return (
      <span>
      <HeaderContainer />
      <Translator 
                from={this.state.preselectedLanguage}
                to={this.state.selectedLanguage}
                googleApiKey={serverUrl.googleApiTranslation}
              >
       <div className="pro_midcontianer mid_container">
          <div className="container MainForm">
            <div className="row main_sec1">
              <div className="col-sm-6">
                <div className="ms1_left">
                  <div className="ms1_logo">
                    <a onClick={() => jumpTo('/landing-marketplace')}>
                    <img src={logocompany} alt="img" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="ms1_right">
                  <h1>
                    <Translate>THE WORLD'S ONLINE STORE</Translate>
                  </h1>
                  <p>
                    <Translate>Our Platform - Your Business; Your Success - Our Priority</Translate>
                  </p>
                </div>
              </div>
            </div>
            <div className="row login_wrapper">
              <div className="col-md-12 col-sm-12">
                <div className="panel panel-default Set-Log">
                  <div className="panel-heading">
                    <h3><b><Translate>Enter New Password</Translate></b></h3>
                  </div>
                  <div className="panel-body">
                    <form className="form-horizontal reset-form" role="form">
                      <div className="form-group">
                        <label for="email" className="col-md-4 control-label"><Translate>Enter Password</Translate></label>
                        <div className="col-md-6">
                          <input id="password" type="password" onChange={this.handleInputChange} className="form-control" name="password"  required />
                        </div>
                      </div>
                      <div className="form-group">
                        <label for="email" className="col-md-4 control-label"><Translate>Confirm Password</Translate></label>
                        <div className="col-md-6">
                          <input id="password_confirm" type="password" className="form-control" onChange={this.handleInputChange} name="password_confirmation"  required />
                        </div>
                      </div>
                      <div className="form-group" align="center">
                      <span algin='center' style={{color:'#FF0000'}}><Translate>{msgDataError}</Translate></span>
                        <div className="col-md-6 offset-md-4 pull-right">
                          <button type="button" onClick={this.handleFormSubmit} className="btn btn-primary login-btn">
                              <i className="fa fa-sign"></i><Translate>Set New Password</Translate>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {msgData !=='' ?
              <Modal show={this.state.isOpen} onHide={this.closeModal} >
                <Modal.Header closeButton>
                    <Modal.Title><Translate>Modal heading</Translate></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                   <p><Translate>{msgData}</Translate></p>
              </Modal.Body>
              <Modal.Footer>
                <button variant="secondary" onClick={this.closeModal}>
                  Close
                </button>
              </Modal.Footer>
            </Modal>
           :"" }
           </Translator>
        {/* Footer */}
          <FooterContainer />
      </span>
    )
  }
}





