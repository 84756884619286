import { getLoginData} from '../../redux/action/loginAction'
import { connect } from 'react-redux'
import login from './login'
const mapStoreToProps = state => ({
  loginData: state.login.logindata,
})
const mapDispatchToProps = dispatch => ({
  getLoginData: (requestdata)=>dispatch(getLoginData(requestdata))
})

export default connect(mapStoreToProps, mapDispatchToProps)(login)