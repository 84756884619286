import React, { Component } from 'react'
import FooterContainer from '../../components/footer/footerContainer'
import HeaderContainer from '../../components/header/headerContainer'
import TopBannerContainer from '../../components/topBanner/TopBannerContainer'
import MainMenuContainer from '../../components/mainMenu/MainMenuContainer'
import FeaturedContainer from '../../components/featured/FeaturedContainer'
import ProductGrid from '../marketplaceHome/components/ProductGrid'
import ProductList from '../marketplaceHome/components/ProductList'
import './stylesheets/dealspromotionHome.css'
import jumpTo,{jumpToData} from '../../modules/Navigation'
import Modal from "react-bootstrap/Modal";
import Pagination from "react-js-pagination";
import {isMobile} from 'react-device-detect';
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../configs/constant'


export default class dealspromotionHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userdata : localStorage.getItem('userData'),
      currentPage:1,
      keyword:'',
      sort_by:'DiscountedStatus',
      market_segment:'',
      isTop: true,
      isMenuTop: true,
      isMenuTop: true,
      catVal:'',
      subcatID:'',
      marketplaceview:'grid',
      selectedLanguage:localStorage.getItem('selectedLanguage'),
      preselectedLanguage:localStorage.getItem('preselectedLanguage'),
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let requestdata= {};
    requestdata.userdata=this.state.userdata;
    requestdata.catVal= this.state.catVal;
    requestdata.subcatID= this.state.subcatID;
    requestdata.market_segment=this.state.market_segment;
    requestdata.sort_by= "DiscountedStatus";
    // var offset = 0;
    // var BackVar= localStorage.getItem("BackVar");
    // if(BackVar == 1){
    //     offset= localStorage.getItem("offset");
    // }else{
    //   localStorage.setItem("offset",offset);
    // }
    requestdata.discounted_product=1;

    this.props.getProductListDeal(requestdata);
    this.props.getCategoryData();
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 460;
       const isMobileTop = window.scrollY < 42;
       const isMenuTop = window.scrollY < 415;
      if (isTop !== this.state.isTop) {
          this.setState({ isTop })
      }
      if (isMobileTop !== this.state.isMobileTop) {
          this.setState({ isMobileTop })
      }
      if (isMenuTop !== this.state.isMenuTop) {
          this.setState({ isMenuTop })
      }
    });
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
    let requestdata= {};
     requestdata.catVal= '';
      requestdata.subcatID='';
      this.setState({ category: '' })
      this.props.getSubCategoryData(requestdata);
      this.setState({ subcategoryone: '' })
      this.props.getSubCategoryOneData(requestdata);
      this.setState({ subcategorytwo: '' })
      this.props.getSubCategoryTwoData(requestdata);
  }

  handleChange = (event) => {
    window.scrollTo(0, 0);
    let requestdata= {};
    requestdata.sort_by=event.target.value;
    requestdata.search_data=this.state.keyword;
    requestdata.userdata=this.state.userdata;
    requestdata.market_segment=this.state.market_segment;
    this.setState({ sort_by: event.target.value });
    requestdata.offset=0;
    requestdata.discounted_product=1;
    requestdata.catVal= this.state.catVal;
    requestdata.subcatID= this.state.subcatID;
    this.setState({currentPage:1});
    this.props.getProductListDeal(requestdata);
  }

  handleChangeFilter = (event) => { 
    window.scrollTo(0, 0); 
    let requestdata= {};
    requestdata.userdata=this.state.userdata;
    requestdata.market_segment=event.target.value;
    this.setState({ market_segment: event.target.value });
    requestdata.search_data=this.state.keyword;
    requestdata.sort_by= this.state.sort_by;
    requestdata.offset=0;
    requestdata.discounted_product=1;
    requestdata.catVal= this.state.catVal;
    requestdata.subcatID= this.state.subcatID;
    this.setState({currentPage:1});
    this.props.getProductListDeal(requestdata);
  }

  handleChangeKeyword = (event) => {
    this.setState({ keyword: event.target.value });
  }  

  handleClickKeyword = (e) => {
    window.scrollTo(0, 0);
    e.preventDefault()
    let requestdata= {};
    requestdata.userdata=this.state.userdata;
    requestdata.sort_by= this.state.sort_by;
    requestdata.offset=0;
    requestdata.market_segment= this.state.market_segment;
    requestdata.discounted_product=1;
    requestdata.search_data=this.state.keyword;
    requestdata.catVal= this.state.catVal;
    requestdata.subcatID= this.state.subcatID;
    this.props.getProductListDeal(requestdata);
  }

  handleClick = (offset) => {
    window.scrollTo(0, 0);
    let offsetDataMain = offset - 1;
    let offsetData = 12 * offsetDataMain;
    localStorage.setItem("offset",offsetData);
    let requestdata= {};
    requestdata.userdata=this.state.userdata;
    requestdata.sort_by= this.state.sort_by;
    requestdata.search_data=this.state.keyword;
    requestdata.market_segment=this.state.market_segment;
    requestdata.offset=offsetData;
    requestdata.discounted_product=1;
    requestdata.catVal= this.state.catVal;
    requestdata.subcatID= this.state.subcatID;
    this.props.getProductListDeal(requestdata);
    this.setState({
      currentPage: offset
    });
  }

  handleDataCategorySearch = (subcatID,catVal) => {
    window.scrollTo(0, 0);
    var requestdata ={};
    requestdata.userdata=this.state.userdata;
    requestdata.sort_by= this.state.sort_by;
    requestdata.offset=0;
    requestdata.market_segment= this.state.market_segment;
    requestdata.discounted_product=1;
    requestdata.search_data=this.state.keyword;
    requestdata.catVal= catVal;
    requestdata.subcatID=subcatID;
    this.setState({
      currentPage:1,
      isOpen: false,
      catVal:catVal,
      subcatID:subcatID,
      categoryOneData:[],
      categoryTwoData:[],
      categoryThreeData:[],
    });
    this.props.getProductListDeal(requestdata);
  }

  handleDataClick = (subcatID,catVal) => {
    let requestdata= {};
    requestdata.catVal= catVal;
    requestdata.subcatID=subcatID;
    if(catVal === 1){
      this.props.getSubCategoryData(requestdata);
    }else if(catVal === 2){
      this.props.getSubCategoryOneData(requestdata);
    }else if(catVal === 3){
      this.props.getSubCategoryTwoData(requestdata);
    }
  }

  resetData = (event) => {
    window.scrollTo(0, 0);
    let requestdata= {};
    var offset = 0;
    var sortBy = "";
    
    requestdata.sort_by= sortBy;
    requestdata.offset=offset;
    requestdata.userdata=this.state.userdata;
    requestdata.market_segment=this.state.market_segment;
    requestdata.sort_by= "DiscountedStatus";
    requestdata.discounted_product=1;
    requestdata.catVal= '';
    requestdata.subcatID= '';
    requestdata.marketplacesort = '';
    requestdata.filterCat = '';
    requestdata.search_data = '';
    requestdata.market_segment = '';
    this.setState({
      currentPage:1,
      keyword:'',
      category:'',
      sort_by:'',
      catVal:'',
      subcatID:'',
      market_segment:'',
      subcategoryone:'',
      subcategorytwo:'',
      subcategorythree:'',
      marketplacesort:'',
      filterCat:'',
    });

    this.props.getProductListDeal(requestdata);
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });


  render() {
    const logocompany = require('../../assets/images/GBX-silver-logo.png');
    let loaderHTML = false;
    let productListData = [];
    let productListCount = null;
    let ProductCountPagi = 0;
    const productDetailsMain  = this.props.productListSearch;
    const productDetails  = this.props.productList;

    if(productDetailsMain !== null && productDetailsMain !== undefined){
      const productDetails  = productDetailsMain;
    }

    const marketplaceType = "grid";
    // let currentPage = 1;

    const marketSagment = [
                  {'name':'Filter by Market Segment','id':''},
                  {'name':'Business to Business (B2B)','id':'B2B'},
                  {'name':'Business To Consumer (B2C)','id':'B2C'},
                  {'name':'Business To Government (B2G)','id':'B2G'},
                  {'name':'Manufacturer to Business (M2B)','id':'M2B'},
                  {'name':'Manufacturer to Consumer (M2C)','id':'M2C'},                                                              
                  {'name':'Manufacturer to Government (M2G)','id':'M2G'},
                  {'name':'Government to Government (G2G)','id':'G2G'},
                  {'name':'Government to Business (G2B)','id':'G2B'},
                  {'name':'Consumer to Consumer (C2C)','id':'C2C'}
                ];
    const optionsagment = [];
    var objsagment = {}

    for(var i = 0; i < marketSagment.length; i++) {
      var objsagment = {};

      objsagment['value'] = marketSagment[i].id;
      objsagment['label'] = marketSagment[i].name;
      optionsagment.push(objsagment);
    }

    const sortBy = [
                {'name':'Sort by', 'val':'','status':'','sortBy':''},
                {'name':'Price-Low to High', 'val':'UserPrice','status':1,'sortBy':'Low'},
                {'name':'Price-High to Low', 'val':'UserPrice','status':0,'sortBy':'High'},
                {'name':'Title A-Z', 'val':'ProductTitle','status':0,'sortBy':'A'},
                {'name':'Title Z-A', 'val':'ProductTitle','status':1,'sortBy':'Z'},
                {'name':'Most Viewed', 'val':'Most_Viewed','status':1,'sortBy':'View'},
                {'name':'Bestsellers', 'val':'Bestsellers','status':1,'sortBy':'Bestsellers'},
                {'name':'Reset', 'val':'','status':1,'sortBy':'Reset'}
              ];

    const optionsortby = [];
    var objsortby = {}

    const optionpagi = [];
    var objpagi = {}

    for(var i = 0; i < sortBy.length; i++) {
      var objsortby = {};

      objsortby['value'] = sortBy[i].val;
      objsortby['label'] = sortBy[i].name;
      objsortby['sortby'] = sortBy[i].sortBy;
      objsortby['status'] = sortBy[i].status;
      optionsortby.push(objsortby);
    }

    if(productDetails !== null && productDetails !== undefined){
        loaderHTML = true;
        productListData = productDetails.product_list;
        productListCount = productDetails.product_list.length;
        if(productListCount > 0){
        ProductCountPagi = productDetails.product_list[0].ProductCountPagi;

        for(var i = 1; i < ProductCountPagi + 1; i++) {
          var objpagi = {};
          
          objpagi['value'] = i;
          
          optionpagi.push(objpagi);
        }
      }
    }


    const categoryDetails  = this.props.categoryData;
    const subOneCategoryDetails  = this.props.subcategoryData;
    const subTwoCategoryDetails  = this.props.subonecategoryData;
    const subThreeCategoryDetails  = this.props.subtwocategoryData;
    const categoryData = [];
    const categoryOneData = [];
    const categoryTwoData = [];
    const categoryThreeData = [];

    

    if(categoryDetails !== null && categoryDetails !== undefined){
        
        const categoryListData = categoryDetails.Category;
        

      for(var i = 0; i < categoryListData.length; i++) {
          var categoryDataMain = {};
          categoryDataMain['id'] = categoryListData[i].id;
          categoryDataMain['category_name'] = categoryListData[i].category_name;
          categoryDataMain['Count'] = categoryListData[i].Count;
          categoryData.push(categoryDataMain);
      }
      if(subOneCategoryDetails !== null && subOneCategoryDetails !== undefined && subOneCategoryDetails !== ''){

          const categoryOneListData = subOneCategoryDetails.Category;

          for(var i = 0; i < categoryOneListData.length; i++) {
            var categoryOneDataMain = {};
            categoryOneDataMain['id'] = categoryOneListData[i].id;
            categoryOneDataMain['category_name'] = categoryOneListData[i].subcat1_name;
            categoryOneDataMain['Count'] = categoryOneListData[i].subcatOneCount;
            categoryOneData.push(categoryOneDataMain);
        }
      }

      if(subTwoCategoryDetails !== null && subTwoCategoryDetails !== undefined && subTwoCategoryDetails !== ''){

          const categoryTwoListData = subTwoCategoryDetails.Category;

          for(var i = 0; i < categoryTwoListData.length; i++) {
            var categoryTwoDataMain = {};
            categoryTwoDataMain['id'] = categoryTwoListData[i].id;
            categoryTwoDataMain['category_name'] = categoryTwoListData[i].subcat2_name;
            categoryTwoDataMain['Count'] = categoryTwoListData[i].subcatTwoCount;
            categoryTwoData.push(categoryTwoDataMain);
        }
      }

      if(subThreeCategoryDetails !== null && subThreeCategoryDetails !== undefined && subThreeCategoryDetails !== ''){

          const categoryThreeListData = subThreeCategoryDetails.Category;

          for(var i = 0; i < categoryThreeListData.length; i++) {
            var categoryThreeDataMain = {};
            categoryThreeDataMain['id'] = categoryThreeListData[i].id;
            categoryThreeDataMain['category_name'] = categoryThreeListData[i].subcat3_name;
            categoryThreeDataMain['Count'] = categoryThreeListData[i].subcatThreeCount;
            categoryThreeData.push(categoryThreeDataMain);
        }
      }

    }



    // export const usePagination = ({
    //   totalCount,
    //   pageSize,
    //   siblingCount = 1,
    //   currentPage
    // }) => {
    // const paginationRange = useMemo(() => {
    //      // Our implementation logic will go here 
          
    //   }, [totalCount, pageSize, siblingCount, currentPage]);

    //   return paginationRange;
    // };


    // const onPageChange = (value) => {
    //   console.log('value',value);
    //   // currentPage = value;
    //   // onPageChange(currentPage + 1);
    // };

    // const onNext = () => {
    //   onPageChange(currentPage + 1);
    // };

    // const onPrevious = () => {
    //   onPageChange(currentPage - 1);
    // }

    return (
      <span>
      <HeaderContainer />
      {isMobile === false ? 
       <div className="top_banner">
          {/* topBanner */}
          <TopBannerContainer />
       </div> :""}
        {/* MainMenu */}
          {isMobile === false ?
            <div className={this.state.isMenuTop ? 'transparentMainMenu' : 'opaqueMainMenu'}>
              <MainMenuContainer/>
            </div>
            : ""
          }
          <Translator 
            from={this.state.preselectedLanguage}
            to={this.state.selectedLanguage}
            googleApiKey={serverUrl.googleApiTranslation}
          >
          {isMobile === false ?
          <div className="banner_box">
            <div className="container">
            <div className='main_heading'>
              <h1><Translate>THE WORLD'S ONLINE STORE</Translate></h1>
            </div>
              <div className="banner_txt featured_banner">
              <span>
                <h2 className="featured_text"><Translate>Featured Products</Translate></h2>
                  {/* Featured Slider */}
                    <FeaturedContainer />
                </span>
                </div>
                <div className="tax_class_1">
                 <h5><Translate>Prices Include All Sales Taxes Except Cross-Border Duties</Translate></h5>
                </div>
            </div>
          </div> 
          : <h2 className="supportSpace"></h2>}
          {isMobile === true ? 
                <span className='homemarketMenu'>
                 <MainMenuContainer />
               </span>
                : "" 
       }

{isMobile === false ? 
          <div className={this.state.isTop ? 'Form_holder' : 'Form_fixed Top_change Form_holder'}>
            <div className="container">
              <div className="Form_box">
                <div className="row Form_row">
                  <div className="col-sm-2 Form_col">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-10 box_col">  
                        <div className="button_box">          
                          <a href="#" className="btn blue_btn coman_btn" onClick={this.openModal}><Translate>Search by Categories</Translate></a>
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-2 box_col">
                      <div className="grid_icon" >
                       <a title="Refresh" onClick={this.resetData} className="refreshBtn"><i className="fa fa-retweet"></i></a>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5 Form_col">
                    <form className="navbar-form dataClass" role="search" onSubmit={this.handleClickKeyword}>
                      <span className="voice_serch" title="Search by Voice Coming Soon"><i className="fas fa-microphone"></i></span>
                    <div className="input-group add-on">
                      <input className="form-control dataBorder" ref="searchByData" name="searchBy" onChange={this.handleChangeKeyword} placeholder="Search by Keyword. . ." id="srch-term" type="text"/>
                      <div className="input-group-btn input-group-addon dataWidth">
                      <button className="btn blue_btn search_btn" onClick ={this.handleClickKeyword} type="button"><i className="fas fa-search"></i></button>
                      </div>
                    </div>
                    </form>
                  </div>
                 <div className="col-sm-4 Form_col">
                    <div className=" col-sm-8 box_col"> 
                      <div className="button_box">  
                        <select className="form-control filterBar" name="filterCat" value={this.state.filterCat} onChange={this.handleChangeFilter}>
                          {optionsagment.map(({ value, label }, index) => <option key={value} value={value} >{label}</option>)}
                        </select>         
                      </div>
                    </div>
                    <div className=" col-sm-4 box_col">        
                          <div className="button_box">
                            <select className="form-control sortBar" name="marketplacesort" value={this.state.marketplacesort} onChange={this.handleChange}>
                              {optionsortby.map(({ value, label, status, sortby }, index) => <option key={index} value={sortby}>{label}</option>)}
                            </select>
                          </div>    
                    </div>
                  </div>
                 <div className="col-sm-1 Form_col">
                      <div className="row box_row">    
                          <div className="grid_icon homeGrid">
                              <a title="Grid" alt="Grid" onClick={() => {this.handleClickView(1)}} className={this.state.marketplaceview === 'grid' ? 'Grid  Grid_active' :'Grid'} ><i className="fas fa-th"></i></a>
                              <a  title="List" alt="List" onClick={() => {this.handleClickView(2)}} className={this.state.marketplaceview === 'list' ? 'Grid List Grid_active' :'Grid List'} ><i className="fas fa-list-ul"></i></a>
                          </div>
                      </div>  
                  </div>
                </div>
              </div>
            </div>
          </div>
          : <div className={this.state.isMobileTop ? 'Form_holder' : 'Form_fixed Form_holder'}>
            <div className="container">
              <div className="Form_box">
                <div className="row Form_row">
                  <div className="col-sm-2 Form_col">
                    <div className="button_box">          
                      <a href="#" className="btn blue_btn coman_btn" onClick={this.openModal}><Translate>Search by Categories</Translate></a>
                    </div>
                  </div>
                  <div className="col-sm-4 Form_col">
                    <form className="navbar-form dataClass dealsSearch" role="search" onSubmit={this.handleClickKeyword}>
                      <span className="voice_serch" title="Search by Voice Coming Soon"><i className="fas fa-microphone"></i></span>
                    <div className="input-group add-on">
                      <input className="form-control dataBorder" name="searchBy" onChange={this.handleChangeKeyword} placeholder="Search by Keyword" id="srch-term" type="text"/>
                      <div className="input-group-btn input-group-addon dataWidth">
                      <button className="btn blue_btn search_btn" onClick ={this.handleClickKeyword} type="button"><i className="fas fa-search"></i></button>
                      </div>
                    </div>
                    </form>
                  </div>
                  
                  <div className="col-sm-3 Form_col">
                    <div className="button_box">  
                      <select className="form-control filterBar" name="filterCat" value={this.state.filterCat} onChange={this.handleChangeFilter}>
                        {optionsagment.map(({ value, label }, index) => <option key={value} value={value} >{label}</option>)}
                      </select>         
                    </div>
                  </div>

                  <div className="col-sm-3 Form_col">
                  <div className="row box_row">
                    <div className="col-xs-10 col-sm-10 col-md-10 col-lg-9 box_col">        
                      <div className="button_box">
                        <select className="form-control sortBar" name="marketplacesort" value={this.state.marketplacesort} onChange={this.handleChange}>
                          {optionsortby.map(({ value, label, status, sortby }, index) => <option key={index} value={sortby} >{label}</option>)}
                        </select>
                      </div>    
                    </div>    
                    <div className="col-xs-2 col-sm-2 col-sm-2 col-lg-3 box_col text-right">
                      <div className="grid_icon">
                      <a title="Grid" alt="Grid" className="Grid Grid_active"><i className="fas fa-th"></i></a>
                      <a  title="List" alt="List" className="Grid List "><i className="fas fa-list-ul"></i></a>
                    </div>
                    </div>
                  </div>  
                </div>

                </div>
              </div>
            </div>
          </div>
        }
          {loaderHTML === false ?
            <h1 align="center"><Translate>Searching</Translate></h1> :""
          }

          {productListData.length < 1 && loaderHTML !== false ?
            <h1 align="center">No Product Found</h1> :""
          }

          {marketplaceType ==='grid' ?
              <ProductGrid productdata={productListData}/>
            :

            <div></div>

          }
          <div className="pagination_box text-center">
           <Pagination
            activePage={this.state.currentPage}
            itemsCountPerPage={12}
            totalItemsCount={ProductCountPagi}
            pageRangeDisplayed={10}
            onChange={this.handleClick.bind(this)}
          />
          </div>
          <Modal className="Categories_Popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
            <Modal.Header className="blue_btn">
                  <button type="button" className="closeBtnData" onClick={this.closeModal}><i className="far fa-times-circle"></i></button>
                      <h4 className="modal-title withoutuser uprcase"><Translate>All Categories</Translate><span className="icon_spn blue"></span> </h4>
            </Modal.Header>
            <Modal.Body>
              <div className="heading_control">
                
                  {categoryData.map(({ id,category_name,Count }, index) => <div key={index} className="panel_heading"><span className="accordion_txt"><a onClick={() => this.handleDataCategorySearch(id,1)}><Translate>{category_name}</Translate></a><span className="noti_spn transition drop_value blue_bg initial">{Count}</span></span>
                    <a className="panel_title" onClick={() => this.handleDataClick(id,1)}><i className="fas fa-list-ul"></i></a>
                  </div>)}
                  
              </div>
              <div className="panel_group">
                <div className="panel-body">
                  <div className="row margin_auto">
                    <div className="col-sm-4">
                      <h2 className="Sub_Category"><Translate>Sub Category 1</Translate></h2>
                      <div className="collapse_menu">
                        <div className="shadow_box">
                        {categoryOneData.map(({ id,category_name,Count }, index) => <div key={index} className="panel_heading"><span className="accordion_txt"><a onClick={() => this.handleDataCategorySearch(id,2)}><Translate>{category_name}</Translate></a><span className="noti_spn transition drop_value blue_bg initial">{Count}</span></span>
                          <a className="panel_title" onClick={() => this.handleDataClick(id,2)}><i className="fas fa-list-ul"></i></a>
                        </div>)}
                        </div>
                        <div className="shadow_box" ng-if="lamr.subCat ==''">
                          <a href="#" className="SubCtg"><span className="span_box"><Translate>No Sub Category 1</Translate></span></a>
                        </div>
                      </div>                      
                    </div>
                    <div className="col-sm-4">
                      <h2 className="Sub_Category"><Translate>Sub Category 2</Translate></h2>
                      <div className="collapse_menu">
                        <div className="shadow_box">
                        {categoryTwoData.map(({ id,category_name,Count }, index) => <div className="panel_heading"><span className="accordion_txt"><a onClick={() => this.handleDataCategorySearch(id,3)}><Translate>{category_name}</Translate></a><span className="noti_spn transition drop_value blue_bg initial">{Count}</span></span>
                            <a className="panel_title" onClick={() => this.handleDataClick(id,3)}><i className="fas fa-list-ul"></i></a>
                          </div>)}
                        </div>
                        <div className="shadow_box">
                          <a href="#" className="SubCtg"><span className="span_box"><Translate>No Sub Category 2</Translate></span></a>
                        </div>
                      </div>                      
                    </div>
                    <div className="col-sm-4">
                      <h2 className="Sub_Category"><Translate>Sub Category 3</Translate></h2>
                      <div className="collapse_menu">
                        <div className="shadow_box">
                        {categoryThreeData.map(({ id,category_name,Count }, index) => <div className="panel_heading"><span className="accordion_txt"><a onClick={() => this.handleDataCategorySearch(id,4)}><Translate>{category_name}</Translate></a><span className="noti_spn transition drop_value blue_bg initial">{Count}</span></span>
                            <a></a>
                          </div>)}
                        </div>
                        <div className="shadow_box" ng-if="lamr.subCat2 ==''">
                          <a href="#" className="SubCtg"><span className="span_box"><Translate>No Sub Category 3</Translate></span>
                          </a>
                        </div>
                      </div>                      
                    </div>          
                  </div>          
                </div>
              </div>

            </Modal.Body>
          </Modal>
          </Translator>
        {/* Footer */}
          <FooterContainer />
      </span>
    )
  }
}





