import { getVerifyData} from '../../redux/action/verifyemailAction'
import { connect } from 'react-redux'
import verifyEmail from './verifyEmail'
const mapStoreToProps = state => ({
	verifyemailData: state.verifyemail.verifyemails
})
const mapDispatchToProps = dispatch => ({
  getVerifyData:(requestdata)=>dispatch(getVerifyData(requestdata))
})

export default connect(mapStoreToProps, mapDispatchToProps)(verifyEmail)