import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export default function ProductDetailsSlider({productImage,productUrl,productVideo,productUrlVideo}) {
  if(productImage !== null && productImage !== undefined){
    // magnify("myimage", 1.5);
  	const imageData = [];
    const videoData = [];
    const logovideo = require('../../../assets/images/playbutton.png');
      if(productVideo !== null && productVideo !== undefined){
          var videoDataMain = {};
          videoDataMain['link'] = productUrlVideo+productVideo.UserVideoName;
          videoData.push(videoDataMain);
      }
    	for(var i = 0; i < productImage.length; i++) {
          var imageDataMain = {};
          imageDataMain['image'] = productUrl+productImage[i].UserImageName;
          imageDataMain['key'] = i;
          imageData.push(imageDataMain);
      }
  	const data =imageData;
  	const captionStyle = {
	   fontSize: '2em',
	   fontWeight: 'bold',
	}
	const slideNumberStyle = {
	  fontSize: '20px',
	  fontWeight: 'bold',
	}
    return(
	    <span>
    <div className="carousel-wrapper">
      <div className="Main-img isFeaturedClass">
      <Carousel
      infiniteLoop={true}>
      {videoData? 
        videoData.map(videoMain=> 

                <div>
                    <video width="820" height="430" controls>
                        <source src={videoMain.link} />
                    </video>
                    <img className="imagethum" src={logovideo} />
                </div>
          )
         : ""
      }
      {imageData.length > 0 ? 
        imageData.map(imageMain=> 
                <div className="img-magnifier-container" key={imageMain.key}>
                    <img src={imageMain.image} />
                </div>
          )
         :
          ""
      }
            </Carousel>
        </div>
        </div>
      </span>
    )
  }
}

function magnify(imgID, zoom) {
  var img, glass, w, h, bw;
  img = document.getElementById(imgID) ;
  /*create magnifier glass:*/
  glass = document.createElement("DIV");
  glass.setAttribute("class", "img-magnifier-glass");
  /*insert magnifier glass:*/
  img.parentElement.insertBefore(glass, img);
  /*set background properties for the magnifier glass:*/
  glass.style.backgroundImage = "url('" + img.src + "')";
  glass.style.backgroundRepeat = "no-repeat";
  glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
  bw = 3;
  w = glass.offsetWidth / 2;
  h = glass.offsetHeight / 2;
  /*execute a function when someone moves the magnifier glass over the image:*/
  glass.addEventListener("mousemove", moveMagnifier);
  img.addEventListener("mousemove", moveMagnifier);
  /*and also for touch screens:*/
  glass.addEventListener("touchmove", moveMagnifier);
  img.addEventListener("touchmove", moveMagnifier);
  function moveMagnifier(e) {
    var pos, x, y;
    /*prevent any other actions that may occur when moving over the image*/
    e.preventDefault();
    /*get the cursor's x and y positions:*/
    pos = getCursorPos(e);
    x = pos.x;
    y = pos.y;
    /*prevent the magnifier glass from being positioned outside the image:*/
    if (x > img.width - (w / zoom)) {x = img.width - (w / zoom);}
    if (x < w / zoom) {x = w / zoom;}
    if (y > img.height - (h / zoom)) {y = img.height - (h / zoom);}
    if (y < h / zoom) {y = h / zoom;}
    /*set the position of the magnifier glass:*/
    glass.style.left = (x - w) + "px";
    glass.style.top = (y - h) + "px";
    /*display what the magnifier glass "sees":*/
    glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
  }
  function getCursorPos(e) {
    var a, x = 0, y = 0;
    e = e || window.event;
    /*get the x and y positions of the image:*/
    a = img.getBoundingClientRect();
    /*calculate the cursor's x and y coordinates, relative to the image:*/
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return {x : x, y : y};
  }

}