import serverCall from '../../modules/serverCall'

export const getMenuData=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_MENU_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/commen/location-count`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_MENU_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_MENU_FAIL,
      payload: {error}
    })
    return error
  })
}

export const GET_MENU_BEGIN = 'GET_MENU_BEGIN'
export const GET_MENU_SUCCESS='GET_MENU_SUCCESS'
export const GET_MENU_FAIL='GET_MENU_FAIL'