import serverCall from '../../modules/serverCall'

export const getProductLocation=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_PRODUCTRESULT_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/product-search/product-list-location`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_PRODUCTRESULT_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_PRODUCTRESULT_FAIL,
      payload: error
    })
    return error
  })
}


export const GET_PRODUCTRESULT_BEGIN = 'GET_PRODUCTRESULT_BEGIN'
export const GET_PRODUCTRESULT_SUCCESS='GET_PRODUCTRESULT_SUCCESS'
export const GET_PRODUCTRESULT_FAIL='GET_PRODUCTRESULT_FAIL'
