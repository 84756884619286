import serverCall from '../../modules/serverCall'

export const getVendorLocation=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_VENDORRESULT_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/vendor-search/vendor-list-location`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_VENDORRESULT_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_VENDORRESULT_FAIL,
      payload: error
    })
    return error
  })
}


export const GET_VENDORRESULT_BEGIN = 'GET_VENDORRESULT_BEGIN'
export const GET_VENDORRESULT_SUCCESS='GET_VENDORRESULT_SUCCESS'
export const GET_VENDORRESULT_FAIL='GET_VENDORRESULT_FAIL'
