import React from 'react'
import jumpTo from '../../../modules/Navigation'
import SimpleImageSlider from "react-simple-image-slider";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

export default function ProductSlider({productImage,productUrl,productId}) {
    const imageData = [];
    let imagesNav ='';
    let navSize=  50;
    let navMargin= 30;
    let navStyle = 1;
    var objimage = {};
    if(productImage !== '' && productImage !== null && productImage !== undefined){
      objimage['url'] = productUrl + productImage.UserImageName;
     }else{
      const companyLogo = require('../../../assets/images/companydefault.png');
      objimage['url'] = companyLogo;
     } 
    
    
    if(imageData.length >1){
      imagesNav = true;
      navSize=  50;
      navMargin= 30;
      navStyle = 1;
    }else{
      imagesNav = false;
      navSize=  50;
      navMargin= 30;
      navStyle = 1;
    }

    return(
      <span>
        <AwesomeSlider animation="cubeAnimation">
            <div onClick={() => jumpTo('/vendor-store/'+productId)}>
                <img src={objimage.url} />
            </div>
        </AwesomeSlider>
      </span>
    )
}



