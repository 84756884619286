import { getProductListSearch} from '../../redux/action/productlistAction'
import { getCategoryData } from '../../redux/action/categoryAction'
import { getSubCategoryData } from '../../redux/action/subcategoryAction'
import { getSubCategoryOneData } from '../../redux/action/subonecategoryAction'
import { getSubCategoryTwoData } from '../../redux/action/subtwocategoryAction'
import { connect } from 'react-redux'
import countryAvailable from './countryAvailable'

const mapStoreToProps = state => ({
  productList: state.productlist.productlists,
  categoryData: state.category.categorys,
  subcategoryData: state.subcategory.subcategorys,
  subonecategoryData: state.subonecategory.subonecategorys,
  subtwocategoryData: state.subtwocategory.subtwocategorys
})
const mapDispatchToProps = dispatch => ({
  getProductListSearch: (requestdata)=>dispatch(getProductListSearch(requestdata)),
  getCategoryData: ()=>dispatch(getCategoryData()),
  getSubCategoryData: (requestdata)=>dispatch(getSubCategoryData(requestdata)),
  getSubCategoryOneData: (requestdata)=>dispatch(getSubCategoryOneData(requestdata)),
  getSubCategoryTwoData: (requestdata)=>dispatch(getSubCategoryTwoData(requestdata))
})

export default connect(mapStoreToProps, mapDispatchToProps)(countryAvailable)