import serverCall from '../../modules/serverCall'

export const getSubCategoryTwoData=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_SUB_TWO_CATEGORY_BEGIN,
  })
  return serverCall({
    method:'post',
    url:`/api/commen/subcategory-list`,
    data :requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_SUB_TWO_CATEGORY_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_SUB_TWO_CATEGORY_FAIL,
      payload: {error}
    })
    return error
  })
}

export const GET_SUB_TWO_CATEGORY_BEGIN = 'GET_SUB_TWO_CATEGORY_BEGIN'
export const GET_SUB_TWO_CATEGORY_SUCCESS='GET_SUB_TWO_CATEGORY_SUCCESS'
export const GET_SUB_TWO_CATEGORY_FAIL='GET_SUB_TWO_CATEGORY_FAIL'