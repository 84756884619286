import { forgetPasswordData} from '../../redux/action/passwordAction'
import { connect } from 'react-redux'
import forgetPassword from './forgetPassword'
const mapStoreToProps = state => ({
	forgetPassword: state.password.forgetpassword
})
const mapDispatchToProps = dispatch => ({
  forgetPasswordData:(requestdata)=>dispatch(forgetPasswordData(requestdata))
})

export default connect(mapStoreToProps, mapDispatchToProps)(forgetPassword)