import React from 'react'
import jumpTo,{jumpToData} from '../../../modules/Navigation'
import ProductSliderList from './ProductSliderList'
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/umd/popper.min.js'
import serverUrl from '../../../configs/constant'
import {Translator, Translate} from 'react-auto-translate';
export default function ProductGrid({productdata}) {
  const selectedLanguage = localStorage.getItem('selectedLanguage');
  const preselectedLanguage = localStorage.getItem('preselectedLanguage');
  if(productdata !== null && productdata !== undefined){
    return (
      <Translator 
        from={preselectedLanguage}
        to={selectedLanguage}
        googleApiKey={serverUrl.googleApiTranslation}
      >
        <div className="List_View">
          <div className="container">
          {productdata.map(pro =>
            <div key={pro.product_id} className="list_Box">
              <div className="row list_row">
                <div className="col-sm-4">
                  <div className="section_img_product">
                    <div className="thumb_hidden">
                        <ProductSliderList productImage={pro.Images} productUrl={pro.Image_path} productId={pro.GBXProductID}/>
                      {pro.isFeatured === 1 ?
                      <div className="feature_stock">
                        <span className="featured_secll">
                          <a href="#" className="featured">featured</a>
                        </span>
                      </div>
                      : ""
                      } 
                      {pro.QTYAvailable === false ?
                      <div className="stock_con">
                        <div className="out_stock">
                          <span className="stock_secll">
                            <a href="#" className="out_stockbtn">OUT OF STOCK</a>
                          </span>
                        </div>
                      </div>
                      : ""
                      } 
                      {pro.DiscountedStatusData === true ?
                        <div className="sale_bar">
                          <span className="red_barsale"> 
                            Product on sale 
                            <span className="disount_sec"> 
                              <span className="discount_txt">
                                 {pro.DiscountedPercentage}% 
                               </span>
                             off</span> 
                           </span> 
                         </div>
                      : ""
                      }
                    </div>
                  </div>
                </div>
                <div className="col-sm-8 list_column">
                  <div className="MainGrid_sec">
                    <div className="row MainGrid_row">
                      <div className="col-sm-7 col-md-7 col-lg-8 MainGrid_col">
                        <h2 className="MainGrid_txt_head"><a onClick={() => jumpTo('/product-detail/'+pro.GBXProductID)} ><Translate>{pro.ProductTitle}</Translate></a></h2>
                      </div>
                      <div className="col-sm-5 col-md-5 col-lg-4 text-right MainGrid_col">
                        <h3 className="product_rate"><Translate>Product Rating</Translate></h3>
                        <span>
                          <a href="#"><i className="far fa-star active_star"></i></a>
                          <a href="#"><i className="far fa-star active_star"></i></a>
                          <a href="#"><i className="far fa-star active_star"></i></a>
                          <a href="#"><i className="far fa-star no-active_star"></i></a>
                          <a href="#"><i className="far fa-star no-active_star"></i></a>
                        </span>
                      </div>
                    </div>  
                   </div>
                   <div className="desc_section">
                    <div className="row Descript_row">
                      <div className="col-sm-6 Descript_col">         
                        <h3 className="desc_heading"><Translate>Product Description</Translate></h3>
                        <p className="desc_product"><Translate>{pro.ProdDescription}</Translate></p>
                      </div>
                      <div className="col-sm-6 Descript_col">
                        <div className="Grid_Description">
                        <div className="row Descript_row">
                          <div className="col-sm-6 col-6 Grid_column">
                            <h3>Vendor:</h3>
                            <div className="Ptxt_box">
                                <a onClick={() => jumpTo('/vendor-store/'+pro.CompanyURL)} className="tooltip_box">
                                  <span className="Direct_txt"><Translate>{pro.VendorInfo}</Translate></span>
                                  <span className="tooltiptext_box tooltip-top">
                                    <p><Translate>Click here to see</Translate><br/><Translate>{pro.VendorProductCount}</Translate><br/><Translate><Translate>products posted by this vendor</Translate></Translate></p>
                                  </span>
                                </a>
                                <br/>
                                <a className="tooltip_box" data-toggle="modal" data-target={"#exampleModal"+pro.product_id}><span className="Direct_txt"><Translate>{pro.VandorCity}</Translate></span>
                                  <span className="tooltiptext_box tooltip-top">
                                    <p><Translate>Click here to see</Translate><br/><Translate>PRODUCTS and VENDORS</Translate><br/><Translate>in</Translate><b className="upperClass"><Translate>{pro.VandorCity}</Translate></b></p>
                                  </span>
                                </a>  
                                <a className="tooltip_box" data-toggle="modal" data-target={"#exampleModalState"+pro.product_id}><span className="Direct_txt">{pro.VandorProvinceState}</span>
                                  <span className="tooltiptext_box tooltip-top">
                                    <p><Translate>Click here to see</Translate><br/><Translate>PRODUCTS and VENDORS</Translate><br/><Translate>in</Translate><b className="upperClass"><Translate>{pro.VandorProvinceState}</Translate></b></p>
                                  </span>
                                </a>
                                <a className="tooltip_box" data-toggle="modal" data-target={"#exampleModalCountry"+pro.product_id}><span className="Direct_txt"><Translate>{pro.CountryVendorName}</Translate></span>
                                  <span className="tooltiptext_box tooltip-top">
                                    <p><Translate>Click here to see</Translate><br/><Translate>PRODUCTS and VENDORS</Translate><br/><Translate>in</Translate><b className="upperClass"><Translate>{pro.CountryVendorName}</Translate></b></p>
                                  </span>
                                </a>
                                <a className="tooltip_box" data-toggle="modal" data-target={"#exampleModalPostal"+pro.product_id}><span className="Direct_txt">Post Code: {pro.VandorPostalCode}</span>
                                  <span className="tooltiptext_box tooltip-top">
                                    <p><Translate>Click here to see</Translate><br/><Translate>PRODUCTS and VENDORS</Translate><br/><Translate>in</Translate><b className="upperClass">{pro.VandorPostalCode}</b></p>
                                  </span>
                                </a> 
                            </div>
                            </div>
                          <div className="col-sm-6 col-6 Grid_column">
                            <table className="table_box">
                              <tbody>
                                <tr>
                                  <td><a onClick={() => jumpToData('/product-result/'+pro.CountryVendorName,{countryname:pro.Country,name:pro.CountryVendorName,code:pro.Country})} className="green tooltip_box">
                                    <img src={pro.Icon_path+pro.VandorCountry} title="" />
                                    <span className="tooltiptext_box tooltip-top">
                                     <p><Translate>Click here to see</Translate><br/>{pro.CountryVendorCount}<br/><Translate>products supplied from</Translate><br/>{pro.VendorCountryName}</p>
                                    </span>
                                  </a></td>
                                  <td><a href="#" className="Red_box tooltip_box">
                                    <span className="red">{pro.VandorCur} {pro.VandorPrice}</span>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td><a onClick={() => jumpToData('/product-result/United States',{countryname:'US',name:'United States',code:'US'})} className="green tooltip_box">
                                    <img src={pro.Icon_path +pro.USFlag} title="" />
                                    <span className="tooltiptext_box tooltip-top">
                                      <p><Translate>Click here to see</Translate><br/>{pro.USProductCount}<br/><Translate>products supplied from</Translate><br/>{pro.USCountryName}</p>
                                    </span>
                                  </a></td>
                                  <td>
                                    <a href="#" className="Red_box tooltip_box">
                                    <span className="black">{pro.USCurrency} {pro.USPrice}</span>      
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td><a onClick={() => jumpToData('/product-result/'+pro.UserCountryName,{countryname:pro.UserCountryCode,name:pro.UserCountryName,code:pro.UserCountryCode})} className="green tooltip_box">
                                    <img src={pro.Icon_path + pro.UserCountry} title="" />
                                    <span className="tooltiptext_box tooltip-top">
                                      <p><Translate>Click here to see</Translate><br/>{pro.UserCountryCount}<br/><Translate>products supplied from</Translate><br/>{pro.UserCountryName}</p>
                                    </span>
                                  </a></td>
                                  <td>
                                    <a className="green tooltip_box">
                                    <span className="green">{pro.Buyer_currency +" "+ pro.UserPriceMain}</span>
                                    {pro.HasDiscount === 1 ?
                                      <span className="tooltiptext_box tooltip-top" >
                                        <p><Translate>Availabe Price by Volume for this Product</Translate></p>
                                        <table className="tooltip_table">
                                          <tbody><tr>
                                            <td><Translate>Up to</Translate></td>
                                            <td><Translate>From</Translate></td>
                                            <td><Translate>to</Translate></td>
                                            <td><Translate>More than</Translate></td>
                                          </tr>
                                          <tr>
                                            <td>{pro.UpToQuantity1 === 0 ? 1 : pro.UpToQuantity1}</td>
                                            <td>{pro.UpToQuantity1 == 0 ? pro.HasMinimumQuantity : pro.UpToQuantity1 + 1}</td>
                                            <td>{pro.UpToQuantity2}</td>
                                            <td>{pro.MoreThanQuantity2}</td>
                                          </tr>
                                          <tr>
                                            <td>{pro.UserPrice === 'NaN' ? "N/A" : pro.UserPrice}</td>
                                            <td colSpan="2">{pro.UserPrice_one}</td>
                                            <td>{pro.UserPrice_sec}</td>
                                          </tr>
                                        </tbody></table>
                                        <div className="Price_txt text-left">
                                          <p><Translate>Above Prices are in </Translate>{pro.Buyer_currency}<img src={pro.Icon_path+pro.UserCountry} title=""/></p>
                                        </div>
                                      </span>
                                      :""}                      
                                    </a>
                                  </td>
                                </tr>                   
                              </tbody>
                            </table>
                           <div className="Ptxt_box">
                            <span className="Direct_txt membersin"><Translate>Member since {pro.VandorDate}</Translate></span></div>
                          </div>      
                       
                       </div>
                      </div>  
                    </div>
                    </div>
                    <div className="desc_section">
                      <h3 className="desc_heading">Additional Product Info</h3>
                      {pro.AdditionalProductInfo !== '' ? 
                      <span className="desc_product" dangerouslySetInnerHTML={{__html: pro.AdditionalProductInfo}}></span>
                      :
                      <span><Translate>No Infomation Available </Translate></span>
                    }
                    </div>
                    <div className="desc_section_read">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="ReadMore_desc">
                          <ul>
                             <li>
                              <a className="green" data-toggle="modal" data-target={"#exampleModalShare"+pro.product_id}><Translate>Share</Translate><i className="long_arrow fas fa-share-alt"></i></a>
                              </li>
                                {pro.QTYAvailable === true && pro.CountryAvailability == true ?
                                <li>
                                {pro.addedwish === false ?
                                  <a className="green tooltip_box" >
                                    <i className="far fa-heart"></i><Translate>Wishlist</Translate>
                                      <span className="tooltiptext_box"><p><Translate>{pro.UserType !=='' && pro.UserType === 'Buyer' ? "Click here in order to add this item to your wishlist":"Please login as Buyer in order to add this item to your wishlist"}</Translate></p></span>
                                  </a>
                                  :""}
                                  {pro.addedwish === true ?
                                  <a className="green tooltip_box"><i className="fas fa-check"></i><Translate>Wishlist</Translate>
                                    <span class="tooltiptext_box"><p><Translate>{pro.UserType !=='' && pro.UserType === 'Buyer' ? "This Item is in your wishlist":"Please login as Buyer in order to add this item to your wishlist"}</Translate></p></span>
                                  </a>
                                  :""}
                                </li>
                                :""}

                                {pro.QTYAvailable === true && pro.CountryAvailability === true ?
                                <li>
                                  {pro.addedshop === false ?
                                  <a className="dark_green tooltip_box">
                                    <i className="fas fa-shopping-cart"></i><Translate>Add to Cart</Translate><span className="tooltiptext_box"><p><Translate>{pro.UserType !=='' && pro.UserType === 'Buyer' ? "Click here in order to add this item to your shopping cart":"Please login as Buyer in order to add this item to your shopping cart"}</Translate></p></span>
                                  </a>
                                  :""}
                                  {pro.addedshop === true ?
                                  <a class="dark_green tooltip_box">
                                    <i class="fas fa-check"></i><Translate>Add to Cart</Translate><span className="tooltiptext_box"><p><Translate>{pro.UserType !=='' && pro.UserType === 'Buyer' ? "This Item is in your shopping cart":"Please login as Buyer in order to add this item to your shopping cart"}</Translate></p></span>
                                  </a>
                                  :""}
                                </li>
                                :""}
                                {pro.QTYAvailable !== false && pro.CountryAvailability == false ?
                                <li className="OutStock_check">
                                    <span className="checkbox_txt"><Translate>This product is not available for sale in your country</Translate></span>
                                </li>
                                :""}
                                {pro.QTYAvailable === false ?
                                <li className="OutStock_check">
                                  <label className="lable_con">
                                    <input type="checkbox"/>
                                    <span className="checkmark"></span>
                                    <span className="checkbox_txt"><Translate>Click here to get notified when the product becomes available</Translate></span>
                                  </label>
                                </li>
                                :""}
                             </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="ReadMore_desc">
                          <ul className="like_dislike_sec">
                             <li><a href="#" className="like_icon">0&nbsp;<i className="far fa-thumbs-up"></i></a></li>
                             <li><a href="#" className="dislike_icon">0&nbsp;<i className="far fa-thumbs-down"></i></a></li>
                          </ul>
                        </div>  
                      </div>

                      <div className="modal fade"  id={"exampleModalState"+pro.product_id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><Translate>PRODUCTS and VENDORS in </Translate><b className="upperClass"><Translate>{pro.VandorProvinceState}</Translate></b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              </div>
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              <p>Click here to see<br/>{pro.ProvinceStateProductCount}<br/><Translate>PRODUCTS LOCATED in </Translate><b className="upperClass"><Translate>{pro.VandorProvinceState}</Translate></b></p>
                              <button type="button" onClick={() => jumpToData('/product-result/'+pro.VandorProvinceState,{statename:pro.VandorProvinceState,name:pro.VandorProvinceState,code:pro.Country})} className="btn btn-success"><Translate>Continue</Translate></button>
                            </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              </div>
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              <p><Translate>Click here to see </Translate><br/>{pro.ProvinceStateVendorCount}<br/> <Translate>VENDORS LOCATED in</Translate> <b className="upperClass"><Translate>{pro.VandorProvinceState}</Translate></b></p>
                              <button type="button" onClick={() => jumpToData('/vendor-result/'+pro.VandorProvinceState,{statename:pro.VandorProvinceState,name:pro.VandorProvinceState,code:pro.Country})}  className="btn btn-success"><Translate>Continue</Translate></button>
                            </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="modal fade" id={"exampleModal"+pro.product_id}  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><Translate><Translate>PRODUCTS and VENDORS in </Translate></Translate><b className="upperClass"><Translate><Translate>{pro.VandorCity}</Translate></Translate></b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              </div>
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              <p><Translate>Click here to see</Translate><br/><Translate>{pro.CityProductCount}</Translate><br/><Translate>PRODUCTS LOCATED in </Translate><b className="upperClass"><Translate>{pro.VandorCity}</Translate></b></p>
                              <button type="button" className="btn btn-success" onClick={() => jumpToData('/product-result/'+pro.VandorCity,{cityname:pro.VandorCity,name:pro.VandorCity,code:pro.Country})}><Translate>Continue</Translate></button>
                            </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              </div>
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              <p><Translate>Click here to see</Translate> <br/><Translate>{pro.CityVendorCount}</Translate><br/><Translate>VENDORS LOCATED in</Translate><b className="upperClass"><Translate>{pro.VandorCity}</Translate></b></p>
                              <button type="button" onClick={() => jumpToData('/vendor-result/'+pro.VandorCity,{cityname:pro.VandorCity,name:pro.VandorCity,code:pro.Country})} className="btn btn-success"><Translate>Continue</Translate></button>
                            </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="modal fade" id={"exampleModalPostal"+pro.product_id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><Translate>PRODUCTS and VENDORS in </Translate><b className="upperClass"><Translate>{pro.VandorPostalCode}</Translate></b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              </div>
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              <p><Translate>Click here to see</Translate><br/>{pro.PostalCodeProductCount}<br/><Translate>PRODUCTS LOCATED in </Translate><b className="upperClass">{pro.VandorPostalCode}</b></p>
                              <button type="button" className="btn btn-success" onClick={() => jumpToData('/product-result/'+pro.VandorPostalCode,{postalname:pro.VandorPostalCode,name:pro.VandorPostalCode,code:pro.Country})}><Translate>Continue</Translate></button>
                            </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              </div>
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              <p><Translate>Click here to see</Translate> <br/>{pro.PostalCodeVendorCount}<br/> <Translate>VENDORS LOCATED in </Translate><b className="upperClass">{pro.VandorPostalCode}</b></p>
                              <button type="button" onClick={() => jumpToData('/vendor-result/'+pro.VandorPostalCode,{postalname:pro.VandorPostalCode,name:pro.VandorPostalCode,code:pro.Country})} className="btn btn-success"><Translate>Continue</Translate></button>
                            </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="modal fade" id={"exampleModalCountry"+pro.product_id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><Translate>PRODUCTS and VENDORS in </Translate><b className="upperClass"><Translate>{pro.CountryVendorName}</Translate></b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              </div>
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              <p><Translate>Click here to see</Translate><br/>{pro.CountryProductCount}<br/><Translate>PRODUCTS LOCATED in</Translate><b className="upperClass"><Translate>{pro.CountryVendorName}</Translate></b></p>
                              <button type="button" className="btn btn-success" onClick={() => jumpToData('/product-result/'+pro.CountryVendorName,{countryname:pro.Country,name:pro.CountryVendorName,code:pro.Country})}><Translate>Continue</Translate></button>
                            </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              </div>
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              <p>Click here to see <br/>{pro.CountryVendorCount}<br/><Translate> VENDORS LOCATED in </Translate><b className="upperClass"><Translate>{pro.CountryVendorName}</Translate></b></p>
                              <button type="button" onClick={() => jumpToData('/vendor-result/'+pro.CountryVendorName,{countryname:pro.Country,name:pro.CountryVendorName,code:pro.Country})} className="btn btn-success"><Translate>Continue</Translate></button>
                            </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="modal fade" id={"exampleModalShare"+pro.product_id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><b><Translate>Share This Product</Translate></b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <a target="_blank" href={"https://twitter.com/intent/tweet?text=https://'+serverUrl.serverdomain+'/product-detail/"+pro.product_id}><i className="fab fa-twitter-square"></i> <Translate>Share via Twitter</Translate></a>
                            <br/>
                            <hr/>
                            <div className="fb-share-button" data-href="https://developers.facebook.com/docs/plugins/" data-layout="button_count" data-size="small"><a target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u=https://"+serverUrl.serverdomain+"/product-detail/"+pro.product_id} className="fb-xfbml-parse-ignore"><i className="fab fa-facebook-square"></i><Translate>Share via Facebook</Translate></a></div>
                            <br/>
                            <hr/>
                            <a target="_blank" href={"https://www.linkedin.com/shareArticle?mini=true&url=https://"+serverUrl.serverdomain+"/product-detail/"+pro.product_id}><i className="fab fa-linkedin"></i><Translate>Share via LinkedIn</Translate></a>
                            <span>
                            <br/>
                            <hr/>
                            <a href={"whatsapp://send?text=https://"+serverUrl.serverdomain+"/product-detail/"+pro.product_id} data-action="share/whatsapp/share"><i className="fab fa-whatsapp"></i><Translate>Share via Whatsapp</Translate></a>
                            </span>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                          </div>
                        </div>
                      </div>
                    </div>

                    
                    </div>
                    </div>
                </div>
              </div>      
            
            </div>
          </div>
          )}
        </div>
      </div>
      </Translator>
    )

  }else if(productdata === ''){
    return (
      <span></span>
    )
  }else{
    return(
      <span></span>
    )
  }
}



