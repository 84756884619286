import {
  POST_FORGET_PASS_BEGIN,
  POST_FORGET_PASS_SUCCESS,
  POST_FORGET_PASS_FAIL,
  POST_RESET_PASS_BEGIN,
  POST_RESET_PASS_SUCCESS,
  POST_RESET_PASS_FAIL
} from '../action/passwordAction'

const initialState = {
  loading: false,
  forgetpassword: null,
  resetpassword: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_FORGET_PASS_BEGIN:
      return {
        loading: true,
        error: null
      }
    case POST_FORGET_PASS_SUCCESS:
      return {
        loading: false,
        forgetpassword: action.payload.data
      }
    case POST_FORGET_PASS_FAIL:
      return {
        loading: false,
        error: action.payload.data
      }
    case POST_RESET_PASS_BEGIN:
      return {
        loading: true,
        error: null
      }
    case POST_RESET_PASS_SUCCESS:
      return {
        loading: false,
        resetpassword: action.payload.data
      }
    case POST_RESET_PASS_FAIL:
      return {
        loading: false,
        error: action.payload.data
      }
    default:
      return state
  }
}