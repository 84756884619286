import {
  GET_ADVANCESEARCH_BEGIN,
  GET_ADVANCESEARCH_SUCCESS,
  GET_ADVANCESEARCH_FAIL,
  GET_CLICK_ADVANCESEARCH_BEGIN,
  GET_CLICK_ADVANCESEARCH_SUCCESS,
  GET_CLICK_ADVANCESEARCH_FAIL
} from '../action/advancesearchAction'

const initialState = {
  loading: false,
  advancesearchs: null,
  advancesearchclick: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADVANCESEARCH_BEGIN:
      return {
        loading: true,
        error: null
      }
    case GET_ADVANCESEARCH_SUCCESS:
      return {
        loading: false,
        advancesearchs: action.payload.data
      }
    case GET_ADVANCESEARCH_FAIL:
      return {
        loading: false,
        error: action.payload.error
      } 
    case GET_CLICK_ADVANCESEARCH_BEGIN:
      return {
        loading: true,
        error: null
      }
    case GET_CLICK_ADVANCESEARCH_SUCCESS:
      return {
        loading: false,
        advancesearchclick: action.payload.data
      }
    case GET_CLICK_ADVANCESEARCH_FAIL:
      return {
        loading: false,
        error: action.payload.error
      } 
    default:
      return state
  }
}