import {
  GET_CATEGORY_BEGIN,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,  

} from '../action/categoryAction'

const initialState = {
  loading: false,
  categorys: null,
  subcategorys: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY_BEGIN:
      return {
        loading: true,
        error: null
      }
    case GET_CATEGORY_SUCCESS:
      return {
        loading: false,
        categorys: action.payload.data
      }
    case GET_CATEGORY_FAIL:
      return {
        loading: false,
        error: action.payload.data
      }
    default:
      return state
  }
}