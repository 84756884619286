import React, { Component } from 'react'
import FooterContainer from '../../components/footer/footerContainer'
import HeaderContainer from '../../components/header/headerContainer'
import MainMenuContainer from '../../components/mainMenu/MainMenuContainer'
import './stylesheets/vendorResult.css'
import jumpTo,{go,jumpToData,goData} from '../../modules/Navigation'
import {isMobile} from 'react-device-detect';
import ProductSlider from './components/ProductSlider'
import Modal from "react-bootstrap/Modal";
import Pagination from "react-js-pagination";
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/umd/popper.min.js';
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../configs/constant'


export default class vendorResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword:'',
      market_segment:"",
      sort_by:'A',
      sort_by:'Z',
      isTop: true,
      isMobileTop: true,
      nameData:props.location.state.name,
      codeData:props.location.state.code,
      condition:props.location.state.condition,
      conditionsend:props.location.state,
      devicetype:localStorage.getItem('devicetype'),
      deviceos:localStorage.getItem('deviceos'),
      devicebrowser:localStorage.getItem('devicebrowser'),
      latitude: localStorage.getItem('latitude'),
      longitude:localStorage.getItem('longitude'),
      userdevice:localStorage.getItem('userdevice'),
      selectedLanguage:localStorage.getItem('selectedLanguage'),
      preselectedLanguage:localStorage.getItem('preselectedLanguage'),
    };
    let requestdata = props.location.state;
    this.props.getVendorLocation(requestdata);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getCategoryData()
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 460;
      const isMobileTop = window.scrollY <150;
      if (isTop !== this.state.isTop) {
          this.setState({ isTop })
      }
      if (isMobileTop !== this.state.isMobileTop){
         this.setState({isMobileTop})
      }
    });
  }

  handleChange = (event) => { 
    window.scrollTo(0, 0);
    localStorage.setItem("sortBy",event.target.value);
    const requestdata=this.state.dataCode;
    if(requestdata !== undefined){
      requestdata.sort_by=event.target.value;
      requestdata.search_data=this.state.keyword;
      requestdata.market_segment=this.state.market_segment;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      this.setState({ sort_by: event.target.value });
      requestdata.offset=0;
      this.props.getVendorLocation(requestdata);
    }else{
      const requestdata= this.state.conditionsend;
      requestdata.sort_by=event.target.value;
      requestdata.search_data=this.state.keyword;
      requestdata.market_segment=this.state.market_segment;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      this.setState({ sort_by: event.target.value });
      requestdata.offset=0;
      this.props.getVendorLocation(requestdata);
    }
  }

  handleChangeFilter = (event) => {  
    window.scrollTo(0, 0);
    const requestdata=this.state.dataCode;
    if(requestdata !== undefined){
      requestdata.market_segment=event.target.value;
      this.setState({ market_segment: event.target.value });
      requestdata.search_data=this.state.keyword;
      requestdata.sort_by= this.state.sort_by;
      requestdata.offset=0;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      this.props.getVendorLocation(requestdata);
    }else{
      const requestdata= this.state.conditionsend;
      requestdata.market_segment=event.target.value;
      this.setState({ market_segment: event.target.value });
      requestdata.search_data=this.state.keyword;
      requestdata.sort_by= this.state.sort_by;
      requestdata.offset=0;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      this.props.getVendorLocation(requestdata);
    }
    
  }

  handleChangeKeyword = (event) => {
    this.setState({ keyword: event.target.value });
  }  

  handleClickKeyword = (e) => {
    window.scrollTo(0, 0);
  
    e.preventDefault()
    this.refs.searchByData.blur()
    const requestdata= this.state.dataCode;
    if(this.state.keyword !=="" && requestdata !== undefined){
      requestdata.sort_by= this.state.sort_by;
      requestdata.offset=0;
      requestdata.market_segment= this.state.market_segment;
      requestdata.search_data=this.state.keyword;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      this.props.getVendorLocation(requestdata);
    }else if(this.state.keyword !==""){
      const requestdata= this.state.conditionsend;
      requestdata.sort_by= this.state.sort_by;
      requestdata.offset=0;
      requestdata.market_segment= this.state.market_segment;
      requestdata.search_data=this.state.keyword;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      this.props.getVendorLocation(requestdata);
    }
  }  


  handlePageClick = (data,cond) => {
    let requestdata = data;
    requestdata.devicetype= this.state.devicetype;
    requestdata.deviceos= this.state.deviceos;
    requestdata.devicebrowser= this.state.devicebrowser;
    requestdata.latitude= this.state.latitude;
    requestdata.longitude= this.state.longitude;
    requestdata.userdevice= this.state.userdevice;
    this.setState({ nameData: data.name,codeData:data.code,dataCode:data });
    this.props.getVendorLocation(requestdata);
  }

  handleClick = (offset) => {
    window.scrollTo(0, 0);
    let offsetDataMain = offset - 1;
    let offsetData = 12 * offsetDataMain;
    localStorage.setItem("offset",offsetData);
    const requestdata=this.state.dataCode;
    if(requestdata !== undefined){
      requestdata.sort_by= this.state.sort_by;
      requestdata.search_data=this.state.keyword;
      requestdata.market_segment=this.state.market_segment;
      requestdata.offset=offsetData;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      this.props.getVendorLocation(requestdata);
      this.setState({
        currentPage: offset
      });
    }else{
      const requestdata= this.state.conditionsend;
      requestdata.sort_by= this.state.sort_by;
      requestdata.search_data=this.state.keyword;
      requestdata.market_segment=this.state.market_segment;
      requestdata.offset=offsetData;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      this.props.getVendorLocation(requestdata);
      this.setState({
        currentPage: offset
      });
    }
    
  }

  handleDataClick = (subcatID,catVal) => {
    let requestdata= {};
    requestdata.catVal= catVal;
    requestdata.subcatID=subcatID;
    if(catVal === 1){
      this.props.getSubCategoryData(requestdata);
    }else if(catVal === 2){
      this.props.getSubCategoryOneData(requestdata);
    }else if(catVal === 3){
      this.props.getSubCategoryTwoData(requestdata);
    }
  }

  resetData = (event) => {
    window.scrollTo(0, 0);
    var offset = 0;
    var sortBy = "";
    
    const requestdata= this.state.conditionsend;
    requestdata.sort_by=sortBy;
    requestdata.offset=offset;
    requestdata.devicetype= this.state.devicetype;
    requestdata.deviceos= this.state.deviceos;
    requestdata.devicebrowser= this.state.devicebrowser;
    requestdata.latitude= this.state.latitude;
    requestdata.longitude= this.state.longitude;
    requestdata.userdevice= this.state.userdevice;
    requestdata.catVal= '';
    requestdata.subcatID= '';
    requestdata.marketplacesort = '';
    requestdata.filterCat = '';
    requestdata.search_data = '';
    requestdata.market_segment = '';

    this.setState({
      currentPage:1,
      keyword:'',
      category:'',
      sort_by:'',
      catVal:'',
      subcatID:'',
      market_segment:'',
      subcategoryone:'',
      subcategorytwo:'',
      subcategorythree:'',
      marketplacesort:'',
      filterCat:'',
    });

    this.props.getVendorLocation(requestdata);
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });


  render() {
    const loaderAd = require('../../assets/images/loaderAd.gif');
    let loaderHTML = false;
    let productListData = null;
    let productListCount = null;
    let ProductCountPagi = 0;
    let productCount = "";
    const productDetails  = this.props.vendorList;
    const name  = this.state.nameData;
    let code  = '';
    let locationLogo = '';
    let nameCondition = '';

    if(name === 'search_data'){
      code  = 'companydefaultOld.jpg';
      nameCondition = this.state.codeData;
      locationLogo = require('../../assets/images/'+code)
    }else{
      code  = this.state.codeData+'.png';
      nameCondition = name;
      locationLogo = require('../../assets/images/flags/'+code)
    }

    const marketplaceType = "grid";
    // let currentPage = 1;

    const marketSagment = [
                  {'name':'Filter by Market Segment','id':''},
                  {'name':'Business to Business (B2B)','id':'B2B'},
                  {'name':'Business To Consumer (B2C)','id':'B2C'},
                  {'name':'Business To Government (B2G)','id':'B2G'},
                  {'name':'Manufacturer to Business (M2B)','id':'M2B'},
                  {'name':'Manufacturer to Consumer (M2C)','id':'M2C'},                                                              
                  {'name':'Manufacturer to Government (M2G)','id':'M2G'},
                  {'name':'Government to Government (G2G)','id':'G2G'},
                  {'name':'Government to Business (G2B)','id':'G2B'},
                  {'name':'Consumer to Consumer (C2C)','id':'C2C'}
                ];
    const optionsagment = [];
    var objsagment = {}

    for(var i = 0; i < marketSagment.length; i++) {
      var objsagment = {};

      objsagment['value'] = marketSagment[i].id;
      objsagment['label'] = marketSagment[i].name;
      optionsagment.push(objsagment);
    }

    const sortBy = [
                {'name':'Sort by', 'val':'','status':'','sortBy':''},
                {'name':'Title A-Z', 'val':'ProductTitle','status':0,'sortBy':'A'},
                {'name':'Title Z-A', 'val':'ProductTitle','status':1,'sortBy':'Z'},
                {'name':'Reset', 'val':'','status':1,'sortBy':'Reset'}
              ];

    const optionsortby = [];
    var objsortby = {}

    const optionpagi = [];
    var objpagi = {}

    for(var i = 0; i < sortBy.length; i++) {
      var objsortby = {};

      objsortby['value'] = sortBy[i].val;
      objsortby['label'] = sortBy[i].name;
      objsortby['sortby'] = sortBy[i].sortBy;
      objsortby['status'] = sortBy[i].status;
      optionsortby.push(objsortby);
    }

    if(productDetails !== null && productDetails !== undefined){
        loaderHTML = true;
        productCount = productDetails.product_count;
        productListData = productDetails.product_list;
        productListCount = productDetails.product_list.length;
        const pageSize = 12;
        if(productListCount > 0){
          ProductCountPagi = productDetails.product_list[0].ProductCountPagi;
          for(var i = 1; i < ProductCountPagi + 1; i++) {
            var objpagi = {};
            
            objpagi['value'] = i;
            
            optionpagi.push(objpagi);
          }
          const totalPageCount = Math.ceil(productDetails.product_list[0].ProductCountPagi / pageSize);
        }
    }


    const categoryDetails  = this.props.categoryData;
    const subOneCategoryDetails  = this.props.subcategoryData;
    const subTwoCategoryDetails  = this.props.subonecategoryData;
    const subThreeCategoryDetails  = this.props.subtwocategoryData;
    const categoryData = [];
    const categoryOneData = [];
    const categoryTwoData = [];
    const categoryThreeData = [];

    

    if(categoryDetails !== null && categoryDetails !== undefined){
        
        const categoryListData = categoryDetails.Category;
        

      for(var i = 0; i < categoryListData.length; i++) {
          var categoryDataMain = {};
          categoryDataMain['id'] = categoryListData[i].id;
          categoryDataMain['category_name'] = categoryListData[i].category_name;
          categoryDataMain['Count'] = categoryListData[i].Count;
          categoryData.push(categoryDataMain);
      }
      if(subOneCategoryDetails !== null && subOneCategoryDetails !== undefined && subOneCategoryDetails !== ''){

          const categoryOneListData = subOneCategoryDetails.Category;

          for(var i = 0; i < categoryOneListData.length; i++) {
            var categoryOneDataMain = {};
            categoryOneDataMain['id'] = categoryOneListData[i].id;
            categoryOneDataMain['category_name'] = categoryOneListData[i].subcat1_name;
            categoryOneDataMain['Count'] = categoryOneListData[i].subcatOneCount;
            categoryOneData.push(categoryOneDataMain);
        }
      }

      if(subTwoCategoryDetails !== null && subTwoCategoryDetails !== undefined && subTwoCategoryDetails !== ''){

          const categoryTwoListData = subTwoCategoryDetails.Category;

          for(var i = 0; i < categoryTwoListData.length; i++) {
            var categoryTwoDataMain = {};
            categoryTwoDataMain['id'] = categoryTwoListData[i].id;
            categoryTwoDataMain['category_name'] = categoryTwoListData[i].subcat2_name;
            categoryTwoDataMain['Count'] = categoryTwoListData[i].subcatTwoCount;
            categoryTwoData.push(categoryTwoDataMain);
        }
      }

      if(subThreeCategoryDetails !== null && subThreeCategoryDetails !== undefined && subThreeCategoryDetails !== ''){

          const categoryThreeListData = subThreeCategoryDetails.Category;

          for(var i = 0; i < categoryThreeListData.length; i++) {
            var categoryThreeDataMain = {};
            categoryThreeDataMain['id'] = categoryThreeListData[i].id;
            categoryThreeDataMain['category_name'] = categoryThreeListData[i].subcat3_name;
            categoryThreeDataMain['Count'] = categoryThreeListData[i].subcatThreeCount;
            categoryThreeData.push(categoryThreeDataMain);
        }
      }

    }

    // export const usePagination = ({
    //   totalCount,
    //   pageSize,
    //   siblingCount = 1,
    //   currentPage
    // }) => {
    // const paginationRange = useMemo(() => {
    //      // Our implementation logic will go here 
          
    //   }, [totalCount, pageSize, siblingCount, currentPage]);

    //   return paginationRange;
    // };


    // const onPageChange = (value) => {
    //   console.log('value',value);
    //   // currentPage = value;
    //   // onPageChange(currentPage + 1);
    // };

    // const onNext = () => {
    //   onPageChange(currentPage + 1);
    // };

    // const onPrevious = () => {
    //   onPageChange(currentPage - 1);
    // }

    return (
      <span>
      <HeaderContainer />
      {isMobile === false ?
        <div className="vendorResultWeb">
           <MainMenuContainer />
        </div>:""
        }
        <Translator 
          from={this.state.preselectedLanguage}
          to={this.state.selectedLanguage}
          googleApiKey={serverUrl.googleApiTranslation}
        >
          <div className="banner_box indianflagClass" style={{backgroundImage:"url("+locationLogo+")", backgroundSize: '100% 120%', padding:" 68px 0 24px"}}>
            <div className="container" align="center">
                {loaderHTML === false ?
            <h2>Loading <img className="loader_Ad" src={loaderAd}/></h2> :""
          }
              <h1 className="main_heading" style={{textShadow:"2px 2px 0px rgba(0, 0, 0, 0.50)"}}>{productCount}<br/><br/><br/>Vendor(s)<br/><br/> {nameCondition}
              </h1>
                <img src="" className="flagImg" />
            </div>
          </div>
       {isMobile === true ? 
        <div className="vendorResultMenu">
               <MainMenuContainer />
        </div>
                : "" 
       } 
       {isMobile === false ?
          <div className={this.state.isTop ?
           'Form_holder vendorForm' : 'Form_fixed Form_holder vendorForm Top_change'}>
            <div className="container">
              <div className="Form_box vendorResultBox">
                <div className="filter_box">
                <div className="col-sm-2 Form_col">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-10 box_col">  
                        <div className="button_box">          
                          <a href="#" className="btn blue_btn coman_btn" onClick={this.openModal}><Translate>Search by Categories</Translate></a>
                        </div>
                    </div>
                    <div className="col-sm-1 col-md-1 col-lg-1 box_col">
                      <div className="refresh_icon" >
                       <a title="Refresh" onClick={this.resetData} className="vendorRefreshBtn"><i className="fa fa-retweet"></i></a>
                      </div>
                     </div>
                  </div>
                  <div className="col-sm-5 Form_col">
                    <form className="navbar-form dataClass vendorResultSearch" role="search" onSubmit={this.handleClickKeyword}>

                      <span className="voice_serch vendorResultVoice" title="Search by Voice Coming Soon"><i className="fas fa-microphone"></i></span>
                     <div className="input-group add-on vendorSearchBar">
                      <input className="form-control dataBorder VendorResultPlaceholder" ref="searchByData" name="searchBy" onChange={this.handleChangeKeyword} placeholder="Search by Keyword" id="srch-term" type="text"/>
                      <div className="input-group-btn input-group-addon dataWidth shrinkBox">
                      <button className="btn blue_btn search_btn" onClick ={this.handleClickKeyword} type="button"><i className="fas fa-search"></i></button>
                      </div>
                    </div>
                    </form>
                  </div>
                  <div className="col-sm-3 Form_col">
                    <div className="button_box">  
                      <select className="form-control vendorFilterBar" name="filterCat" value={this.state.filterCat} onChange={this.handleChangeFilter}>
                        {optionsagment.map(({ value, label }, index) => <option key={value} value={value} >{label}</option>)}
                      </select>         
                    </div>
                  </div>
                  <div className="col-sm-2 Form_col">
                  <div className="row box_row">
                    <div className="col-sm-12 box_col">        
                      <div className="button_box">
                        <select className="form-control vendorSortBar" name="marketplacesort" value={this.state.marketplacesort} onChange={this.handleChange}>
                          {optionsortby.map(({ value, label, status, sortby }, index) => <option key={index} value={sortby} >{label}</option>)}
                        </select>
                      </div>    
                    </div>    
                    {/*<div className="col-xs-2 col-sm-2 col-sm-2 col-lg-3 box_col text-right">
                      <div className="grid_icon">
                      <a title="Grid" alt="Grid" className="Grid Grid_active"><i className="fas fa-th"></i></a>
                      <a  title="List" alt="List" className="Grid List "><i className="fas fa-list-ul"></i></a>
                    </div>
                    </div>*/}
                  </div>  
                </div>

                </div>
              </div>
            </div>
          </div>:
          <div className={this.state.isMobileTop ? 'Form_holder vendorSearchMobile' : 'Form_fixed Form_holder '}>
            <div className="container">
              <div className="Form_box">
                <div className="row Form_row">
                  <div className="col-sm-6 Form_col">
                    <form className="navbar-form dataClass homeSearch vendorSearch" role="search" onSubmit={this.handleClickKeyword}>
                      <span className="voice_serch" title="Search by Voice Coming Soon"><i className="fas fa-microphone"></i></span>
                    <div className="input-group add-on">
                      <input className="form-control dataBorder" ref="searchByData"  name="searchBy" onChange={this.handleChangeKeyword} placeholder="Search by Keyword" id="srch-term" type="text"/>
                      <div className="input-group-btn input-group-addon dataWidth">
                      <button className="btn blue_btn search_btn" onClick ={this.handleClickKeyword} type="button"><i className="fas fa-search"></i></button>
                      </div>
                    </div>
                    </form>
                  </div>
                  
                  <div className="col-sm-4 Form_col">
                    <div className="button_box">  
                      <select className="form-control filterBar" name="filterCat" value={this.state.filterCat} onChange={this.handleChangeFilter}>
                        {optionsagment.map(({ value, label }, index) => <option key={value} value={value} >{label}</option>)}
                      </select>         
                    </div>
                  </div>

                  <div className="col-sm-2 Form_col">
                  <div className="row box_row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 box_col">        
                      <div className="button_box">
                        <select className="form-control sortBar" name="marketplacesort" value={this.state.marketplacesort} onChange={this.handleChange}>
                          {optionsortby.map(({ value, label, status, sortby }, index) => <option key={index} value={sortby} >{label}</option>)}
                        </select>
                      </div>    
                    </div>    
                    {/*<div className="col-xs-2 col-sm-2 col-sm-2 col-lg-3 box_col text-right">
                      <div className="grid_icon">
                       <a title="Grid" alt="Grid" onClick={() => {this.handleClickView(1)}} className={this.state.marketplaceview === 'grid' ? 'Grid  Grid_active' :'Grid'} ><i className="fas fa-th"></i></a>
                       <a  title="List" alt="List" onClick={() => {this.handleClickView(2)}} className={this.state.marketplaceview === 'list' ? 'Grid List Grid_active' :'Grid List'} ><i className="fas fa-list-ul"></i></a>
                      </div>
                    </div>*/}
                  </div>  
                </div>

                </div>
              </div>
            </div>
          </div> }
          
          {productListCount <= 0 && productListCount !==null  ?
            <h1 align="center">No Vendor Found</h1> :""
          }

          {marketplaceType ==='grid' && productListData !== undefined && productListData !==null ?
              <div className="Grid_Boxes vndr_page">
                <div className="container">
                  <div className="row">
                  {productListData.map(pro =>
                    <div key={pro.product_id} className="col-md-6 Grid_col">
                      <div className="Grid_Box">
                        <div className="thumb_hidden">
                          <span className="img_cellholder">
                            <div className="grid_slider">
                              <ProductSlider productImage={pro.Images} productUrl={pro.Image_path} productId={pro.CompanyURL}/>
                            </div>
                          </span>
                          {pro.isFeatured === 1 ?
                          <div className="feature_stock">
                            <span className="featured_secll">
                              <a className="featured">featured</a>
                            </span>
                          </div>
                          : ""
                          }                  

                          {pro.QTYAvailable === false ?
                          <div className="stock_con">
                            <div className="out_stock">
                              <span className="stock_secll">
                                <a className="out_stockbtn">OUT OF STOCK</a>
                              </span>
                            </div>
                          </div>
                          : ""
                          }

                          {pro.DiscountedStatusData === true ?
                            <div className="sale_bar">
                              <span className="red_barsale"> 
                                Product on sale 
                                <span className="disount_sec"> 
                                  <span className="discount_txt">
                                     {pro.DiscountedPercentage}% 
                                   </span>
                                 off</span> 
                               </span> 
                             </div>
                          : ""
                          }
                        </div>
                        <div className="Grid_Description vndr_row">
                          <div className="Ptxt_box">
                            <h3><Translate>Vendor Name:</Translate></h3>  
                            <a className="tooltip_box" onClick={() => jumpTo('/vendor-store/'+pro.CompanyURL)}>
                                  <span className="Direct_txt">{pro.VendorInfo}</span>
                                  <span className="tooltiptext_box tooltip-top">
                                    <p><Translate>Click here to see</Translate><br/>{pro.VendorProductCount}<br/><Translate>products posted by this vendor</Translate></p>
                                  </span>
                                </a>
                          </div>
                          <div className="Ptxt_box no_bg">
                            <h3><Translate>Main Products:</Translate></h3>  
                            <div className="prdct_name">
                              <Translate>Paintings, Chairs, Clothes</Translate>
                            </div>
                          </div>
                          <div className="row Descript_row ">
                            <div className="col-sm-6 col-xs-6 Grid_column">
                              <div className="Ptxt_box tltp_inline">
                                <h4><Translate>Vendor Locations:</Translate></h4> 
                                <a className="tooltip_box" data-toggle="modal" data-target={"#exampleModal"+pro.product_id}><span className="Direct_txt"><Translate>{pro.VandorCity}</Translate></span>
                                  <span className="tooltiptext_box tooltip-top">
                                    <p><Translate>Click here to see</Translate><br/><Translate>PRODUCTS and VENDORS</Translate><br/> in <b className="upperClass"><Translate>{pro.VandorCity}</Translate></b></p>
                                  </span>
                                </a>  
                                <a className="tooltip_box" data-toggle="modal" data-target={"#exampleModalState"+pro.product_id}><span className="Direct_txt"><Translate>{pro.VandorProvinceState}</Translate></span>
                                  <span className="tooltiptext_box tooltip-top">
                                    <p><Translate>Click here to see</Translate><br/><Translate>PRODUCTS and VENDORS</Translate><br/> in <b className="upperClass"><Translate>{pro.VandorProvinceState}</Translate></b></p>
                                  </span>
                                </a>
                                <a className="tooltip_box" data-toggle="modal" data-target={"#exampleModalCountry"+pro.product_id}><span className="Direct_txt"><Translate>{pro.CountryVendorName}</Translate></span>
                                  <span className="tooltiptext_box tooltip-top">
                                    <p><Translate>Click here to see</Translate><br/><Translate>PRODUCTS and VENDORS</Translate><br/> in <b className="upperClass"><Translate>{pro.CountryVendorName}</Translate></b></p>
                                  </span>
                                </a>
                                <a className="tooltip_box" data-toggle="modal" data-target={"#exampleModalPostal"+pro.product_id}><span className="Direct_txt">Post Code: {pro.VandorPostalCode}</span>
                                  <span className="tooltiptext_box tooltip-top">
                                    <p><Translate>Click here to see</Translate><br/><Translate>PRODUCTS and VENDORS</Translate><br/> in <b className="upperClass">{pro.VandorPostalCode}</b></p>
                                  </span>
                                </a>    
                              </div>
                              <div className="Ptxt_box">
                              <h4><Translate>Vendor Ratings:</Translate></h4> 
                                <span className="ratings vlu_txt"><i className="fas fa-star"></i> <i className="fas fa-star"></i> <i className="fas fa-star"></i> <i className="fas fa-star"></i> <i className="fas fa-star"></i></span>
                              </div>
                            </div>
                            <div className="col-sm-6 col-xs-6 Grid_column">
                            <div className="Ptxt_box">
                              <h4>Business Type</h4>  
                              <span className="vlu_txt"><Translate>Manufacturer, Distributor, Wholesaler, etc</Translate></span>
                              <h4><Translate>{serverUrl.appname} Member Since:</Translate></h4> 
                              <span className="vlu_txt">{pro.VandorDate}</span>  
                            </div>
                            </div> 
                          </div>
                        </div>
                        <div className="ReadMore_box">
                          <table className="table_box table">
                            <tbody>
                              <tr>
                                <td>
                                  <a className="green" data-toggle="modal" data-target={"#exampleModalShare"+pro.product_id}><Translate>Share</Translate></a>
                                </td>
                                <td>
                                  <a className="green" onClick={() => jumpTo('/vendor-store/'+pro.CompanyURL)}><Translate>More Vendor Details</Translate></a>
                                </td>
                                {<td>
                                  <a className="green" data-toggle="modal"><Translate>Visit Vendor Store</Translate></a>
                                </td>}
                              </tr>                       
                            </tbody>
                          </table>
                        </div>
                      </div>

                          <div className="modal fade" id={"exampleModalState"+pro.product_id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel"><Translate>PRODUCTS and VENDORS in </Translate><b className="upperClass"><Translate>{pro.VandorProvinceState}</Translate></b></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="row">
                                      
                                      <div className="col-xs-12 Grid_column">
                                      <p><Translate>Click here to see</Translate><br/>{pro.ProvinceStateProductCount}<br/><Translate>PRODUCTS LOCATED in </Translate><b className="upperClass"><Translate>{pro.VandorProvinceState}</Translate></b></p>
                                      <button type="button" onClick={() => jumpToData('/product-result/'+pro.VandorProvinceState,{statename:pro.VandorProvinceState,name:pro.VandorProvinceState,code:pro.Country})} className="btn btn-success" data-dismiss="modal" >Continue</button>
                                    </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12  Grid_column">
                                        <p><Translate>Click here to see </Translate><br/>{pro.ProvinceStateProductCount}<br/><Translate>VENDORS LOCATED in </Translate><b className="upperClass"><Translate>{pro.VandorProvinceState}</Translate></b></p>
                                        <button type="button" onClick={() => this.handlePageClick({statename:pro.VandorProvinceState,name:pro.VandorProvinceState,code:pro.Country})}  className="btn btn-success" data-dismiss="modal"><Translate>Continue</Translate></button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                                  </div>
                                </div>
                              </div>
                            </div>


                            <div className="modal fade" id={"exampleModal"+pro.product_id}  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel"><Translate>PRODUCTS and VENDORS in </Translate><b className="upperClass"><Translate>{pro.VandorCity}</Translate></b></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      </div>
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      <p><Translate>Click here to see</Translate><br/>{pro.CityProductCount}<br/><Translate>PRODUCTS LOCATED in </Translate><b className="upperClass">{pro.VandorCity}</b></p>
                                      <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => jumpToData('/product-result/'+pro.VandorCity,{cityname:pro.VandorCity,name:pro.VandorCity,code:pro.Country})} ><Translate>Continue</Translate></button>
                                    </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      </div>
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      <p><Translate>Click here to see </Translate><br/>{pro.CityVendorCount}<br/><Translate>VENDORS LOCATED in </Translate> <b className="upperClass">{pro.VandorCity}</b></p>
                                      <button type="button" onClick={() => this.handlePageClick({cityname:pro.VandorCity,name:pro.VandorCity,code:pro.Country})} className="btn btn-success" data-dismiss="modal" ><Translate>Continue</Translate></button>
                                    </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                                  </div>
                                </div>
                              </div>
                            </div>



                            <div className="modal fade" id={"exampleModalPostal"+pro.product_id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel"><Translate>PRODUCTS and VENDORS in </Translate><b className="upperClass">{pro.VandorPostalCode}</b></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      </div>
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      <p><Translate>Click here to see</Translate><br/>{pro.PostalCodeProductCount}<br/><Translate>PRODUCTS LOCATED in </Translate><b className="upperClass">{pro.VandorPostalCode}</b></p>
                                      <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => jumpToData('/product-result/'+pro.VandorPostalCode,{postalname:pro.VandorPostalCode,name:pro.VandorPostalCode,code:pro.Country})}><Translate>Continue</Translate></button>
                                    </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      </div>
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      <p><Translate>Click here to see </Translate><br/>{pro.PostalCodeVendorCount}<br/><Translate>VENDORS LOCATED in</Translate><b className="upperClass">{pro.VandorPostalCode}</b></p>
                                      <button type="button" onClick={() => this.handlePageClick({postalname:pro.VandorPostalCode,name:pro.VandorPostalCode,code:pro.Country})} className="btn btn-success" data-dismiss="modal"><Translate>Continue</Translate></button>
                                    </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button type="button"  className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                                  </div>
                                </div>
                              </div>
                            </div>


                            <div className="modal fade" id={"exampleModalCountry"+pro.product_id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel"><Translate>PRODUCTS and VENDORS in</Translate> <b className="upperClass">{pro.CountryVendorName}</b></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      </div>
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      <p><Translate>Click here to see</Translate><br/>{pro.CountryProductCount}<br/><Translate>PRODUCTS LOCATED in </Translate><b className="upperClass"><Translate>{pro.CountryVendorName}</Translate></b></p>
                                      <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => jumpToData('/product-result/'+pro.CountryProductName,{countryname:pro.ProductCountry,name:pro.CountryProductName,code:pro.ProductCountry})}><Translate>Continue</Translate></button>
                                    </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      </div>
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      <p><Translate>Click here to see </Translate><br/>{pro.CountryVendorCount}<br/><Translate>VENDORS LOCATED in </Translate><b className="upperClass"><Translate>{pro.CountryVendorName}</Translate></b></p>
                                      <button type="button" onClick={() => this.handlePageClick({countryname:pro.Country,name:pro.CountryVendorName,code:pro.Country})} className="btn btn-success" data-dismiss="modal"><Translate>Continue</Translate></button>
                                    </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="modal fade" id={"exampleModalShare"+pro.product_id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel"><b><Translate>Share This Product</Translate></b></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <a target="_blank" href={"https://twitter.com/intent/tweet?text=https://"+serverUrl.serverdomain+"/product-detail/"+pro.product_id}><i className="fab fa-twitter-square"></i> Share via Twitter</a>
                                    <br/>
                                    <hr/>
                                    <div className="fb-share-button" data-href="https://developers.facebook.com/docs/plugins/" data-layout="button_count" data-size="small"><a target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u=https://"+serverUrl.serverdomain+"/product-detail/"+pro.product_id} className="fb-xfbml-parse-ignore"><i className="fab fa-facebook-square"></i> <Translate>Share via Facebook</Translate></a></div>
                                    <br/>
                                    <hr/>
                                    <a target="_blank" href={"https://www.linkedin.com/shareArticle?mini=true&url=https://"+serverUrl.serverdomain+"/product-detail/"+pro.product_id}><i className="fab fa-linkedin"></i> <Translate>Share via LinkedIn</Translate></a>
                                    <span>
                                    <br/>
                                    <hr/>
                                    <a href={"whatsapp://send?text=https://"+serverUrl.serverdomain+"/product-detail/"+pro.product_id} data-action="share/whatsapp/share"><i className="fab fa-whatsapp"></i><Translate>Share via Whatsapp</Translate></a>
                                    </span>
                                  </div>
                                  <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                    </div>
                  )}


                  </div>
                </div>
              </div>
            :

            <div></div>

          }
          <div className="pagination_box text-center">
            <Pagination
              activePage={this.state.currentPage}
              itemsCountPerPage={12}
              totalItemsCount={ProductCountPagi}
              pageRangeDisplayed={10}
              onChange={this.handleClick.bind(this)}
            />
          </div>

          <Modal className="Categories_Popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
            <Modal.Header className="blue_btn">
                  <button type="button" className="closeBtnData" onClick={this.closeModal}><i className="far fa-times-circle"></i></button>
                      <h4 className="modal-title withoutuser uprcase"><Translate>All Categories</Translate><span className="icon_spn blue"></span> </h4>
            </Modal.Header>
            <Modal.Body>
              <div className="heading_control">
                
                  {categoryData.map(({ id,category_name,Count }, index) => <div key={index} className="panel_heading"><span className="accordion_txt"><a onClick={() => jumpToData('/advance-search/',{category:id,name:'category',code:id})}><Translate>{category_name}</Translate></a><span className="noti_spn transition drop_value blue_bg initial">{Count}</span></span>
                    <a className="panel_title" onClick={() => this.handleDataClick(id,1)}><i className="fas fa-list-ul"></i></a>
                  </div>)}
                  
              </div>
              <div className="panel_group">
                <div className="panel-body">
                  <div className="row margin_auto">
                    <div className="col-sm-4">
                      <h2 className="Sub_Category"><Translate>Sub Category 1</Translate></h2>
                      <div className="collapse_menu">
                        <div className="shadow_box">
                        {categoryOneData.map(({ id,category_name,Count }, index) => <div key={index} className="panel_heading"><span className="accordion_txt"><a onClick={() => jumpToData('/advance-search/',{category:this.state.category,name:'category',subcategoryone:id,code:this.state.category})}><Translate>{category_name}</Translate></a><span className="noti_spn transition drop_value blue_bg initial">{Count}</span></span>
                          <a className="panel_title" onClick={() => this.handleDataClick(id,2)}><i className="fas fa-list-ul"></i></a>
                        </div>)}
                        </div>
                        <div className="shadow_box" ng-if="lamr.subCat ==''">
                          <a href="#" className="SubCtg"><span className="span_box"><Translate>No Sub Category 1</Translate></span></a>
                        </div>
                      </div>                      
                    </div>
                    <div className="col-sm-4">
                      <h2 className="Sub_Category"><Translate>Sub Category 2</Translate></h2>
                      <div className="collapse_menu">
                        <div className="shadow_box">
                        {categoryTwoData.map(({ id,category_name,Count }, index) => <div className="panel_heading"><span className="accordion_txt"><a onClick={() => jumpToData('/advance-search/',{category:this.state.category,name:'category',subcategoryone:this.state.subcategoryone,subcategorytwo:id,code:this.state.category})}><Translate>{category_name}</Translate></a><span className="noti_spn transition drop_value blue_bg initial">{Count}</span></span>
                            <a className="panel_title" onClick={() => this.handleDataClick(id,3)}><i className="fas fa-list-ul"></i></a>
                          </div>)}
                        </div>
                        <div className="shadow_box" ng-if="lamr.subCat1 ==''">
                          <a href="#" className="SubCtg"><span className="span_box"><Translate>No Sub Category 2</Translate></span></a>
                        </div>
                      </div>                      
                    </div>
                    <div className="col-sm-4">
                      <h2 className="Sub_Category"><Translate>Sub Category 3</Translate></h2>
                      <div className="collapse_menu">
                        <div className="shadow_box">
                        {categoryThreeData.map(({ id,category_name,Count }, index) => <div className="panel_heading"><span className="accordion_txt"><a onClick={() => jumpToData('/advance-search/',{category:this.state.category,name:'category',subcategoryone:this.state.subcategoryone,subcategorytwo:this.state.subcategoryone,subcategorythree:id,code:this.state.category})}><Translate>{category_name}</Translate></a><span className="noti_spn transition drop_value blue_bg initial">{Count}</span></span>
                            <a></a>
                          </div>)}
                        </div>
                        <div className="shadow_box" ng-if="lamr.subCat2 ==''">
                          <a href="#" className="SubCtg"><span className="span_box"><Translate>No Sub Category 3</Translate></span>
                          </a>
                        </div>
                      </div>                      
                    </div>          
                  </div>          
                </div>
              </div>

            </Modal.Body>
          </Modal>
          </Translator>
        {/* Footer */}
          <FooterContainer />
      </span>
    )
  }
}





