import {
  GET_ALL_PRODUCTS_BEGIN,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FAIL,
  GET_ALL_SAGMENT_BEGIN,
  GET_ALL_SAGMENT_SUCCESS,
  GET_ALL_SAGMENT_FAIL, 
  GET_ALL_COUNTRY_BEGIN,
  GET_ALL_COUNTRY_SUCCESS,
  GET_ALL_COUNTRY_FAIL,
  GET_PRODUCT_BEGIN,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  GET_DEAL_BEGIN,
  GET_DEAL_SUCCESS,
  GET_DEAL_FAIL,
  GET_PRODUCTS_BY_CATEGORY_BEGIN,
  GET_PRODUCTS_BY_CATEGORY_SUCCESS,
  GET_PRODUCTS_BY_CATEGORY_FAIL,
  SEARCH_BEGIN,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  APPLY_FILTERS_BEGIN,
  APPLY_FILTERS_SUCCESS,
  APPLY_FILTERS_FAIL,
} from '../action/productAction'

const initialState = {
  products: null,
  product: null,
  deal: null,
  sagment: null,
  country: null,
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: true,
        products: action.payload.data
      }
    case GET_ALL_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
        products: action.payload.data
      }
    case GET_PRODUCT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        product: action.payload.data.product
      }
    case GET_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      }
    case GET_DEAL_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case GET_DEAL_SUCCESS:
      return {
        ...state,
        loading: false,
        deal: action.payload.data
      }
    case GET_DEAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      }
    case GET_ALL_SAGMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case GET_ALL_SAGMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        sagment: action.payload.data
      }
    case GET_ALL_SAGMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      }
    case GET_ALL_COUNTRY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case GET_ALL_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        country: action.payload.data
      }
    case GET_ALL_COUNTRY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      }
    case GET_PRODUCTS_BY_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case GET_PRODUCTS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload.data.products
      }
    case GET_PRODUCTS_BY_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      }
    case SEARCH_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload.data.products
      }
    case SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      }
    case APPLY_FILTERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case APPLY_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload.data.products
      }
    case APPLY_FILTERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      }
    default:
      return state
  }
}
