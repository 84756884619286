import serverCall from '../../modules/serverCall'

export const getProductList=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_PRODUCTLIST_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/marketplace/product-list`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_PRODUCTLIST_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_PRODUCTLIST_FAIL,
      payload: error
    })
    return error
  })
}

export const getProductListSearch=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_PRODUCTLIST_SEARCH_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/marketplace/product-list-search`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_PRODUCTLIST_SEARCH_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_PRODUCTLIST_SEARCH_FAIL,
      payload: error
    })
    return error
  })
}

export const getProductListDeal=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_PRODUCTLIST_SEARCH_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/marketplace/product-list`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_PRODUCTLIST_SEARCH_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_PRODUCTLIST_SEARCH_FAIL,
      payload: error
    })
    return error
  })
}

export const getProductListFeatured=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_PRODUCTLIST_SEARCH_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/marketplace/product-list`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_PRODUCTLIST_SEARCH_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_PRODUCTLIST_SEARCH_FAIL,
      payload: error
    })
    return error
  })
}

export const GET_PRODUCTLIST_BEGIN = 'GET_PRODUCTLIST_BEGIN'
export const GET_PRODUCTLIST_SUCCESS='GET_PRODUCTLIST_SUCCESS'
export const GET_PRODUCTLIST_FAIL='GET_PRODUCTLIST_FAIL'
export const GET_PRODUCTLIST_SEARCH_BEGIN = 'GET_PRODUCTLIST_SEARCH_BEGIN'
export const GET_PRODUCTLIST_SEARCH_SUCCESS='GET_PRODUCTLIST_SEARCH_SUCCESS'
export const GET_PRODUCTLIST_SEARCH_FAIL='GET_PRODUCTLIST_SEARCH_FAIL'