import React, { Component, useState, setState } from 'react'
import serverUrl from '../../../configs/constant'
import jumpTo,{jumpToData} from '../../../modules/Navigation'
import ProductSlider from './ProductSlider'
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/umd/popper.min.js';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Translator, Translate} from 'react-auto-translate';

  function handleClickCart(event){ 
   const MySwal = withReactContent(Swal);

    let requestdata= {};
    requestdata.gbxproductid = event;
    requestdata.userdata = localStorage.getItem('userData');
    fetch(serverUrl.serverUrl+'/api/buyer/add-shoping-cart', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestdata),
    })
    .then(response => response.json())
    .then(data => {
      MySwal.fire('<p>'+data.msg+'</p>')
    })
    .catch((error) => {
      // console.error('Error:', error);
    });
  }

  function handleClickWish(event){ 
    const MySwal = withReactContent(Swal)
    let requestdata= {};
    requestdata.gbxproductid = event;
    requestdata.userdata = localStorage.getItem('userData');
    fetch(serverUrl.serverUrl+'/api/buyer/add-wish-list', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestdata),
    })
    .then(response => response.json())
    .then(data => {
      MySwal.fire(<p>{data.msg}</p>)
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }



export default function ProductGrid({productdata}) {
  const [addcart, setaddcart] = useState('Add To Cart');
  const [addcarttext, setaddcarttext] = useState('fas fa-shopping-cart');
  const [addwish, setaddwish] = useState('Wishlist');
  const [addwishtext, setaddwishtext] = useState('far fa-heart');
  const selectedLanguage = localStorage.getItem('selectedLanguage');
  const preselectedLanguage = localStorage.getItem('preselectedLanguage');

  if(productdata !== null && productdata !== undefined){
    return (
      <Translator 
        from={preselectedLanguage}
        to={selectedLanguage}
        googleApiKey={serverUrl.googleApiTranslation}
      >
      <div className="Grid_Boxes">
        <div className="container">
          <div className="row">
          {productdata.map(pro =>
            <div key={pro.product_id} className="col-lg-4 col-md-6 col-sm-6 Grid_col">
              <div className="Grid_Box">
                <h2 className="MainGrid_txt"><a onClick={() => jumpTo('/product-detail/'+pro.GBXProductID)} ><Translate>{pro.ProductTitle}</Translate></a></h2>
                <div className="thumb_hidden">
                  <span className="img_cellholder">
                    <div className="grid_slider">
                      <ProductSlider productImage={pro.Images} productUrl={pro.Image_path} productId={pro.GBXProductID}/>
                    </div>
                  </span>
                  {pro.isFeatured === 1 ?
                  <div className="feature_stock">
                    <span className="featured_secll">
                      <a className="featured">featured</a>
                    </span>
                  </div>
                  : ""
                  }                  

                  {pro.QTYAvailable === false ?
                  <div className="stock_con">
                    <div className="out_stock">
                      <span className="stock_secll">
                        <a className="out_stockbtn">OUT OF STOCK</a>
                      </span>
                    </div>
                  </div>
                  : ""
                  }

                  {pro.DiscountedStatusData === true ?
                    <div className="sale_bar">
                      <span className="red_barsale"> 
                        Product on sale 
                        <span className="disount_sec"> 
                          <span className="discount_txt">
                             {pro.DiscountedPercentage}% 
                           </span>
                         off</span> 
                       </span> 
                     </div>
                  : ""
                  }
                </div>
                <div className="Grid_Description">
                  <div className="row Descript_row">
                    <div className="col-sm-6 col-6 Grid_column">
                      <h3>Vendor:</h3>
                      <div className="Ptxt_box">
                        <a className="tooltip_box" onClick={() => jumpTo('/vendor-store/'+pro.CompanyURL)}>
                          <span className="vendor_txt">{pro.VendorInfo}</span>
                          <span className="tooltiptext_box tooltip-top tooltip_home">
                            <p>Click here to see<br/>{pro.VendorProductCount}<br/><Translate>products posted by this vendor</Translate></p>
                          </span>
                        </a>
                        <br/>
                        <a className="tooltip_box" data-toggle="modal" data-target={"#exampleModal"+pro.product_id}><span className="Direct_txt"><Translate>{pro.ProductCity}</Translate></span>
                          <span className="tooltiptext_box tooltip-top tooltip_home">
                            <p>Click here to see<br/><Translate>PRODUCTS and VENDORS</Translate><br/> in <b className="upperClass"><Translate>{pro.ProductCity}</Translate></b></p>
                          </span>
                        </a>  
                        <a className="tooltip_box" data-toggle="modal" data-target={"#exampleModalState"+pro.product_id}><span className="Direct_txt"><Translate>{pro.ProductProvinceState}</Translate></span>
                          <span className="tooltiptext_box tooltip-top tooltip_home">
                            <p>Click here to see<br/><Translate>PRODUCTS and VENDORS</Translate><br/> in <b className="upperClass"><Translate>{pro.ProductProvinceState}</Translate></b></p>
                          </span>
                        </a>
                        <a className="tooltip_box" data-toggle="modal" data-target={"#exampleModalCountry"+pro.product_id}><span className="Direct_txt"><Translate>{pro.CountryProductName}</Translate></span>
                          <span className="tooltiptext_box tooltip-top tooltip_home">
                            <p>Click here to see<br/><Translate>PRODUCTS and VENDORS</Translate><br/> in <b className="upperClass"><Translate>{pro.CountryProductName}</Translate></b></p>
                          </span>
                        </a>
                        <a className="tooltip_box" data-toggle="modal" data-target={"#exampleModalPostal"+pro.product_id}><span className="Direct_txt"><Translate>Post Code:</Translate> {pro.ProductPostalCode}</span>
                          <span className="tooltiptext_box tooltip-top tooltip_home">
                            <p>Click here to see<br/><Translate>PRODUCTS and VENDORS</Translate><br/> in <b className="upperClass">{pro.ProductPostalCode}</b></p>
                          </span>
                        </a>    
                      </div>
                    </div>
                    <div className="col-sm-6 col-6 Grid_column">
                      <table className="table_box">
                        <tbody>
                          <tr>
                            <td><a className="green tooltip_box" onClick={() => jumpToData('/product-result/'+pro.CountryProductName,{countryname:pro.ProductCountry,name:pro.CountryProductName,code:pro.ProductCountry})}>
                              <img src={pro.Icon_path+pro.VandorCountry} title="" />
                              <span className="tooltiptext_box tooltip-top">
                                <p><Translate>Click here to see</Translate><br/>{pro.CountryProductCount}<br/><Translate>products supplied from </Translate><br/>{pro.VendorCountryName}</p>
                              </span>
                            </a></td>
                            <td><a className="Red_box tooltip_box">
                              <span className="red">{pro.VandorCur} {pro.VandorPrice}</span>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td><a className="green tooltip_box" onClick={() => jumpToData('/product-result/United States',{countryname:'US',name:'United States',code:'US'})}>
                              <img src={pro.Icon_path +pro.USFlag} title="" />
                              <span className="tooltiptext_box tooltip-top">
                                <p><Translate>Click here to see</Translate><br/>{pro.USProductCount}<br/><Translate>products supplied from</Translate><br/>{pro.USCountryName}</p>
                              </span>
                            </a></td>
                            <td>
                              <a className="Red_box tooltip_box">
                              <span className="black">{pro.USCurrency} {pro.USPrice}</span>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td><a className="green tooltip_box" onClick={() => jumpToData('/product-result/'+pro.UserCountryName,{countryname:pro.UserCountryCode,name:pro.UserCountryName,code:pro.UserCountryCode})}>
                              <img src={pro.Icon_path + pro.UserCountry} title="" />
                              <span className="tooltiptext_box tooltip-top">
                                <p><Translate>Click here to see</Translate><br/>{pro.UserCountryCount}<br/><Translate>products supplied from</Translate><br/>{pro.UserCountryName}</p>
                              </span>
                            </a></td>
                            <td>
                              <a className="green tooltip_box">
                              <span className="green">{pro.Buyer_currency +" "+ pro.UserPriceMain}</span>
                              {pro.HasDiscount === 1 ?
                                <span className="tooltiptext_box tooltip-top" >
                                  <p><Translate>Availabe Price by Volume for this Product</Translate></p>
                                  <table className="tooltip_table">
                                    <tbody><tr>
                                      <td><Translate>Up to</Translate></td>
                                      <td><Translate>From</Translate></td>
                                      <td><Translate>to</Translate></td>
                                      <td><Translate>More than</Translate></td>
                                    </tr>
                                    <tr>
                                      <td>{pro.UpToQuantity1 === 0 ? 1 : pro.UpToQuantity1}</td>
                                      <td>{pro.UpToQuantity1 == 0 ? pro.HasMinimumQuantity : pro.UpToQuantity1 + 1}</td>
                                      <td>{pro.UpToQuantity2}</td>
                                      <td>{pro.MoreThanQuantity2}</td>
                                    </tr>
                                    <tr>
                                      <td>{pro.UserPrice === 'NaN' ? "N/A" : pro.UserPrice}</td>
                                      <td colSpan="2">{pro.UserPrice_one}</td>
                                      <td>{pro.UserPrice_sec}</td>
                                    </tr>
                                  </tbody></table>
                                  <div className="Price_txt text-left">
                                    <p><Translate>Above Prices are in </Translate>{pro.Buyer_currency}<img src={pro.Icon_path+pro.UserCountry} title=""/></p>
                                  </div>
                                </span>
                                :""}                      
                              </a>
                            </td>
                          </tr>


                        </tbody>
                      </table>
                    </div> 
                      


                  </div>
                  <div className="Ptxt_box">
                    <span className="Direct_txt membersin"><Translate>Member since</Translate> {pro.VandorDate}</span>
                  </div>
                </div>
                <div className="ReadMore_box">
                  <table className="table_box table">
                    <tbody>
                      <tr>
                        <td>
                          <a className="green" data-toggle="modal" data-target={"#exampleModalShare"+pro.product_id}><Translate>Share</Translate><i className="long_arrow fas fa-share-alt"></i></a>
                        </td>
                        {pro.QTYAvailable === true && pro.CountryAvailability == true ?
                        <td>
                        {pro.addedwish === false ?
                          <a className="green tooltip_box" onClick={() => handleClickWish(pro.GBXProductID)}>
                            <i className={addwishtext}></i>{addwish}
                              <span className="tooltiptext_box"><p><Translate>{pro.UserType !=='' && pro.UserType === 'Buyer' ? "Click here in order to add this item to your wishlist":"Please login as Buyer in order to add this item to your wishlist"}</Translate></p></span>
                          </a>
                          :""}
                          {pro.addedwish === true ?
                          <a className="green tooltip_box"><i className="fas fa-check"></i>Wishlisted
                            <span className="tooltiptext_box"><p><Translate>{pro.UserType !=='' && pro.UserType === 'Buyer' ? "This Item is in your wishlist":"Please login as Buyer in order to add this item to your wishlist"}</Translate></p></span>
                          </a>
                          :""}
                        </td>
                        :""}

                        {pro.QTYAvailable === true && pro.CountryAvailability === true ?
                        <td>
                          {pro.addedshop === false ?
                          <a className="dark_green tooltip_box" onClick={() => handleClickCart(pro.GBXProductID)} id={pro.productid+'new'}>
                            <i className={addcarttext}></i>{addcart}<span className="tooltiptext_box"><p><Translate>{pro.UserType !=='' && pro.UserType === 'Buyer' ? "Click here in order to add this item to your shopping cart":"Please login as Buyer in order to add this item to your shopping cart"}</Translate></p></span>
                          </a>
                          :""}
                          {pro.addedshop === true ?
                          <a className="dark_green tooltip_box">
                            <i className="fas fa-check"></i>Added<span className="tooltiptext_box"><p><Translate>{pro.UserType !=='' && pro.UserType === 'Buyer' ? "This Item is in your shopping cart":"Please login as Buyer in order to add this item to your shopping cart"}</Translate></p></span>
                          </a>
                          :""}
                        </td>
                        :""}
                        {pro.QTYAvailable !== false && pro.CountryAvailability == false ?
                        <td className="OutStock_check">
                            <span className="checkbox_txt"><Translate>This product is not available for sale in your country</Translate></span>
                        </td>
                        :""}
                        {pro.QTYAvailable === false ?
                        <td className="OutStock_check">
                          <label className="lable_con">
                            <input type="checkbox"/>
                            <span className="checkmark"></span>
                            <span className="checkbox_txt"><Translate>Click here to get notified when the product becomes available</Translate></span>
                          </label>
                        </td>
                        :""}
                      </tr>                       
                    </tbody>
                  </table>
                </div>

                


              </div>

                  <div className="modal fade"  id={"exampleModalState"+pro.product_id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">PRODUCTS and VENDORS in <b className="upperClass"><Translate>{pro.VandorProvinceState}</Translate></b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              </div>
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              <p>Click here to see<br/>{pro.ProvinceStateProductCount}<br/><Translate>PRODUCTS LOCATED in </Translate><br/><b className="upperClass"><Translate>{pro.ProductProvinceState}</Translate></b></p>
                              <button type="button" onClick={() => jumpToData('/product-result/'+pro.ProductProvinceState,{statename:pro.ProductProvinceState,name:pro.ProductProvinceState,code:pro.ProductCountry})} className="btn btn-success" data-dismiss="modal"><Translate>Continue</Translate></button>
                            </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              </div>
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              <p>Click here to see <br/>{pro.ProvinceStateVendorCount}<br/><Translate>VENDORS LOCATED in </Translate><br/><b className="upperClass"><Translate>{pro.ProductProvinceState}</Translate></b></p>
                              <button type="button" onClick={() => jumpToData('/vendor-result/'+pro.ProductProvinceState,{statename:pro.ProductProvinceState,name:pro.ProductProvinceState,code:pro.ProductCountry})}  className="btn btn-success" data-dismiss="modal"><Translate>Continue</Translate></button>
                            </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="modal fade" id={"exampleModal"+pro.product_id}  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><Translate>PRODUCTS and VENDORS in </Translate><b className="upperClass"><Translate>{pro.VandorCity}</Translate></b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              </div>
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              <p>Click here to see<br/>{pro.CityProductCount}<br/><Translate>PRODUCTS LOCATED in </Translate><br/><b className="upperClass"><Translate>{pro.ProductCity}</Translate></b></p>
                              <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => jumpToData('/product-result/'+pro.ProductCity,{cityname:pro.ProductCity,name:pro.ProductCity,code:pro.ProductCountry})}><Translate>Continue</Translate></button>

                            </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              </div>
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              <p>Click here to see <br/>{pro.CityVendorCount}<br/><Translate>VENDORS LOCATED in</Translate><br/><b className="upperClass"><Translate>{pro.ProductCity}</Translate></b></p>
                              <button type="button"  data-dismiss="modal" onClick={() => jumpToData('/vendor-result/'+pro.ProductCity,{cityname:pro.ProductCity,name:pro.ProductCity,code:pro.ProductCountry})} className="btn btn-success" data-dismiss="modal" ><Translate>Continue</Translate></button>
                            </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="modal fade" id={"exampleModalPostal"+pro.product_id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><Translate>PRODUCTS and VENDORS in </Translate><b className="upperClass">{pro.VandorPostalCode}</b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              </div>
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              <p>Click here to see<br/>{pro.PostalCodeProductCount}<br/>PRODUCTS LOCATED in <br/><b className="upperClass">{pro.ProductPostalCode}</b></p>
                              <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => jumpToData('/product-result/'+pro.ProductPostalCode,{postalname:pro.ProductPostalCode,name:pro.ProductPostalCode,code:pro.ProductCountry})}><Translate>Continue</Translate></button>

                            </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              </div>
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              <p>Click here to see <br/>{pro.PostalCodeVendorCount}<br/><Translate>VENDORS LOCATED in </Translate><br/><b className="upperClass">{pro.ProductPostalCode}</b></p>
                              <button type="button" onClick={() => jumpToData('/vendor-result/'+pro.ProductPostalCode,{postalname:pro.ProductPostalCode,name:pro.ProductPostalCode,code:pro.ProductCountry})} className="btn btn-success" data-dismiss="modal"><Translate>Continue</Translate></button>
                            </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="modal fade" id={"exampleModalCountry"+pro.product_id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><Translate>PRODUCTS and VENDORS in</Translate> <b className="upperClass"><Translate>{pro.CountryVendorName}</Translate></b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              </div>
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              <p>Click here to see<br/>{pro.CountryProductCount}<br/><Translate>PRODUCTS LOCATED i</Translate>n <br/><b className="upperClass"><Translate>{pro.CountryProductName}</Translate></b></p>
                              <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => jumpToData('/product-result/'+pro.CountryProductName,{countryname:pro.ProductCountry,name:pro.CountryProductName,code:pro.ProductCountry})}><Translate>Continue</Translate></button>
                            </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              </div>
                              <div className="col-sm-4 col-xs-4 Grid_column">
                              <p>Click here to see <br/>{pro.CountryVendorCount}<br/><Translate>VENDORS LOCATED in</Translate> <br/><b className="upperClass"><Translate>{pro.CountryProductName}</Translate></b></p>
                              <button type="button" onClick={() => jumpToData('/vendor-result/'+pro.CountryProductName,{countryname:pro.ProductCountry,name:pro.CountryProductName,code:pro.ProductCountry})} className="btn btn-success" data-dismiss="modal" ><Translate>Continue</Translate></button>
                            </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="modal fade" id={"exampleModalShare"+pro.product_id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><b><Translate>Share This Product</Translate></b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <a target="_blank" href={"https://twitter.com/intent/tweet?text=https://"+serverUrl.serverdomain+"/product-detail/"+pro.GBXProductID}><i className="fab fa-twitter-square"></i><Translate>Share via Twitter</Translate></a>
                            <br/>
                            <hr/>
                            <a target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u=https://"+serverUrl.serverdomain+"/product-detail/"+pro.GBXProductID} className="fb-xfbml-parse-ignore"><i className="fab fa-facebook-square"></i><Translate>Share via Facebook</Translate></a>
                            <br/>
                            <hr/>
                            <a target="_blank" href={"https://www.linkedin.com/shareArticle?mini=true&url=https://"+serverUrl.serverdomain+"/product-detail/"+pro.GBXProductID}><i className="fab fa-linkedin"></i><Translate>Share via LinkedIn</Translate></a>
                            <span>
                            <br/>
                            <hr/>
                            <a href={"whatsapp://send?text=https://"+serverUrl.serverdomain+"/product-detail/"+pro.GBXProductID} data-action="share/whatsapp/share"><i className="fab fa-whatsapp"></i><Translate>Share via Whatsapp</Translate></a>
                            </span>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                          </div>
                        </div>
                      </div>
                    </div>
            </div>
          )}


          </div>
        </div>

      </div>
      </Translator>
    )

  }else if(productdata === ''){
    return (
      <span></span>
    )
  }else{
    return(
      <span></span>
    )
  }
}




