import { getCountryList } from '../../redux/action/productAction'
import { getCategoryData } from '../../redux/action/categoryAction'
import { getSubCategoryData } from '../../redux/action/subcategoryAction'
import { getSubCategoryOneData } from '../../redux/action/subonecategoryAction'
import { getSubCategoryTwoData } from '../../redux/action/subtwocategoryAction'
import { getAdvanceSearch, getAdvanceSearchClick,getAdvancesearchCity} from '../../redux/action/advancesearchAction'
import { connect } from 'react-redux'
import advanceSearch from './advanceSearch'

const mapStoreToProps = state => ({
  countryData: state.product.country,
  categoryData: state.category.categorys,
  subcategoryData: state.subcategory.subcategorys,
  subonecategoryData: state.subonecategory.subonecategorys,
  subtwocategoryData: state.subtwocategory.subtwocategorys,
  advancesearchData: state.advancesearch.advancesearchs,
  advancesearchClick: state.advancesearch.advancesearchclick,
  advancesearchCity: state.advancesearch.advancesearchcity
})
const mapDispatchToProps = dispatch => ({
  getCountryList: (sendData)=>dispatch(getCountryList(sendData)),
  getCategoryData: ()=>dispatch(getCategoryData()),
  getSubCategoryData: (requestdata)=>dispatch(getSubCategoryData(requestdata)),
  getSubCategoryOneData: (requestdata)=>dispatch(getSubCategoryOneData(requestdata)),
  getSubCategoryTwoData: (requestdata)=>dispatch(getSubCategoryTwoData(requestdata)),
  getAdvanceSearch: (requestdata)=>dispatch(getAdvanceSearch(requestdata)),
  getAdvanceSearchClick: (requestdata)=>dispatch(getAdvanceSearchClick(requestdata)),
  getAdvancesearchCity: (requestdata)=>dispatch(getAdvancesearchCity(requestdata))
})

export default connect(mapStoreToProps, mapDispatchToProps)(advanceSearch)