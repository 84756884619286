import serverCall from '../../modules/serverCall'

export const signinVendor=(requestdata)=>dispatch=>{
  dispatch({
    type:POST_SIGNINVENDOR_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/registration/registration-vendor`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: POST_SIGNINVENDOR_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: POST_SIGNINVENDOR_FAIL,
      payload: error
    })
    return error
  })
}

export const signinAffiliate=(requestdata)=>dispatch=>{
  dispatch({
    type:POST_SIGNINVENDOR_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/registration/registration-affiliate`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: POST_SIGNINVENDOR_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: POST_SIGNINVENDOR_FAIL,
      payload: error
    })
    return error
  })
}

export const signinBuyer=(requestdata)=>dispatch=>{
  dispatch({
    type:POST_SIGNINVENDOR_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/registration/registration-buyer`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: POST_SIGNINVENDOR_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: POST_SIGNINVENDOR_FAIL,
      payload: error
    })
    return error
  })
}

export const getAffilate=(requestdata)=>dispatch=>{
  dispatch({
    type: POST_AFFILATE_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:'/api/registration/get-affiliate',
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type:POST_AFFILATE_SUCCESS,
      payload:res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type:POST_AFFILATE_FAIL,
      payload:{error}
    })
    throw error
  })
}

export const getVerifyEmail=(requestdata)=>dispatch=>{
  dispatch({
    type: POST_AFFILATE_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:'/api/registration/get-affiliate',
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type:POST_AFFILATE_SUCCESS,
      payload:res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type:POST_AFFILATE_FAIL,
      payload:{error}
    })
    throw error
  })
}

export const getSigninData=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_ALL_SIGNIN_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/registration/page-details`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_ALL_SIGNIN_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_ALL_SIGNIN_FAIL,
      payload: {error}
    })
    return error
  })
}

export const POST_SIGNIN_BEGIN='POST_SIGNIN_BEGIN'
export const POST_SIGNIN_SUCCESS='POST_SIGNIN_SUCCESS'
export const POST_SIGNIN_FAIL='POST_SIGNIN_FAIL'

export const POST_SIGNINVENDOR_BEGIN='POST_SIGNINVENDOR_BEGIN'
export const POST_SIGNINVENDOR_SUCCESS='POST_SIGNINVENDOR_SUCCESS'
export const POST_SIGNINVENDOR_FAIL='POST_SIGNINVENDOR_FAIL'

export const POST_AFFILATE_BEGIN='POST_AFFILATE_BEGIN'
export const POST_AFFILATE_SUCCESS='POST_AFFILATE_SUCCESS'
export const POST_AFFILATE_FAIL='POST_AFFILATE_FAIL'

export const GET_ALL_SIGNIN_BEGIN='GET_ALL_SIGNIN_BEGIN'
export const GET_ALL_SIGNIN_SUCCESS='GET_ALL_SIGNIN_SUCCESS'
export const GET_ALL_SIGNIN_FAIL='GET_ALL_SIGNIN_FAIL'
