import React, { Component } from 'react'
import FooterContainer from '../../components/footer/footerContainer'
import HeaderContainer from '../../components/header/headerContainer'
import './stylesheets/forgetPassword.css'
import jumpTo,{go} from '../../modules/Navigation'
import {Redirect} from 'react-router-dom';
import Modal from "react-bootstrap/Modal"


export default class forgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isOpen: true,
        devicetype:localStorage.getItem('devicetype'),
        deviceos:localStorage.getItem('deviceos'),
        devicebrowser:localStorage.getItem('devicebrowser'),
        latitude: localStorage.getItem('latitude'),
        longitude:localStorage.getItem('longitude'),
        userdevice:localStorage.getItem('userdevice'),
      };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  handleFormSubmit = event => {
    event.preventDefault();
    this.props.forgetPasswordData(this.state);
  }

  handleInputChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  openModal = () => this.setState({ isOpen: true });
  
  closeModal = () =>{ 
    this.setState({ isOpen: false });
    this.props.history.push('/landing-marketplace')
  }

  render() {
    const forgetPasswordData = this.props.forgetPassword;
    let msgData = '';
    let msgDataError = '';
    const logocompany = require('../../assets/images/global-logo.png');
    if(forgetPasswordData !=='' && forgetPasswordData !== undefined && forgetPasswordData !==null){
      if(forgetPasswordData.status === '1'){
          msgData = forgetPasswordData.msg;
      }else if(forgetPasswordData.status === '0'){
        msgDataError = forgetPasswordData.msg;
      }
    }
    return (
      <span>
      <HeaderContainer />
      <div className="pro_midcontianer mid_container">
        <div className="container MainForm">
          <div className="row main_sec1">
            <div className="col-sm-6">
              <div className="ms1_left">
                <div className="ms1_logo">
                  <a onClick={() => jumpTo('/landing-marketplace')}>
                  <img src={logocompany} alt="img"/>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="ms1_right">
                <h1>
                  THE WORLD'S ONLINE STORE
                </h1>
                <p>
                  Our Platform - Your Business; Your Success - Our Priority
                </p>
              </div>
            </div>
          </div>
          <div className="row login_wrapper">
            <div className="col-md-12 col-sm-12">
              <div className="panel panel-default Set-Log">
                <div className="panel-heading">
                  <h3><b><span></span> Reset Password</b></h3>
                </div>
                <div className="panel-body">
                  <form className="form-horizontal reset-form" role="form">
                    <div className="form-group">
                      <label for="email" className="col-md-4 control-label">E-Mail Address</label>
                      <div className="col-md-6">
                        <input id="email" type="email" className="form-control" onChange={this.handleInputChange} name="email" required/>
                      </div>
                    </div>
                    <div className="form-group" align="center">
                    <span algin='center' style={{color:'#FF0000'}}>{msgDataError}</span>
                      <div className="col-md-6 offset-md-4">
                        <button type="button" className="btn btn-primary login-btn" onClick={this.handleFormSubmit}>
                            <i className="fa fa-sign"></i> Send Password Reset Link

                        </button>
                        <a className="btn btn-link" onClick={() => jumpTo('/login')}>You have a password ? Log in</a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        {msgData !=='' ?
              <Modal show={this.state.isOpen} onHide={this.closeModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Message</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                   <p>{msgData}</p>
              </Modal.Body>
              <Modal.Footer>
                <button variant="secondary" onClick={this.closeModal}>
                  Close
                </button>
              </Modal.Footer>
            </Modal>
           :"" }
        {/* Footer */}
          <FooterContainer />
      </span>
    )
  }
}





