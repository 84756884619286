import React, { Component } from 'react'
import FooterContainer from '../../components/footer/footerContainer'
import HeaderContainer from '../../components/header/headerContainer'
import './stylesheets/contactUs.css'
import jumpTo,{go} from '../../modules/Navigation'
import GoogleMapReact from 'google-map-react';
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../configs/constant'


export default class contactUs extends Component {
    constructor(props) {
      super(props);
      this.state = {
          selectedLanguage:localStorage.getItem('selectedLanguage'),
          preselectedLanguage:localStorage.getItem('preselectedLanguage'),
        };
  }

  componentDidMount() {
      window.scrollTo(0, 0);
  }
  handlegoBack = (data) => {
    localStorage.setItem("BackVar",1);
    window.history.back();
  }
  
  render() {
     const GbMap = require('../../assets/images/GB_Map2.jpg');
    return (
      <>
    <HeaderContainer />
<div classname="pro_midcontianer mid_container">
            <div className="sub_back text-left">
              <a onClick={this.handlegoBack} className="land_back aboutUsBack"><i className="fas fa-arrow-left"></i><span> BACK</span></a>
            </div>
  <div className="container contact_page">  
        <Translator 
                  from={this.state.preselectedLanguage}
                  to={this.state.selectedLanguage}
                  googleApiKey={serverUrl.googleApiTranslation}
                >
      <p><Translate>Contact Us</Translate></p>
  
    <div id='content'>
      <div id='left'>
      <div className="GB_mapp"><img src={GbMap} height="546px" style ={{cursor: "pointer",pointerEvents: "visible"}}/>
      </div>
      <ul id='location-bar'>

          <li>
            <a className='location' data-location='Canada' href='javascript:void(0)'><Translate>Canada</Translate></a>
          </li>
          <li>
            <a className='location' data-location='USA' href='javascript:void(0)'><Translate>USA</Translate></a>
          </li>
          <li>
            <a className='location' data-location='Europe' href='javascript:void(0)'><Translate>Europe</Translate></a>
          </li>
          <li>
            <a className='location' data-location='India' href='javascript:void(0)'><Translate>India</Translate></a>
          </li>
        </ul>
      </div>
  
       <div id='right'>
        <p><Translate>Connect</Translate></p>
          <div id='social'>
          <a className='social'>
            <span className='entypo-facebook'></span>
          </a>
          <a className='social'>
            <span className='entypo-twitter'></span>
          </a>
          <a className='social'>
            <span className='entypo-linkedin'></span>
          </a>
          <a className='social'>
            <span className='entypo-gplus'></span>
          </a>
          <a className='social'>
            <span className='entypo-instagrem'></span>
          </a>
         </div>
         <form className="form-horizontal login-form" role="form">
          <p><Translate>Contact Us</Translate></p>
          {/*<Translate _translateProps={['placeholder']}>*/}
          <label className="labelClass"><Translate>First Name, Last Name</Translate></label>
          <input placeholder='First Name, Last Name' type='text' required/>
          {/*</Translate>*/}
          <label className="labelClass"><Translate>Email</Translate></label>
          <input placeholder='Email' type='email' required/>
          <label className="labelClass"><Translate>Subject</Translate></label>
          <input placeholder='Subject' type='text' required/>
          <label className="labelClass"><Translate>Message</Translate></label>
          <textarea placeholder='Message' rows='4' required></textarea>
          <input placeholder='Send' type='submit'/>
        </form>
         <p><Translate>Email Us</Translate></p>
        <p className='other entypo-mail'>
          <a href='#'>info@{serverUrl.serverdomain}</a>
        </p>
    </div>
  </div>
  </Translator>
  </div>
      {/* Footer */}
        <FooterContainer />
</div>
</>
    )
  }
}





