import {getfeaturedData} from '../../redux/action/featuredAction'
import {connect} from 'react-redux'
import Featured from './Featured'


const mapStoreToProps = state => ({
  featuredData: state.featured.featureds,
})
const mapDispatchToProps = dispatch => ({
  getfeaturedData: (sendData)=>dispatch(getfeaturedData(sendData)),
})

export default connect(mapStoreToProps,mapDispatchToProps)(Featured)