import { getProductCountProvince} from '../../redux/action/productAction'
import { connect } from 'react-redux'
import landingProvince from './landingProvince'
const mapStoreToProps = state => ({
  productCount: state.product.products,
})
const mapDispatchToProps = dispatch => ({
  getProductCountProvince: (sendData)=>dispatch(getProductCountProvince(sendData)),
  // applyFilters:(filter_string)=>dispatch(applyFilters(filter_string))
})

export default connect(mapStoreToProps, mapDispatchToProps)(landingProvince)