import React, { Component } from 'react'
import './stylesheets/headerBack.css'
import Auth from '../../../modules/Auth'
import jumpTo,{jumpToData} from '../../../modules/Navigation';
import serverUrl from '../../../configs/constant';
import {Translator, Translate} from 'react-auto-translate';
import { Button } from 'react-bootstrap';

export default class HeaderBack extends Component {
  // const isLoggedin = 0;

  constructor(props) {
    super(props);
    var latitude = localStorage.getItem('latitude');
    var longitude = localStorage.getItem('longitude');
    var devicebrowser = localStorage.getItem('devicebrowser');
    var deviceos = localStorage.getItem('deviceos');
    var devicetype = localStorage.getItem('devicetype');
    var userdevice = localStorage.getItem('userdevice');
    var sendData = {
      "devicetype":devicetype,
      "deviceos":deviceos,
      "devicebrowser":devicebrowser,
      "latitude":latitude,
      "longitude":longitude
    }
    this.toggleMenu = this.toggleMenu.bind(this);
    var  LanguageData = localStorage.getItem('selectedLanguage');
    if(LanguageData !== '' && LanguageData !== null && LanguageData !== undefined){
      LanguageData = localStorage.getItem('selectedLanguage');
    }else{
      LanguageData = 'en';
    }
    this.state = {
      menu: false,
      selectedLanguage:LanguageData,
      preselectedLanguage:localStorage.getItem('preselectedLanguage'),
    };
  }


  componentDidMount() {
    let requestdMain = localStorage.getItem('userData');
  }


 changeLanguage(selected,preselected){
   localStorage.setItem('selectedLanguage',selected.toLowerCase());
   localStorage.setItem('preselectedLanguage',preselected.toLowerCase());
   window.location.reload()
 }
  handleClick = (offset) => {
    localStorage.setItem('isLoggedin',0);
    localStorage.setItem('userType','');
    localStorage.setItem('userName','');
    localStorage.setItem('userData','');
    localStorage.setItem('dataurl','');
    localStorage.setItem('wishListCountData','');
  }

  toggleMenu(){
    this.setState({ menu: !this.state.menu })
  }

  render() {
    var languageName = localStorage.getItem('languageName');

    return (
      <div className="">
        <p>Header Back</p>
      </div>
    )
  }
}



