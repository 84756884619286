import {connect} from 'react-redux'
import HeaderBack from './HeaderBack'
// import {getViewSite, getWishList} from '../../redux/action/headerAction'


const mapStoreToProps = state => ({
  // wishlistcount: state.header.wishcount,
  languageName: state.header.headers
})
const mapDispatchToProps = dispatch => ({
  // getWishList: (requestdata)=>dispatch(getWishList(requestdata)),
  // getViewSite: (sendData)=>dispatch(getViewSite(sendData))
  // applyFilters:(filter_string)=>dispatch(applyFilters(filter_string))
})

export default connect(mapStoreToProps,mapDispatchToProps)(HeaderBack)