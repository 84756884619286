import React, { Component } from 'react'
import './stylesheets/sideMenuBuyer.css'
import Auth from '../../../modules/Auth'
import jumpTo,{jumpToData} from '../../../modules/Navigation';
import serverUrl from '../../../configs/constant';
import {Translator, Translate} from 'react-auto-translate';
import { Button } from 'react-bootstrap';

export default class SideMenuBuyer extends Component {

  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    var  LanguageData = localStorage.getItem('selectedLanguage');
    if(LanguageData !== '' && LanguageData !== null && LanguageData !== undefined){
      LanguageData = localStorage.getItem('selectedLanguage');
    }else{
      LanguageData = 'en';
    }
    this.state = {
      menu: false,
      selectedLanguage:LanguageData,
      preselectedLanguage:localStorage.getItem('preselectedLanguage'),
    };
  }


  componentDidMount() {
    let requestdMain = localStorage.getItem('userData');
  }


 changeLanguage(selected,preselected){
   localStorage.setItem('selectedLanguage',selected.toLowerCase());
   localStorage.setItem('preselectedLanguage',preselected.toLowerCase());
   window.location.reload()
 }
  handleClick = (offset) => {
    localStorage.setItem('isLoggedin',0);
    localStorage.setItem('userType','');
    localStorage.setItem('userName','');
    localStorage.setItem('userData','');
    localStorage.setItem('dataurl','');
    localStorage.setItem('wishListCountData','');
  }

  toggleMenu(){
    this.setState({ menu: !this.state.menu })
  }

  render() {
    var languageName = localStorage.getItem('languageName');
    var countryLanguage ='';
    if(languageName === '' || languageName === null){
        if(this.props.languageName !== null && this.props.languageName !== undefined){
           countryLanguage = this.props.languageName.languagesCode;
           languageName = this.props.languageName.languagesName;
           localStorage.setItem('countryLanguage', countryLanguage );
           localStorage.setItem('languageName', languageName );
        }
    }


    return (
      <div className="">
        <p>SideMenuBuyer</p>
      </div>
    )
  }
}



