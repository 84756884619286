import serverCall from '../../modules/serverCall'

export const forgetPasswordData=(requestdata)=>dispatch=>{
  dispatch({
    type:POST_FORGET_PASS_BEGIN,
  })
  return serverCall({
    method:'post',
    url:`/api/password/forget-password`,
    data :requestdata
  })
  .then(res=>{
    dispatch({
      type: POST_FORGET_PASS_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: POST_FORGET_PASS_FAIL,
      payload: {error}
    })
    return error
  })
}

export const resetPasswordData=(requestdata)=>dispatch=>{
  dispatch({
    type:POST_RESET_PASS_BEGIN,
  })
  return serverCall({
    method:'post',
    url:`/api/password/reset-password`,
    data :requestdata
  })
  .then(res=>{
    dispatch({
      type: POST_RESET_PASS_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: POST_RESET_PASS_FAIL,
      payload: {error}
    })
    return error
  })
}

export const POST_FORGET_PASS_BEGIN = 'POST_FORGET_PASS_BEGIN'
export const POST_FORGET_PASS_SUCCESS='POST_FORGET_PASS_SUCCESS'
export const POST_FORGET_PASS_FAIL='POST_FORGET_PASS_FAIL'

export const POST_RESET_PASS_BEGIN = 'POST_RESET_PASS_BEGIN'
export const POST_RESET_PASS_SUCCESS='POST_RESET_PASS_SUCCESS'
export const POST_RESET_PASS_FAIL='POST_RESET_PASS_FAIL'