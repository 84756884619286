import serverCall from '../../modules/serverCall'

export const getVendorStore=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_VENDORSTORE_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/product-search/vendor-store`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_VENDORSTORE_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_VENDORSTORE_FAIL,
      payload: error
    })
    return error
  })
}


export const GET_VENDORSTORE_BEGIN = 'GET_VENDORSTORE_BEGIN'
export const GET_VENDORSTORE_SUCCESS='GET_VENDORSTORE_SUCCESS'
export const GET_VENDORSTORE_FAIL='GET_VENDORSTORE_FAIL'
