import {
  GET_PRODUCTRESULT_BEGIN,
  GET_PRODUCTRESULT_SUCCESS,
  GET_PRODUCTRESULT_FAIL
} from '../action/productresultAction'

const initialState = {
  loading: false,
  productresults: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTRESULT_BEGIN:
      return {
        loading: true,
        error: null
      }
    case GET_PRODUCTRESULT_SUCCESS:
      return {
        loading: false,
        productresults: action.payload.data
      }
    case GET_PRODUCTRESULT_BEGIN:
      return {
        loading: false,
        error: action.payload.error
      } 
    default:
      return state
  }
}