import { getSigninData, getAffilate,signinVendor,signinBuyer,signinAffiliate } from '../../redux/action/signinAction'
import { connect } from 'react-redux'
import signup from './signup'
const mapStoreToProps = state => ({
  signData: state.signin.signin_data,
  affliateData: state.signin.affliate_data,
  vendorData: state.signin.vendor_data,
})
const mapDispatchToProps = dispatch => ({
  getSigninData: (sendData)=>dispatch(getSigninData(sendData)),
  getAffilate: (requestdata)=>dispatch(getAffilate(requestdata)),
  signinVendor: (requestdata)=>dispatch(signinVendor(requestdata)),
  signinBuyer: (requestdata)=>dispatch(signinBuyer(requestdata)),
  signinAffiliate: (requestdata)=>dispatch(signinAffiliate(requestdata)),
  // applyFilters:(filter_string)=>dispatch(applyFilters(filter_string))
})

export default connect(mapStoreToProps, mapDispatchToProps)(signup)