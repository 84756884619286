import {getMenuData} from '../../redux/action/menuAction'
import {connect} from 'react-redux'
import MainMenu from './MainMenu'


const mapStoreToProps = state => ({
  menuData: state.menu.menus,
})
const mapDispatchToProps = dispatch => ({
  getMenuData: (requestdata)=>dispatch(getMenuData(requestdata)),
})

export default connect(mapStoreToProps,mapDispatchToProps)(MainMenu)