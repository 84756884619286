import React, { Component } from 'react'
import './stylesheets/topBanner.css'
// import AwesomeSlider from 'react-awesome-slider'
// import withAutoplay from 'react-awesome-slider/dist/autoplay'
// import AwesomeSliderStyles from 'react-awesome-slider/src/styles'

export default class TopBanner extends Component {
  // const isLoggedin = 0;

  constructor(props) {
      super(props)
      // this.props.getBannerData()
      // this.toggleMenu = this.toggleMenu.bind(this);
      this.state = {
        videoSound:false
      }
    }

  handleDataVideo = (dataCurrent) => {
    if(dataCurrent === true){
      this.setState({
        videoSound:false
      })
    }else{
      this.setState({
        videoSound:true
      })
    }
  }

  render() {

    // const AutoplaySlider = withAutoplay(AwesomeSlider);
    // const  imageDetails  = this.props.bannerImage;
    const  imageDetailsVideo  = require('../../assets/video/Beautiful Earth video for the GBX site.mp4');
    // const image = [];
    // if(imageDetails !== null && imageDetails !== undefined){
    //   var imageDataMain = imageDetails.imageData;
    //   var imageUrl = imageDetails.imagePath;
    //   for(var i = 0; i < imageDataMain.length; i++) {
    //     var imageData = {};
    //     imageData['name'] = imageUrl+imageDataMain[i];
    //     image.push(imageData);
    //   }
    // }
    
    return (
      <span className="wrapperVideo" >
            <video width="100%" height="400px" className="mainVideo reelVideo" src={imageDetailsVideo} autoplay="autoplay" type="video/mp4" loop="loop" muted={this.state.videoSound}> 
            </video> 
            <span className="overlayVideo" onClick={() =>this.handleDataVideo(this.state.videoSound)}>
              {this.state.videoSound === false ?
                <i className="fas fa-volume-up"></i>
              :
                <i className="fas fa-volume-mute"></i>
              }
            </span>
      </span>
    )
  }
}



