import React, { Component } from 'react'
import FooterContainer from '../../components/footer/footerContainer'
import HeaderContainer from '../../components/header/headerContainer'
import './stylesheets/termsCondition.css'
import jumpTo,{go} from '../../modules/Navigation'
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../configs/constant'

export default class termsCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selectedLanguage:localStorage.getItem('selectedLanguage'),
        preselectedLanguage:localStorage.getItem('preselectedLanguage'),
      };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handlegoBack = (data) => {
    localStorage.setItem("BackVar",1);
    window.history.back();
  }
  
  render() {
    
    return (
      <>
      <HeaderContainer />
      <div className="pro_midcontianer mid_container cntnt_pages">
            <div className="sub_back text-left">
              <a onClick={this.handlegoBack} className="land_back aboutUsBack"><i className="fas fa-arrow-left"></i><span> BACK</span></a>
            </div>
        <div className="container">
          <div className="terms">
      <Translator 
          from={this.state.preselectedLanguage}
          to={this.state.selectedLanguage}
          googleApiKey={serverUrl.googleApiTranslation}
        >
            <h2 className="dark-grey"><b><Translate>TERMS AND CONDITIONS</Translate></b></h2>
            <br/>
            <p><b><Translate>1. General Prohibitions</Translate></b></p>
            <p><b><Translate>2. Artifacts</Translate></b></p>
            <p><b><Translate>3. Currency and Stamps</Translate></b></p>
            <p><b><Translate>4. Contracts and Tickets</Translate></b></p>
            <p><b><Translate>5. Credit / Debit Cards and Financial Instruments</Translate></b></p>
            <p><b><Translate>6. Illicit Drugs and Associated Paraphernalia </Translate></b></p>
            <p><b><Translate>7. Ethnically and Racially Offensive Material</Translate></b></p>
            <p><b><Translate>8. Event Ticket Resale Policy</Translate></b></p>
            <p><b><Translate>9. Faces, Names and Signatures</Translate></b></p>
            <p><b><Translate>10. Firearms, Ammunition, Weapons, Explosives</Translate></b></p>
            <p><b><Translate>11. Alcohol, Food and Beverages</Translate></b></p>
            <p><b><Translate>12. Tobacco Products</Translate></b></p>
            <p><b><Translate>13. Government Identifications, Licenses and Uniforms</Translate></b></p>
            <p><b><Translate>14. Hazardous Materials</Translate></b></p>
            <p><b><Translate>15. Human Parts and Remains</Translate></b></p>
            <p><b><Translate>16. Invoices</Translate></b></p>
            <p><b><Translate>17. Mailing Lists and Personal Information</Translate></b></p>
            <p><b><Translate>18. Non-Transferable Items</Translate></b></p>
            <p><b><Translate>19. Police-Related Items</Translate></b></p>
            <p><b><Translate>20. Pornographic Materials and Adult Products</Translate></b></p>
            <p><b><Translate>21. Medical Drugs and Devices</Translate></b></p>
            <p><b><Translate>22. Circumvention Devices and Hacking Equipment</Translate></b></p>
            <p><b><Translate>23. Pirated Software</Translate></b></p>
            <p><b><Translate>24. Stolen Property</Translate></b></p>
            <p><b><Translate>25. Unauthorized Copies of Intellectual Property</Translate></b></p>
            <p><b><Translate>26. Used Clothing and Cosmetics</Translate></b></p>
            <p><b><Translate>27. Wildlife and Animal Products</Translate></b></p>
            <p><b><Translate>28. Sanctioned and Prohibited Items</Translate></b></p>
            <br/>
            <h3><b><Translate>1. GENERAL PROHIBITIONS:</Translate></b></h3>
            <p>
              <Translate>1.1 You may not post or sell any item that is restricted or prohibited by a federal, state or local law in any country or jurisdiction. Please be aware that the www.{serverUrl.servername} and www.gbx.world websites (each a "Site") function as global marketplaces, thus the selling or posting of items may be prohibited because of laws outside of the jurisdiction where you reside. Below, we have listed some categories of prohibited or restricted items. However, this list is not exhaustive; you, as the seller, are responsible for ensuring that you are not posting an item that is prohibited by law in any jurisdiction.</Translate>
            </p>
            <p><Translate>1.2 {serverUrl.servername} has chosen to also prohibit the posting of other categories of items which       may not be restricted or prohibited by law but are nonetheless controversial including: 
            (a) Items that encourage illegal activities (e.g. lock pick tools, synthetic urine for cheating drug tests); 
            (b) Items that are racially, religiously or ethnically derogatory, or that promote hatred, violence, racial or religious intolerance; 
            (c) Giveaways, lotteries, raffles, or contests; 
            (d) Stocks, bonds, investment interests, and other securities; 
            (e) Pornographic materials or items that are sexual in nature;
            (f) Items that do not offer a physical product for sale, such as electronic books and other digital goods.</Translate></p>
            <p>
            <Translate>1.3 {serverUrl.servername}, in its sole and exclusive discretion, reserves the right to impose additional restrictions and prohibitions.</Translate>
            </p>
            <br/>
            <h3><b><Translate>2. ARTIFACTS</Translate></b></h3>
            <p><Translate>2.1 Artifacts, cultural relics, historical grave markers, and related items are protected under the laws of Hong Kong, the PRC and other jurisdictions, and may not be posted or sold through the Site.</Translate></p>
            <p><Translate>2.2 The trade of cultural relics (including personal collection relics), gold, silver, other precious metals, rare animals and their products from the PRC to foreign (non-PRC) parties is strictly prohibited under PRC law and is accordingly prohibited on the Site.</Translate></p>
            <br/>
            <h3><b><Translate>3. CURRENCY AND STAMPS</Translate></b></h3>
            <p><Translate>3.1 {serverUrl.servername} strictly forbids the sale and purchase of currency, coins, bank notes, bonds, money orders, currency in digital or any intangible form (e.g. crypto-currency) and other securities, as well as the equipment and materials used to produce such items.</Translate></p>
            <p><Translate>3.2 Counterfeits of the identified articles in 3.1, legal tenders and stamps are strictly prohibited.</Translate></p>
             <p><Translate>3.3 Reproductions or replicas of coins as collectible items must be clearly marked with the word "COPY", "REPRODUCTION" or "REPLICA" and comply with all relevant local laws.</Translate></p>
            <br/>
            <h3><b><Translate>4. CONTRACTS AND TICKETS</Translate> </b></h3>
            <p><Translate>4.1 You are responsible for ensuring that your transaction is lawful and not in violation of any contractual obligation. Before posting an item on the Site, you should carefully read any contracts that you have entered into that might limit your right to sell your item on the Site. Some items, such as airline tickets, have terms printed on the item that may limit your ability to sell that item. In other cases, such as when you are distributing a company's products, you may have signed a separate contract restricting your ability to market the product.</Translate></p>
            <p><Translate>4.2 {serverUrl.servername} does not search for items that may raise these types of issues, nor can it review copies of private contracts, or adjudicate or take sides in private contract disputes. However, we want you to be aware that posting items in violation of your contractual obligations could put you at risk with third parties. {serverUrl.servername} therefore urges that you not list any item until you have reviewed any relevant contracts or agreements, and are confident you can legally sell it on the Site.</Translate></p>
      <p><Translate>4.3 If you have any questions regarding your rights under a contract or agreement, we strongly recommend that you contact the company with whom you entered into the contract and/or consult with an attorney.</Translate></p>
      <br/>
      <h3><b><Translate>5. CREDIT / DEBIT CARDS AND FINANCIAL INSTRUMENTS</Translate></b></h3>
      <p><Translate>5.1 Credit and debit cards cannot lawfully be transferred from one person to another, and therefore such items may not be listed on the Site.</Translate></p>
      <p><Translate>5.2 Card readers are prohibited from being listed on the Site.</Translate></p>
      <p><Translate>5.3 Listings that offer the sale or buying of gold, silver and other precious metals (not including jewelry) are prohibited.</Translate></p>
      <p><Translate>5.4 The listing or sale of stocks, bonds, credit, investment interests, or other securities is strictly prohibited.</Translate></p>
      <p><Translate>5.5 {serverUrl.servername} prohibits listings that offer financial services, including money transfers, issuing bank guarantees and letters of credit, loans, fundraising and funding for personal investment purposes etc.</Translate></p>
      <br/>
      <h3><b><Translate>6. ILLICIT DRUGS AND ASSOCIATED PARAPHERNALIA</Translate></b></h3>
      <p><Translate>6.1 The listing or sale of narcotics, steroids, poppy seeds, poppy seed products or other controlled substances (including all drugs listed in Schedules I, II, III, IV or V of the Uniform Controlled Substances Act, 21 U.S.C. 801 et seq.) is strictly forbidden on the Site.</Translate></p>
      <p><Translate>6.2 {serverUrl.servername} expressly forbids any and all posting of psychotropic drugs and narcotics.</Translate></p>
      <p><Translate>6.3 The listing or sale of drug paraphernalia, including all items that are primarily intended or designed for use in manufacturing, concealing, or using a controlled substance is strictly forbidden on the Site. Such items include, but are not limited to those items used for the ingestion of illicit substances including pipes such as water pipes, carburetor pipes, chamber pipes, ice pipes, bongs etc.</Translate></p>
      <br/>
      <h3><b><Translate>7. ETHNICALLY AND RACIALLY OFFENSIVE MATERIAL</Translate></b></h3>
      <p><Translate>7.1 Postings that are ethnically or racially offensive are prohibited on the Site. Sellers and purchasers must ensure that any wording used portrays appropriate sensitivity to those who might read it in their postings, and when they are offering or purchasing potentially offensive items or services.</Translate></p>
      <p><Translate>7.2 Occasionally, if materials are of historical value or integral to the item (such as a book title), members may use offensive words and phrases such as "Yang Guizi" in the subject and description of a posting. {serverUrl.servername} reserves the sole discretion to decide the removal of such items and encourages all members to treat others as they themselves would like to be treated.</Translate></p>
      <p><Translate>7.3 {serverUrl.servername} generally prohibits such materials promoting Nazism, the KKK, etc.</Translate></p>
      <br/>
      <h3><b><Translate>8. EVENT TICKET RESALE POLICY </Translate></b></h3>
      <p><Translate>8.1 {serverUrl.servername} allows the listing of tickets to performance, sporting and entertainment events to the extent permitted by law. However, as a ticket seller, you are responsible for ensuring that your particular transaction does not violate any applicable law or the terms on the ticket itself.</Translate></p>
      <br/>
      <h3><b><Translate>9. FACES, NAMES AND SIGNATURES</Translate></b></h3>
      <p><Translate>9.1 Items containing the likeness, image, name, or signature of another person are prohibited, unless the products were made or authorized by the person whose likeness, image, name or signature has been used.</Translate></p>
      <br/>
      <h3><b><Translate>10. FIREARMS, AMMUNITION, WEAPONS, EXPLOSIVES</Translate></b></h3>
      <p><Translate>10.1 The posting of, offering for sale, or offering for purchase of any arms, ammunition, military ordnance, weapons (including explosive weapons), and/or integral or essential parts and components is strictly prohibited. Such activity can result in your account being delisted.</Translate></p>
      <p><Translate>10.2 {serverUrl.servername} does not permit the posting, offering for sale, or offering of purchase of air guns, BB guns, paintball guns, harpoons, spear guns, or any other weapons that may discharge a projectile containing any gas, chemicals, or explosive substances. Replica, "look-alike", and imitation products of the above items under certain circumstances will be permitted only upon the express approval of {serverUrl.servername}.</Translate></p>
      <p><Translate>10.3 The posting of explosives, fireworks and related ignition and detonation equipment is strictly prohibited.</Translate></p>
      <p><Translate>10.4 Any service, instruction, processing, or aid for producing any biological, chemical, or nuclear weapons, or any other Weapons of Mass Destruction (WMD) or known associated agents is strictly prohibited by international law and is accordingly prohibited on the Site. Any violation of this policy will result in the notification of government authorities by {serverUrl.servername} and your account being delisted. </Translate></p>
      <p><Translate>10.5 Knives and other cutting instruments will in most cases be permitted to be listed. However, switchblade knives, gravity knives, bladed handheld devices, lock blade knives, knives with blades over 10cm in length, knives with fullers (blood grooves) and disguised knives are not permitted to be listed.</Translate></p>
      <p><Translate>10.6 Batons, stun guns, crossbows, knuckledusters, pepper spray and other weapons are prohibited from being listed on the Site.{serverUrl.servername} maintains discretion over what items are appropriate and may cause removal of a listing that it deems as a weapon.</Translate></p>
      <br/>
      <h3><b><Translate>11. ALCOHOL, FOOD AND BEVERAGES</Translate></b></h3>
      <p><Translate>11.1 The listing of alcohol is prohibited on the Site.</Translate></p>
      <br/>
      <h3><b><Translate>12. TOBACCO PRODUCTS</Translate></b></h3>
      <p><Translate>12.1 The posting of tobacco products, including but not limited to cigars, cigarettes, cigarette tobacco, pipe tobacco, hookah tobacco, chewing tobacco and tobacco leaf is forbidden on the Site.</Translate></p>
      <p><Translate>12.2 The posting of electronic cigarettes and accessories is prohibited.</Translate></p>
      <p><Translate>12.3 Equipment used in the processing of tobacco and manufacture of tobacco products is prohibited on the Site.</Translate></p>
      <br/>
      <h3><b><Translate>13. GOVERNMENT IDENTIFICATIONS, LICENSES AND UNIFORMS</Translate></b></h3>
      <p><Translate>3.1 The following items are not permitted to be listed on the Site:  
      (a) Items that claim to be, or appear similar to, official government identification documents or licenses, such as birth certificates, driving licenses, visas or passports. Furthermore, completed applications for such documents containing personal information may not be listed.  
      (b) Fake identification cards or any items that are designed for the production of such cards (e.g. ID card holograms). 
      (c) Articles of clothing or identification that claim to be, or appear similar to, official government uniforms. 
      (d) Military decorations, medals and awards, in addition to items with substantially similar designs. </Translate></p>
      <br/>
      <h3><b><Translate>14. HAZARDOUS AND DANGEROUS MATERIALS</Translate></b></h3>
      <p><Translate>14.1 The posting of any chemical products on the Site is prohibited.</Translate></p>
      <p><Translate>14.2 The posting, offering for sale, or offering for purchase of hazardous or dangerous materials (such as the categories of dangerous goods as defined under the International Maritime Dangerous Goods Code) is forbidden on the Site.</Translate></p>
      <p><Translate>14.3 The posting of any products containing harmful substances (e.g. toys containing lead paint) is forbidden on the Site.</Translate></p>
      <p><Translate>14.4 Automotive airbags are expressly forbidden on the Site due to containing explosive materials.</Translate></p>
      <p><Translate>14.5 Asbestos materials and/or products containing asbestos are prohibited.</Translate></p>
      <br/>
      <h3><b><Translate>15. HUMAN PARTS AND REMAINS </Translate></b></h3>
      <p><Translate>15.1 {serverUrl.servername} prohibits the listing of any humans, the human body, or any human body part. Examples of such prohibited items include, but are not limited to: organs, bones, blood, sperm and eggs.</Translate></p>
      <p><Translate>15.2 Skulls and skeletons are also forbidden</Translate></p>
      <p><Translate>15.3 Items made of human hair, such as wigs for commercial uses, are permitted.</Translate></p>
      <br/>
      <h3><b><Translate>16. INVOICES</Translate></b></h3>
      <p><Translate>16.1 The listing or sale of any form of invoices or receipts (including blank, pre-filled, or value added invoices or receipts), is strictly prohibited on the Site.</Translate></p>
      <br/>
      <h3><b><Translate>17. MAILING LISTS AND PERSONAL INFORMATION </Translate></b></h3>
      <p><Translate>17.1 The posting or sale of bulk email or mailing lists that contain personally identifiable information including names, addresses, phone numbers, fax numbers and email addresses, is strictly prohibited.</Translate></p>
      <p><Translate>17.2 Software or other tools which are designed or used to send unsolicited commercial email (i.e. "spam") are prohibited.</Translate></p>
      <br/>
      <h3><b><Translate>18. NON-TRANSFERABLE ITEMS</Translate></b></h3>
      <p><Translate>18.1 Non-transferable items may not be posted or sold through the Site. Many items including lottery tickets, airline tickets and some event tickets may not be resold or transferred.</Translate></p>
      <br/>
      <h3><b><Translate>19. POLICE-RELATED ITEMS</Translate></b></h3>
      <p><Translate>19.1 The posting of law enforcement badges or official law enforcement equipment from any public authority, including badges issued by the government of any country, is strictly prohibited.</Translate></p>
      <p><Translate>19.2 There are some police items that may be listed on the Site, provided they observe the following guidelines:  
      (a) Authorized general souvenir items, such as hats, mugs, pins, pens, buttons, cuff links, T-shirts, money clips that do not resemble badges, and paperweights that do not contain badges.  
      (b) Badges that are clearly not genuine or official (e.g. toy badges).  
      (c) Historical badges that do not resemble modern law enforcement badges, provided that the item description clearly states that the badge is a historical piece at least 75 years old or issued by an organization which no longer exists.</Translate></p>
      <p><Translate>19.3 Police uniforms may not be posted unless they are obsolete and in no way resemble current issue police uniforms. This fact must be clearly stated within the posting description. </Translate></p>
      <br/>
      <h3><b><Translate>20. PORNOGRAPHIC MATERIALS AND ADULT PRODUCTS </Translate></b></h3>
      <p><Translate>20.1 The posting or sale of pornographic materials is strictly prohibited, as it violates laws in many countries. While pornography is difficult to define and standards vary from nation to nation,</Translate></p>
      <p><Translate>20.2 {serverUrl.servername} strictly prohibits items depicting exploitation of minors, bestiality, rape sex, incest or sex with graphic violence or degradation.</Translate></p>
      <p><Translate>20.3 In determining whether listings or information should be removed from the Site, we consider the overall content of the posting, including photos, pictorials and text.</Translate></p>
      <p><Translate>20.4 {serverUrl.servername} does not permit the sale of SM products. Novelty sexually explicit items, including various types of toys, collectibles, or food items in the shape of genitalia and primarily intended for adult buyers are permitted in the appropriate category.</Translate></p>
      <br/>
      <h3><b><Translate>21. MEDICAL DRUGS AND DEVICES </Translate></b></h3>
      <p><Translate>21.1 The posting of all medical drugs, regardless whether classified as prescription, over-the-counter (OTC), vaccine or other types is prohibited on the Site.</Translate></p>
      <p><Translate>21.2 The listing or sale of sexual enhancement and weight loss supplements is prohibited.</Translate></p>
      <p><Translate>21.3 The posting of all medical devices is prohibited.</Translate></p>
      <p><Translate>21.4 Veterinary drugs and devices cannot be listed on the Site.</Translate></p>
      <p><Translate>21.5 Listings that offer medical or healthcare services, including services for medical treatment, rehabilitation, vaccination, health checks, psychological counselling, dietetics, plastic surgery, massage etc. are expressly prohibited on the Site.</Translate></p>
      <br/>
      <h3><b><Translate>22. REPLICA AND COUNTERFEIT ITEMS </Translate></b></h3>
      <p><Translate>22.1 Listing of counterfeits, non-licensed replicas, or unauthorized items, such as counterfeit designer garments, watches, handbags, sunglasses, or other accessories, are strictly limited on the Site.</Translate></p>
      <p><Translate>22.2 If the products sold bear the name or logo of a company, but did not originate from or were not endorsed by that company, such products are limited in terms of their sale on the site.</Translate></p>
      <p><Translate>22.3 Postings of branded products are permitted if a certificate of authorization has been issued by the brand owner.</Translate></p>
      <p><Translate>22.4 Postings offering to sell or purchase replicas, counterfeits or other unauthorized items may be subject to removal by {serverUrl.servername}. Repeated postings of counterfeit or unauthorized items shall result in the immediate suspension of your membership. To learn more about {serverUrl.servername}’s policy on replica and counterfeit items please contact us.</Translate></p>
      <br/>
      <h3><b><Translate>23. HACKING EQUIPMENT</Translate></b></h3>
      <p><Translate>23.1 Descramblers or other items that can be used to gain unauthorized access to television programming (such as satellite and cable TV), internet access, telephone, data or other protected, restricted, or premium services are prohibited. Stating the item is for educational or test purposes will not legitimize a product that is otherwise inappropriate. Some examples of items which are not permitted include smart cards and card programmers, descramblers, DSS emulators and hacking software.</Translate></p>
      <p><Translate>23.2 Similarly, information on "how to" descramble or gain access to cable or satellite television programming or other services without authorization or payment is prohibited. {serverUrl.servername}'s policy is to prohibit any encouragement of this type of activity.</Translate></p>
      <p><Translate>23.3 Any and all unauthorized circumvention or hacking devices not included in the above are also strictly prohibited.</Translate></p>
      <p>23.4 Devices designed to intentionally block, jam or interfere with authorized radio communications, such as cellular and personal communication services, police radar, global positioning systems (GPS) and wireless networking services (Wi-Fi) are prohibited. </p>
      <br/>
      <h3><b><Translate>24. SOFTWARE  </Translate></b></h3>
      <p><Translate>24.1 Academic software: 
      (a) Academic software is software sold at discounted prices to students, teachers, and employees of accredited learning institutions.  
      (b) On the Site, please do not list any academic software unless you are so authorized. Postings violating {serverUrl.servername}'s academic software policy may be deleted prior to publication.  
      (c) For postings of academic software on behalf of an authorized educational reseller or an educational institution, such licensure must be stated conspicuously in the listings. A certificate of authorization issued by the authorized educational reseller (or the educational institution) must also be provided to {serverUrl.servername}.</Translate></p>
      <p><Translate>24.2 OEM software: 
      (a) Do not list "OEM" or "bundled" copies of software on the Site unless you are selling it with computer hardware. Original Equipment Manufacturer (OEM), or bundled software, is software that is obtained as part of the purchase of a new computer. OEM software licenses usually prohibit the purchaser from reselling the software without the computer or, in some cases, without any computer hardware.</Translate></p>
      <br/>
      <h3><b><Translate>25. SPY EQUIPMENT </Translate></b></h3>
      <p><Translate>25.1 The listing or sale of spy equipment is not permitted on the Site.</Translate></p>
      <p><Translate>25.2 The listing of devices used for interception of wire, oral and electronic communications is prohibited.</Translate></p>
      <br/>
      <h3><b><Translate>26. STOLEN PROPERTY</Translate></b></h3>
      <p><Translate>26.1 The posting or sale of stolen property is strictly forbidden on the Site, and violates international law. Stolen property includes items taken from private individuals, as well as property taken without authorization from companies or governments.</Translate></p>
      <p><Translate>26.2 {serverUrl.servername} supports and cooperates with law enforcement efforts involving the recovery of stolen property and the prosecution of responsible individuals. If you are concerned that the images and/or text in your item description have been used by another Site user without your authorization, or that your intellectual property rights have been violated by such user, please contact our service team at info@{serverUrl.servername}.</Translate></p>
      <br/>
      <h3><b><Translate>27. TRANSIT RELATED ITEMS</Translate></b></h3>
      <p><Translate>27.1 The following items related to the commercial airline and public transportation industries may not be listed on the Site:  
      (a) Any article of clothing or identification related to transportation industries, including but not limited to, commercial airline pilot uniforms, flight attendant uniforms, airport service personnel uniforms, uniforms related to railway industries, and uniforms of security personnel of public transport industries. Vintage clothing related to commercial airlines or other public transport may be listed on the Site provided that the item description clearly states that the item is at least 10 years old, is no longer in use by the airline or other public transport authority and does not resemble any current uniform.  
      (b) Manuals or other materials related to mass commercial public transportation, including safety manuals published by commercial airlines or entities operating subways, trains or buses. Such items may only be listed if the description clearly states that the material is obsolete and no longer in use by the airline or other transit authority.  
      (c) Any official, internal, or non-public documents.</Translate></p>
      <br/>
      <h3><b><Translate>28. UNAUTHORIZED COPIES OF INTELLECTUAL PROPERTY</Translate></b></h3>
      <p><Translate>28.1 The listing or sale of unauthorized (pirated, duplicated, backup, bootleg etc.) copies of software programs, video games, music albums, movies, television programs, photographs or other protected works is forbidden on the Site.</Translate></p>
      <br/>
      <h3><b><Translate>29. USED CLOTHING AND COSMETICS</Translate></b></h3>
      <p><Translate>29.1 Used undergarments may not be listed or sold on the Site. Other used clothing may be listed, so long as the clothing has been thoroughly cleaned. Postings that contain inappropriate or extraneous descriptions will be removed.</Translate></p>
      <p><Translate>29.2 The listing or sale of used cosmetics is prohibited on the Site.</Translate></p>
      <br/>
      <h3><b><Translate>30. WILDLIFE AND ANIMAL PRODUCTS</Translate></b></h3>
      <p><Translate>30.1 The listing or sale of any animal (including any animal parts which may include pelts, skins, internal organs, teeth, claws, shells, bones, tusks, ivory and other parts) protected by the Convention on International Trade in Endangered Species of Wild Fauna and Flora (CITES) or any other local law or regulation is strictly forbidden on the Site.</Translate></p>
      <p><Translate>30.2 The listing or sale of products made with any part of and/or containing any ingredient derived from sharks or marine mammals is prohibited on the Site.</Translate></p>
      <p><Translate>30.3 The listing or sale of products made from cats, dogs, bears, and any processing equipments.</Translate></p>
      <p><Translate>30.4 The listing or sale of any live animal is prohibited on the Site.</Translate></p>
      <br/>
      <h3><b><Translate>31. TEXTILE QUOTAS</Translate></b></h3>
      <p><Translate>31.1 The offering for sale or purchase of textile quotas is prohibited on the Site.</Translate></p>
      <br/>
      <h3><b><Translate>32. SANCTIONED AND PROHBITED ITEMS</Translate></b></h3>
      <p><Translate>32.1 Products prohibited by laws, regulations, sanctions and trade restrictions in any relevant country or jurisdiction worldwide are strictly forbidden.</Translate></p>
      <br/>
      <br/>
      <h3><b>IMPORTANT:</b></h3>
      <p><Translate>This list should not be considered exhaustive in nature and shall be updated on a continuous basis. If you are unsure about the product you wish to list with the Site in regard to its appropriateness or legality, please contact our customer service department.</Translate></p>
      <p><Translate>{serverUrl.servername} reserves the right to prevent the posting of, or the removal of, any product that does not adhere to the stated policies, guidelines and regulations. After repeat offences the user’s account may be blocked, and the user’s access to the site may be entirely denied.</Translate></p>

      
      </Translator>   
         </div>
        </div>
      {/* Footer */}
        <FooterContainer />
      </div>
      </>
    )
  }
}





