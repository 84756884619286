import {connect} from 'react-redux'
import footer from './footer'


const mapStoreToProps=state=>({

})

const mapDispatchToProps=dispatch=>({
	
})

export default connect(mapStoreToProps,mapDispatchToProps)(footer)