import {
  GET_COOKIE_BEGIN,
  GET_COOKIE_SUCCESS,
  GET_COOKIE_FAIL
} from '../action/cookieAction'

const initialState = {
  loading: false,
  cookies: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COOKIE_BEGIN:
      return {
        loading: true,
        error: null
      }
    case GET_COOKIE_SUCCESS:
      return {
        loading: false,
        cookies: action.payload.data
      }
    case GET_COOKIE_FAIL:
      return {
        loading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}