import { getProductDetail} from '../../redux/action/productdetailAction'
import { connect } from 'react-redux'
import productDetail from './productDetail'

const mapStoreToProps = state => ({
  productData: state.productdetail.productdetails,
})
const mapDispatchToProps = dispatch => ({
  getProductDetail: (requestdata,requestdatapost)=>dispatch(getProductDetail(requestdata,requestdatapost)),
})

export default connect(mapStoreToProps, mapDispatchToProps)(productDetail)