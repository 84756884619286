// import serverCall from '../../modules/serverCall'
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import serverUrl from '../../configs/constant'


const URL = 'https://'+serverUrl.serverdomain+'/backend'

const serverCall = (config) => {
  //header authorization
  // if (Auth.user_token) {
  //   const token = Auth.getToken()
  //   config.headers = {
  //     "authorization": token
  //   }
  // }
  //interceptors handle network error
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      console.log('error.response',error.response);
      if (!error.response) {
        error.response = {
          data: 'net work error',
          status: 500
        }
      }
      if(error.response.status===401){
        localStorage.setItem('isLoggedin',0);
        localStorage.setItem('userType','');
        localStorage.setItem('userName','');
        localStorage.setItem('userData','');
        localStorage.setItem('dataurl','');
        localStorage.setItem('wishListCountData','');
        window.location = 'https://'+serverUrl.serverdomain+'/backend/logout';
        const MySwal = withReactContent(Swal)
        MySwal.fire('<p>Something went wrong please try again</p>');
        throw error
      }else if(error.response.status===302){
        localStorage.setItem('isLoggedin',0);
        localStorage.setItem('userType','');
        localStorage.setItem('userName','');
        localStorage.setItem('userData','');
        localStorage.setItem('dataurl','');
        localStorage.setItem('wishListCountData','');
        window.location = 'https://'+serverUrl.serverdomain+'/backend/logout';
        const MySwal = withReactContent(Swal)
        MySwal.fire('<p>Something went wrong please try again</p>');
        throw error
      }else if(error.response.status===500){
        // localStorage.setItem('isLoggedin',0);
        // localStorage.setItem('userType','');
        // localStorage.setItem('userName','');
        // localStorage.setItem('userData','');
        // localStorage.setItem('dataurl','');
        // localStorage.setItem('wishListCountData','');
        // window.location = 'https://'+serverUrl.serverdomain+'/backend/logout';
        // const MySwal = withReactContent(Swal)
        // MySwal.fire('<p>Something went wrong please try again</p>');
        throw error
      }
      return Promise.reject(error);
    });
  config.baseURL = URL
  return axios(config)
}

export const getLoginData=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_LOGIN_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/login-auth`,
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_LOGIN_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_LOGIN_FAIL,
      payload: {error}
    })
    return error
  })
}

export const GET_LOGIN_BEGIN = 'GET_LOGIN_BEGIN'
export const GET_LOGIN_SUCCESS='GET_LOGIN_SUCCESS'
export const GET_LOGIN_FAIL='GET_LOGIN_FAIL'