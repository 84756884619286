import { getProductCountDeal} from '../../redux/action/productAction'
import { connect } from 'react-redux'
import dealsandPromotions from './dealsandPromotions'
const mapStoreToProps = state => ({
  productCountDeal: state.product.deal,
})
const mapDispatchToProps = dispatch => ({
  getProductCountDeal: ()=>dispatch(getProductCountDeal()),
})

export default connect(mapStoreToProps, mapDispatchToProps)(dealsandPromotions)