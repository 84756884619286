import { resetPasswordData} from '../../redux/action/passwordAction'
import { connect } from 'react-redux'
import newPassword from './newPassword'
const mapStoreToProps = state => ({
	resetPassword: state.password.resetpassword
})
const mapDispatchToProps = dispatch => ({
  resetPasswordData:(requestdata)=>dispatch(resetPasswordData(requestdata))
})

export default connect(mapStoreToProps, mapDispatchToProps)(newPassword)