import {
  GET_PRODUCTLIST_BEGIN,
  GET_PRODUCTLIST_SUCCESS,
  GET_PRODUCTLIST_FAIL,
  GET_PRODUCTLIST_SEARCH_BEGIN,
  GET_PRODUCTLIST_SEARCH_SUCCESS,
  GET_PRODUCTLIST_SEARCH_FAIL
} from '../action/productlistAction'

const initialState = {
  loading: false,
  productlists: null,
  productlistsearch: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTLIST_BEGIN:
      return {
        loading: true,
        error: null
      }
    case GET_PRODUCTLIST_SUCCESS:
      return {
        loading: false,
        productlists: action.payload.data
      }
    case GET_PRODUCTLIST_FAIL:
      return {
        loading: false,
        error: action.payload.error
      } 
      case GET_PRODUCTLIST_SEARCH_BEGIN:
      return {
        loading: true,
        error: null
      }
    case GET_PRODUCTLIST_SEARCH_SUCCESS:
      return {
        loading: false,
        productlists: action.payload.data
      }
    case GET_PRODUCTLIST_SEARCH_FAIL:
      return {
        loading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}