import {combineReducers} from 'redux'
import signin from './signinReducer'
import product from './productReducer'
import header from './headerReducer'
import topbar from './topbarReducer'
import menu from './menuReducer'
import featured from './featuredReducer'
import productlist from './productlistReducer'
import category from './categoryReducer'
import subcategory from './subcategoryReducer'
import subonecategory from './subonecategoryReducer'
import subtwocategory from './subtwocategoryReducer'
import productdetail from './productdetailReducer'
import advancesearch from './advancesearchReducer'
import productresult from './productresultReducer'
import vendorresult from './vendorresultReducer'
import storeresult from './vendorstoreReducer'
import login from './loginReducer'
import verifyemail from './verifyemailReducer'
import cookie from './cookieReducer'
import password from './passwordReducer'
import wishlist from './wishlistReducer'

export default combineReducers({
  signin,
  product,
  cookie,
  header,
  topbar,
  menu,
  featured,
  productlist,
  category,
  subcategory,
  subonecategory,
  subtwocategory,
  productdetail,
  advancesearch,
  productresult,
  vendorresult,
  storeresult,
  login,
  verifyemail,
  password,
  wishlist
})