import React, { Component } from 'react'
import FooterContainer from '../../components/footer/footerContainer'
import HeaderContainer from '../../components/header/headerContainer'
import './stylesheets/login.css'
import jumpTo from '../../modules/Navigation'
import {isMobile} from 'react-device-detect';
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../configs/constant'


export default class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email:"",
      password:"",
      devicetype:localStorage.getItem('devicetype'),
      deviceos:localStorage.getItem('deviceos'),
      devicebrowser:localStorage.getItem('devicebrowser'),
      latitude: localStorage.getItem('latitude'),
      longitude:localStorage.getItem('longitude'),
      userdevice:localStorage.getItem('userdevice'),
      selectedLanguage:localStorage.getItem('selectedLanguage'),
      preselectedLanguage:localStorage.getItem('preselectedLanguage'),
    }
  }
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }


  handleFormSubmit = event => {
    event.preventDefault();
    // console.log('this.state',this.state);
    var validationData = {
      email:this.state.email,
      password:this.state.password,
    }
    this.props.getLoginData(validationData);
    // const validation = this.validator.validate(this.state);
    // this.setState({
    //   validation
    // });
  }

  handleInputChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  render() {
    const logocompany = require('../../assets/images/global-logo.png');

    const loginDetails  = this.props.loginData;
    let errorMsg = '';

    if(loginDetails !==null && loginDetails !== undefined){
      if(loginDetails.status === 1){
        var dataurl ='https://'+serverUrl.serverdomain+'/backend/'+loginDetails.msg;
        localStorage.setItem('isLoggedin',1);
        localStorage.setItem('userType',loginDetails.usertype);
        localStorage.setItem('userName',loginDetails.userdata);
        localStorage.setItem('userData',loginDetails.id);
        localStorage.setItem('dataurl',dataurl);
        window.location = dataurl;
      }else{
        errorMsg = loginDetails.msg;
      }
    }

    const hintButton = 0;
    return (
      <>
      <HeaderContainer />
      <div className="pro_midcontianer mid_container">
      <Translator 
                from={this.state.preselectedLanguage}
                to={this.state.selectedLanguage}
                googleApiKey={serverUrl.googleApiTranslation}
              >
        <div className="container">
          <div className="row topSpace">
            <div className="col-sm-2">
              <div className="ms1_left">
                <div className="ms1_logo">
                  <a onClick={() => jumpTo('/landing-marketplace')}>
                  <img src={logocompany} alt="img" className="img-responsive" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-10">
                {isMobile === false ?
                  <div className="ms1_right">
                    <h2 className="loginHeading">
                      <Translate>THE WORLD'S ONLINE STORE</Translate>
                    </h2>
                    <p>
                      <Translate>Our Platform - Your Business; Your Success - Our Priority</Translate>
                    </p>
                  </div>
                   :
                  <div className="ms1_right">
                      <h2 className="loginHeading">
                        <Translate>THE WORLD'S ONLINE STORE</Translate>
                      </h2>
                      <p>
                        <Translate>Our Platform - Your Business</Translate><br/><Translate>Your Success - Our Priority</Translate>
                      </p>
                  </div>}
            </div>
          </div>
          <div className="row login_wrapper">
              <div className="col-md-4 col-sm-4">
                <div className="alert alert-default list_view_login">
                  <h5><Translate>Before you Login/Register!</Translate></h5>
                 
                  <p><Translate>BY USING THE WEBSITE OR APP AND THE SERVICES OFFERED ON THE WEBSITE AND APP, YOU AGREE TO FOLLOW AND BE BOUND BY THESE TERMS AND CONDITIONS AND THE {serverUrl.servername} PRIVACY POLICY WHICH ARE HEREBY INCORPORATED BY REFERENCE. IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS AND CONDITIONS AND THE PRIVACY POLICY, YOU MAY NOT ACCESS OR USE THIS WEBSITE OR APP AND THE SERVICES OFFERED ON THE WEBSITE OR VIA THE APP.</Translate>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b><a onClick={() => jumpTo('/terms-condition')}> <Translate>TERMS OF USE</Translate></a></b></p>
                </div>
              </div>
              <div className="col-md-8 col-sm-8">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h3 className="panel-heading headerLogin"><b><span></span><Translate> LOGIN</Translate></b></h3>
                  </div>
                  <div className="panel-body DataBody">
                    <form className="form-horizontal login-form" role="form" onSubmit={this.handleFormSubmit}>
                      <span algin='center' style={{color:'#FF0000'}}>{errorMsg}</span>
                      <input type="hidden" name="user_type" id="user_type" value="Buyer"/>
                      <div className="form-group">
                        <label className="col-md-4 control-label emailText"><Translate>E-Mail Address</Translate></label>
                        <div className="col-md-6 mb-2">
                          <input id="email" type="email" onChange={this.handleInputChange} className="form-control" name="email" required/>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-md-4 control-label emailText"><Translate>Password</Translate></label>
                        <div className="col-md-6">
                          <input id="password" type="password" onChange={this.handleInputChange} className="form-control" name="password" required/>
                        </div>
                        {hintButton === 1 ?
                        <span>
                          <button type="button" className="btn btn-default"><Translate>Password Hint</Translate></button>
                          
                        </span>
                        : ""}
                      </div>
                      <div className="form-group">
                        <div className="col-md-6 offset-md-4">
                          <div className="checkbox ">
                            <label>
                                <input type="checkbox" name="remember"/> <Translate>Remember Me</Translate>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="col-md-6 offset-md-4">
                          <button type="submit" onClick={this.handleFormSubmit} className="btn btn-primary login-btn loginColor">
                              <i className="fas fa-sign-in-alt"></i><Translate>  LOGIN</Translate>
                          </button>
                          <a className="btn btn-link frgtBtn" onClick={() => jumpTo('/reset-password')}><Translate>Forgot Your Password?</Translate></a>
                        </div>
                      </div>
                    
                    </form>

                  </div>
                </div>
              </div>
            </div>
        </div>
        </Translator>
        {/* Footer */}
          <FooterContainer />
          
      </div>
      </>
    )
  }
}





