import {postCookieData} from '../../redux/action/cookieAction'
import { connect } from 'react-redux'
import home from './home'
const mapStoreToProps = state => ({
	cookieData: state.cookie.cookies
})
const mapDispatchToProps = dispatch => ({
	postCookieData: (sendData)=>dispatch(postCookieData(sendData))
})

export default connect(mapStoreToProps, mapDispatchToProps)(home)