import {
  GET_PRODUCTDETAILS_BEGIN,
  GET_PRODUCTDETAILS_SUCCESS,
  GET_PRODUCTDETAILS_FAIL
} from '../action/productdetailAction'

const initialState = {
  loading: false,
  productdetails: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTDETAILS_BEGIN:
      return {
        loading: true,
        error: null
      }
    case GET_PRODUCTDETAILS_SUCCESS:
      return {
        loading: false,
        productdetails: action.payload.data
      }
    case GET_PRODUCTDETAILS_BEGIN:
      return {
        loading: false,
        error: action.payload.error
      } 
    default:
      return state
  }
}