import serverUrl from '../../configs/constant'
import axios from 'axios'



const URL = 'https://'+serverUrl.serverdomain+'/backend'

const serverCall = (config) => {
  //header authorization
  // if (Auth.user_token) {
  //   const token = Auth.getToken()
  //   config.headers = {
  //     "authorization": token
  //   }
  // }
  //interceptors handle network error
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (!error.response) {
        error.response = {
          data: 'net work error',
          status: 500
        }
      }
      if(error.response.status===401){
        // Auth.logout()
        // jumpTo('/login')
        throw error
      }
      return Promise.reject(error);
    });
  config.baseURL = URL
  return axios(config)
}

export const getVerifyData=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_VERIFY_BEGIN,
  })
  return serverCall({
    method:'GET',
    url:`/verify-email/`+requestdata,
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
  })
  .then(res=>{
    dispatch({
      type: GET_VERIFY_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_VERIFY_FAIL,
      payload: {error}
    })
    return error
  })
}

export const GET_VERIFY_BEGIN = 'GET_VERIFY_BEGIN'
export const GET_VERIFY_SUCCESS='GET_VERIFY_SUCCESS'
export const GET_VERIFY_FAIL='GET_VERIFY_FAIL'