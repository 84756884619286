import serverCall from '../../modules/serverCall'

export const getAllProducts=()=>dispatch=>{
  dispatch({
    type:GET_ALL_PRODUCTS_BEGIN,
  })
  return serverCall({
    method:'GET',
    url:`/products`
  })
  .then(res=>{
    dispatch({
      type: GET_ALL_PRODUCTS_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_ALL_PRODUCTS_FAIL,
      payload: {error}
    })
    return error
  })
}

export const getProduct=(id)=>dispatch=>{
  dispatch({
    type:GET_PRODUCT_BEGIN,
  })
  return serverCall({
    method:'GET',
    url:`/products/${id}`
  })
  .then(res=>{
    dispatch({
      type: GET_PRODUCT_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_PRODUCT_FAIL,
      payload: {error}
    })
    return error
  })
}

export const getProductsByCategory=(c)=>dispatch=>{
  dispatch({
    type:GET_PRODUCTS_BY_CATEGORY_BEGIN,
  })
  return serverCall({
    method:'GET',
    url:`/products?category=${c}`
  })
  .then(res=>{
    dispatch({
      type: GET_PRODUCTS_BY_CATEGORY_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_PRODUCTS_BY_CATEGORY_FAIL,
      payload: {error}
    })
    return error
  })
}

export const search=(text)=>dispatch=>{
  dispatch({
    type:SEARCH_BEGIN,
  })
  return serverCall({
    method:'GET',
    url:`/search?query=${text}`
  })
  .then(res=>{
    dispatch({
      type: SEARCH_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: SEARCH_FAIL,
      payload: {error}
    })
    return error
  })
}

export const getProductCount=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_ALL_PRODUCTS_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/landing/landing-home`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_ALL_PRODUCTS_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_ALL_PRODUCTS_FAIL,
      payload: {error}
    })
    return error
  })
}

export const getProductCountCity=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_ALL_PRODUCTS_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/landing/landing-city`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_ALL_PRODUCTS_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_ALL_PRODUCTS_FAIL,
      payload: {error}
    })
    return error
  })
}

export const getProductCountProvince=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_ALL_PRODUCTS_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/landing/landing-province`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_ALL_PRODUCTS_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_ALL_PRODUCTS_FAIL,
      payload: {error}
    })
    return error
  })
}

export const getProductCountDeal=()=>dispatch=>{
  dispatch({
    type:GET_DEAL_BEGIN,
  })
  return serverCall({
    method:'GET',
    url:`/api/commen/deals-promotions`
  })
  .then(res=>{
    dispatch({
      type: GET_DEAL_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_DEAL_FAIL,
      payload: {error}
    })
    return error
  })
}

export const getProductCountSagment=()=>dispatch=>{
  dispatch({
    type:GET_ALL_SAGMENT_BEGIN,
  })
  return serverCall({
    method:'GET',
    url:`/api/commen/market-sagment`
  })
  .then(res=>{
    dispatch({
      type: GET_ALL_SAGMENT_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_ALL_SAGMENT_FAIL,
      payload: {error}
    })
    return error
  })
}

export const getCountryList=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_ALL_COUNTRY_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/commen/country-list`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_ALL_COUNTRY_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_ALL_COUNTRY_FAIL,
      payload: {error}
    })
    return error
  })
}


export const APPLY_FILTERS_BEGIN='APPLY_FILTERS_BEGIN'
export const APPLY_FILTERS_SUCCESS='APPLY_FILTERS_SUCCESS'
export const APPLY_FILTERS_FAIL='APPLY_FILTERS_FAIL'


export const SEARCH_BEGIN='SEARCH_BEGIN'
export const SEARCH_SUCCESS='SEARCH_SUCCESS'
export const SEARCH_FAIL='SEARCH_FAIL'


export const GET_ALL_PRODUCTS_BEGIN='GET_ALL_PRODUCTS_BEGIN'
export const GET_ALL_PRODUCTS_SUCCESS='GET_ALL_PRODUCTS_SUCCESS'
export const GET_ALL_PRODUCTS_FAIL='GET_ALL_PRODUCTS_FAIL'

export const GET_ALL_SAGMENT_BEGIN='GET_ALL_SAGMENT_BEGIN'
export const GET_ALL_SAGMENT_SUCCESS='GET_ALL_SAGMENT_SUCCESS'
export const GET_ALL_SAGMENT_FAIL='GET_ALL_SAGMENT_FAIL'

export const GET_ALL_COUNTRY_BEGIN='GET_ALL_COUNTRY_BEGIN'
export const GET_ALL_COUNTRY_SUCCESS='GET_ALL_COUNTRY_SUCCESS'
export const GET_ALL_COUNTRY_FAIL='GET_ALL_COUNTRY_FAIL'

export const GET_PRODUCT_BEGIN='GET_PRODUCT_BEGIN'
export const GET_PRODUCT_SUCCESS='GET_PRODUCT_SUCCESS'
export const GET_PRODUCT_FAIL='GET_PRODUCT_FAIL'

export const GET_DEAL_BEGIN='GET_DEAL_BEGIN'
export const GET_DEAL_SUCCESS='GET_DEAL_SUCCESS'
export const GET_DEAL_FAIL='GET_DEAL_FAIL'

export const GET_PRODUCTS_BY_CATEGORY_BEGIN='GET_PRODUCTS_BY_CATEGORY_BEGIN'
export const GET_PRODUCTS_BY_CATEGORY_SUCCESS='GET_PRODUCTS_BY_CATEGORY_SUCCESS'
export const GET_PRODUCTS_BY_CATEGORY_FAIL='GET_PRODUCTS_BY_CATEGORY_FAIL'