import {
  GET_SHOP_BEGIN,
  GET_SHOP_SUCCESS,
  GET_SHOP_FAIL,
  GET_WISHLIST_BEGIN,
  GET_WISHLIST_SUCCESS,
  GET_WISHLIST_FAIL
} from '../action/wishlistAction'

const initialState = {
  loading: false,
  shopping: null,
  wishs: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SHOP_BEGIN:
      return {
        loading: true,
        error: null
      }
    case GET_SHOP_SUCCESS:
      return {
        loading: false,
        shopping: action.payload.data
      }
    case GET_SHOP_FAIL:
      return {
        loading: false,
        error: action.payload.error.response.data
      }
    case GET_WISHLIST_BEGIN:
      return {
        loading: true,
        error: null
      }
    case GET_WISHLIST_SUCCESS:
      return {
        loading: false,
        wishs: action.payload.data
      }
    case GET_WISHLIST_FAIL:
      return {
        loading: false,
        error: action.payload.error.response.data
      }
    default:
      return state
  }
}