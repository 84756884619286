import {
  GET_LOGIN_BEGIN,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_FAIL
} from '../action/loginAction'

const initialState = {
  loading: false,
  logindata: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGIN_BEGIN:
      return {
        loading: true,
        error: null
      }
    case GET_LOGIN_SUCCESS:
      return {
        loading: false,
        logindata: action.payload.data
      }
    case GET_LOGIN_FAIL:
      return {
        loading: false,
        error: action.payload.error.response.data
      }
    default:
      return state
  }
}