import serverCall from '../../modules/serverCall'

export const getProductDetail=(requestdata,requestdatapost)=>dispatch=>{
  dispatch({
    type:GET_PRODUCTDETAILS_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/product/product-detail/`+requestdata,
    data:requestdatapost
  })
  .then(res=>{
    dispatch({
      type: GET_PRODUCTDETAILS_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_PRODUCTDETAILS_FAIL,
      payload: error
    })
    return error
  })
}


export const GET_PRODUCTDETAILS_BEGIN = 'GET_PRODUCTDETAILS_BEGIN'
export const GET_PRODUCTDETAILS_SUCCESS='GET_PRODUCTDETAILS_SUCCESS'
export const GET_PRODUCTDETAILS_FAIL='GET_PRODUCTDETAILS_FAIL'
