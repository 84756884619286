import {
  GET_HEADER_BEGIN,
  GET_HEADER_SUCCESS,
  GET_HEADER_FAIL,
  GET_WISH_BEGIN,
  GET_WISH_SUCCESS,
  GET_WISH_FAIL
} from '../action/headerAction'

const initialState = {
  loading: false,
  headers: null,
  wishcount: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HEADER_BEGIN:
      return {
        loading: true,
        error: null
      }
    case GET_HEADER_SUCCESS:
      return {
        loading: false,
        headers: action.payload.data
      }
    case GET_HEADER_FAIL:
      return {
        loading: false,
        error: action.payload.error.response.data
      }
    case GET_WISH_BEGIN:
      return {
        loading: true,
        error: null
      }
    case GET_WISH_SUCCESS:
      return {
        loading: false,
        wishcount: action.payload.data
      }
    case GET_WISH_FAIL:
      return {
        loading: false,
        error: action.payload.error.response.data
      }
    default:
      return state
  }
}