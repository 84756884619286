import React, { Component } from 'react';
// import Perf from 'react-addons-perf';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import { registerNav } from './modules/Navigation'
import Auth from './modules/Auth'
import landingMarketplaceContainer from './pages/landingMarketplace/langingMarketplaceContainer'
import dealsandPromotionsContainer from './pages/dealsandPromotions/dealsandPromotionsContainer'
import landingMarketsegmentContainer from './pages/landingMarketsegment/landingMarketsegmentContainer'
import landingShopContainer from './pages/landingShop/landingShopContainer'
import loginContainer from './pages/login/loginContainer'
import signupContainer from './pages/signup/signupContainer'
import termsConditionContainer from './pages/termsCondition/termsConditionContainer'
import aboutUsContainer from './pages/aboutUs/aboutUsContainer'
import privacyPolicyContainer from './pages/privacyPolicy/privacyPolicyContainer'
import contactUsContainer from './pages/contactUs/contactUsContainer'
import landingProvinceContainer from './pages/landingProvince/landingProvinceContainer'
import marketplaceHomeContainer from './pages/marketplaceHome/marketplaceHomeContainer'
import productDetailContainer from './pages/productDetail/productDetailContainer'
import intermediaryPageContainer from './pages/intermediaryPage/intermediaryPageContainer'
import advanceSearchContainer from './pages/advanceSearch/advanceSearchContainer'
import homeContainer from './pages/home/homeContainer'
import productResultContainer from './pages/productResult/productResultContainer'
import vendorResultContainer from './pages/vendorResult/vendorResultContainer'
import dealspromotionHomeContainer from './pages/dealspromotionHome/dealspromotionHomeContainer'
import featuredListContainer from './pages/featuredList/featuredListContainer'
import countryAvailableContainer from './pages/countryAvailable/countryAvailableContainer'
import vendorStoreContainer from './pages/vendorStore/vendorStoreContainer'
import verifyEmailContainer from './pages/verifyEmail/verifyEmailContainer'
import forgetPasswordContainer from './pages/forgetPassword/forgetPasswordContainer'
import newPasswordContainer from './pages/newPassword/newPasswordContainer'
import buyerDashboardContainer from './pages/backend/buyer/buyerDashboard/buyerDashboardContainer'
Auth.getAuthorizationToken();
class App extends Component {

  render() {
    return (
      <span>
        <Router ref={registerNav}>
          <Switch>
            <Route exact path="/landing-province" component={landingProvinceContainer} />
            <Route exact path="/landing-marketplace" component={landingMarketplaceContainer} />
            <Route exact path="/home" component={intermediaryPageContainer} />
            <Route exact path="/landing-deal-promotion" component={dealsandPromotionsContainer} />
            <Route exact path="/landing-market-segment" component={landingMarketsegmentContainer} />
            <Route exact path="/landing-shop" component={landingShopContainer} />
            <Route exact path="/login" component={loginContainer} />
            <Route exact path="/marketplace-home" component={marketplaceHomeContainer} />
            <Route exact path="/deals-promotion" component={dealspromotionHomeContainer} />
            <Route exact path="/featured-list" component={featuredListContainer} />
            <Route exact path="/product-detail/:productid" component={productDetailContainer} />
            <Route exact path="/marketplace/:country" component={countryAvailableContainer} />
            <Route exact path="/vendor-store/:vendor" component={vendorStoreContainer} />
            <Route exact path="/register/:registervalue/:affcode?" component={signupContainer} />
            <Route exact path="/terms-condition" component={termsConditionContainer} />
            <Route exact path="/verify-email/:registervalue/" component={verifyEmailContainer} />
            <Route exact path="/about-us" component={aboutUsContainer} />
            <Route exact path="/privacy-policy" component={privacyPolicyContainer} />
            <Route exact path="/contact-us" component={contactUsContainer} />
            <Route exact path="/advance-search" component={advanceSearchContainer} />
            <Route exact path="/" component={homeContainer} />
            <Route path="/product-result/:name" component={productResultContainer} />
            <Route path="/vendor-result/:name" component={vendorResultContainer} />
            <Route path="/reset-password" component={forgetPasswordContainer} />
            <Route path="/new-password/:token" component={newPasswordContainer} />
            <Route path="/buyer-dashboard" component={buyerDashboardContainer} />
            // <Route path="/privacy-policy"component={ Redirect } loc="https://meetflo.zendesk.com/hc/en-us/articles/230425728-Privacy-Policies"/>
            // <Redirect to='/landing-marketplace' />
          </Switch>
        </Router>
        </span>
    );
  }
}
const mapStoreToProps = state => ({
  
})
const mapDispatchToProps = {
 
}
export default connect(mapStoreToProps, mapDispatchToProps)(App);
