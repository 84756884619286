import serverCall from '../../modules/serverCall'

export const addWishList=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_WISHLIST_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/buyer/add-wish-list`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_WISHLIST_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_WISHLIST_FAIL,
      payload: {error}
    })
    return error
  })
}

export const addShoppingCart=(requestdata)=>dispatch=>{
   dispatch({
    type:GET_WISHLIST_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/buyer/add-shoping-cart`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_WISHLIST_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_WISHLIST_FAIL,
      payload: {error}
    })
    return error
  })
}

export const GET_SHOP_BEGIN = 'GET_SHOP_BEGIN'
export const GET_SHOP_SUCCESS='GET_SHOP_SUCCESS'
export const GET_SHOP_FAIL='GET_SHOP_FAIL'

export const GET_WISHLIST_BEGIN = 'GET_WISHLIST_BEGIN'
export const GET_WISHLIST_SUCCESS='GET_WISHLIST_SUCCESS'
export const GET_WISHLIST_FAIL='GET_WISHLIST_FAIL'