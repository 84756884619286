
let history
export const registerNav = (ref) => {
  history = ref.history
}

const jumpTo = (uri) => {
  history.push(uri)
}
export const go=(uri)=>{
  history.go(uri)
}

export const goData=(uri,data)=>{
  history.go(uri,data)
}
export const jumpToData=(uri,data)=>{
  history.push(uri,data)
}
export default jumpTo
