import React from 'react'
import jumpTo from '../../../modules/Navigation'
import SimpleImageSlider from "react-simple-image-slider";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function ProductSlider({productImage,productUrl,productId}) {
  if(productImage !== '' && productImage !== null && productImage !== undefined){
    const imageData = [];
    let imagesNav ='';
    let navSize=  50;
    let navMargin= 30;
    let navStyle = 1;
    for(var i = 0; i < productImage.length; i++) {
      var objimage = {};

      objimage['url'] = productUrl + productImage[i].UserImageName;
      objimage['key'] = i;
      imageData.push(objimage);
    }
 
    if(imageData.length >1){
      imagesNav = true;
      navSize=  50;
      navMargin= 30;
      navStyle = 1;
    }else{
      imagesNav = false;
      navSize=  50;
      navMargin= 30;
      navStyle = 1;
    }

    return(
      <span>
        <AwesomeSlider animation="cubeAnimation">
      {imageData.length > 0 ? 
        imageData.map(imageMain=> 
            <div key={imageMain.key}>
              <a onClick={() => jumpTo('/product-detail/'+productId)}>
                <img src={imageMain.url} />
              </a>
            </div>
          )
        : ""}
        </AwesomeSlider>
      </span>
    )
  }
}



