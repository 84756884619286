import React, { Component } from 'react'
import FooterContainer from '../../components/footer/footerContainer'
import HeaderContainer from '../../components/header/headerContainer'
import MainMenuContainer from '../../components/mainMenu/MainMenuContainer'
import ProductDetailsSlider from './components/ProductDetailsSlider';
import './stylesheets/productDetail.css'
import serverUrl from '../../configs/constant'
import {isMobile} from 'react-device-detect';
import jumpTo,{jumpToData} from '../../modules/Navigation'
import Modal from "react-bootstrap/Modal";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Translator, Translate} from 'react-auto-translate';


export default class productDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countProduct:1,
      addcart:'Add To Cart',
      addcarttext:'fas fa-shopping-cart',
      addwish:'Wishlist',
      addwishtext:'far fa-heart',
      devicetype:localStorage.getItem('devicetype'),
      deviceos:localStorage.getItem('deviceos'),
      devicebrowser:localStorage.getItem('devicebrowser'),
      latitude: localStorage.getItem('latitude'),
      longitude:localStorage.getItem('longitude'),
      userdevice:localStorage.getItem('userdevice'),
      selectedLanguage:localStorage.getItem('selectedLanguage'),
      preselectedLanguage:localStorage.getItem('preselectedLanguage'),
      showMore:false,
      showMoreTerm:false,
    };
    let requestdata = this.props.location.pathname;
    let requestdataMain = requestdata.split("/");
    let requestdatapost = {};
    requestdatapost.userdata= localStorage.getItem('userData');
    
    this.props.getProductDetail(requestdataMain[2],requestdatapost);
    
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange = (event) => {
    this.setState({ countProduct: event.target.value });
  }

  handleClickPlus = (event) => {
    let productCount = this.state.countProduct + 1; 
    this.setState({ countProduct: productCount });
  }

  handleClickMinus = (event) => {
    if(this.state.countProduct > 1 ){
        let productCount = this.state.countProduct -1; 
        this.setState({ countProduct: productCount });
    }
  }


  handlegoBack = (data) => {
    localStorage.setItem("BackVar",1);
    window.history.back();
  }

  handlePageClick = (data) => {
    this.props.getProductDetail(data);
  }

  handleClickCart= (event) => {
    const MySwal = withReactContent(Swal);
    let requestdata= {};
    requestdata.gbxproductid = event;
    requestdata.userdata = localStorage.getItem('userData');
    requestdata.devicetype= this.state.devicetype;
    requestdata.deviceos= this.state.deviceos;
    requestdata.devicebrowser= this.state.devicebrowser;
    requestdata.latitude= this.state.latitude;
    requestdata.longitude= this.state.longitude;
    requestdata.userdevice= this.state.userdevice;
    fetch(serverUrl.serverUrl+'/api/buyer/add-shoping-cart', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestdata),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === '1'){
        this.setState({ addcart: 'Added',addcarttext: 'fas fa-check'});
      }else if(data.status === '0'){
          MySwal.fire('<p>'+data.msg+'</p>')
      }
      // console.log('Success:', data);
    })
    .catch((error) => {
      // console.error('Error:', error);
    });
  }



handleClickWish= (event) => {
    const MySwal = withReactContent(Swal);
    let requestdata= {};
    requestdata.gbxproductid = event;
    requestdata.userdata = localStorage.getItem('userData');
    requestdata.devicetype= this.state.devicetype;
    requestdata.deviceos= this.state.deviceos;
    requestdata.devicebrowser= this.state.devicebrowser;
    requestdata.latitude= this.state.latitude;
    requestdata.longitude= this.state.longitude;
    requestdata.userdevice= this.state.userdevice;
    fetch(serverUrl.serverUrl+'/api/buyer/add-wish-list', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestdata),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === '1'){
        this.setState({ addwish:'Wishlisted',addwishtext: 'fas fa-check'});
      }else if(data.status === '0'){
          MySwal.fire('<p>'+data.msg+'</p>')
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

setShowMore= (event) => {
  this.setState({ showMore:event});
}

setShowMoreTerm= (event) => {
  this.setState({ showMoreTerm:event});
}
  render() {
    const productDetailData = this.props.productData;
    const loaderImg = require('../../assets/images/loader_img.gif');
    const chartlogo = require('../../assets/images/chart.png');
    
    const ratingData = [];
    const reletedProduct = [];
    const vendorOtherProductData = [];
    const productCommentData = [];
    const countryAvailability = [];
    var productColor = [];
    var productSize = [];
    var productType = [];

    // let productDetail = '';
    
    if(productDetailData !== null && productDetailData !== undefined && productDetailData !== ''){
      // console.log('productDetailData',productDetailData.status);
      // if(productDetailData.status === "0"){
      //   window.location.href= "/";
      //   return false;
      // }else{
        // if(productDetailData.status === 1){
          var productDetail = productDetailData.product_list;
      //   }
      // }
   


      if(productDetailData.product_list.Colour !=='' && productDetailData.product_list.Colour !== undefined && productDetailData.product_list.Colour !== null){
        for (var i = 0; i < productDetailData.product_list.Colour.length; i++) {
          var objColor = {};
          if(productDetailData.product_list.Colour[i] !== '' && productDetailData.product_list.Colour[i] !== undefined){
            objColor['name'] =  productDetailData.product_list.Colour[i];
            objColor['key'] = i;
            productColor.push(objColor);
          }
        }
      }

      if(productDetailData.product_list.Size !=='' && productDetailData.product_list.Size !== undefined && productDetailData.product_list.Size !== null){
        for (var i = 0; i < productDetailData.product_list.Size.length; i++) {
          var objSize = {};
          if(productDetailData.product_list.Size[i] !== '' && productDetailData.product_list.Size[i] !== undefined){
            objSize['name'] =  productDetailData.product_list.Size[i];
            objSize['key'] = i;
            productSize.push(objSize);
          }
        }
      }

      if(productDetailData.product_list.Type !=='' && productDetailData.product_list.Type !== undefined && productDetailData.product_list.Type !== null){
        for (var i = 0; i < productDetailData.product_list.Type.length; i++) {
          var objType = {};
          if(productDetailData.product_list.Type[i] !== '' && productDetailData.product_list.Type[i] !== undefined){
            objType['name'] =  productDetailData.product_list.Type[i];
            objType['key'] = i;
            productType.push(objType);
          }
        }
      }

      if(productDetailData.product_list.relatedProuctData.length > 0){

        for(var i = 0; i < productDetailData.product_list.relatedProuctData.length; i++){
            var reletedProductDataMain = {};
            reletedProductDataMain['UserImageName'] = productDetailData.product_list.relatedProuctData[i].UserImageName;
            reletedProductDataMain['ProductTitle'] = productDetailData.product_list.relatedProuctData[i].ProductTitle;
            reletedProductDataMain['GBXProductID'] = productDetailData.product_list.relatedProuctData[i].GBXProductID;
            reletedProductDataMain['product_id'] = productDetailData.product_list.relatedProuctData[i].product_id;
            reletedProduct.push(reletedProductDataMain);
        }
      }
      if(productDetailData.product_list.vendorOtherProductData){

        for(var i = 0; i < productDetailData.product_list.vendorOtherProductData.length; i++){
            var vendorOtherProductDataMain = {};
            vendorOtherProductDataMain['UserImageName'] = productDetailData.product_list.vendorOtherProductData[i].UserImageName;
            vendorOtherProductDataMain['ProductTitle'] = productDetailData.product_list.vendorOtherProductData[i].ProductTitle;
            vendorOtherProductDataMain['GBXProductID'] = productDetailData.product_list.vendorOtherProductData[i].GBXProductID;
            vendorOtherProductDataMain['product_id'] = productDetailData.product_list.vendorOtherProductData[i].product_id;
            vendorOtherProductData.push(vendorOtherProductDataMain);
        }
      }

      if(productDetailData.product_list.CountryAvailability.length > 0){
          var CountryAvailabilityData = {};
          for(var i = 0; i < productDetailData.product_list.CountryAvailability.length; i++){
            var countryAvailabilityData = {};
            countryAvailabilityData['name'] = productDetailData.product_list.CountryAvailability[i].CountryName;
            countryAvailability.push(countryAvailabilityData);
        }
      }
      
      if(productDetailData.product_list.productComment !==''){
        for(var i = 0; i < productDetailData.product_list.productComment.length; i++){
            var productCommentDataMain = {};
            productCommentDataMain['value'] = productDetailData.product_list.productComment[i];
            productCommentData.push(productCommentDataMain);
        }
      }
    }

    return (
      <span>
      <HeaderContainer />

      { productDetailData !== null && productDetailData !== undefined && productDetailData !== '' ?
        <Translator 
            from={this.state.preselectedLanguage}
            to={this.state.selectedLanguage}
            googleApiKey={serverUrl.googleApiTranslation}
          >
          <div className=" ProductContent" id="gbxMain">
            {isMobile === true ? 
              <div className="tax_class">
              </div>
                 :""}
            <span className="Header_add">
              <MainMenuContainer/>
            </span>
            {isMobile === false ?
            <h2 className="tax_classNew"><Translate>{serverUrl.appname} does not recommend contacting vendors outside this platform. all risks are assumed by the buyer for direct purchases from third party vendors</Translate></h2>
            :""}          
            <div className="container">
              <ol className="breadcrumb padd_15">
                <li><a className="goBackBtn" onClick={this.handlegoBack}> <i className="fas fa-angle-left goBackBtn"></i><Translate> Go back</Translate></a></li>
                </ol>
                  <ol className="breadcrumb">
                  {productDetailData.product_list.Category.firstCategoryId !=='' ?
                  <li><a className="catText" onClick={() => jumpToData('/advance-search/',{category:productDetailData.product_list.Category.firstCategoryId,name:'category',code:productDetailData.product_list.Category.firstCategoryId})}><Translate>{productDetailData.product_list.Category.firstCategoryName} </Translate><span className="noti_spn transition drop_value blue_bg">{productDetailData.product_list.Category.firstCategoryCount}</span> > </a></li>
                  :""}
                  {productDetailData.product_list.Category.secondCategoryId !=='' ?
                  <li><a className="subCatText" onClick={() => jumpToData('/advance-search/',{category:productDetailData.product_list.Category.firstCategoryId,name:'category',subcategoryone:productDetailData.product_list.Category.secondCategoryId,code:productDetailData.product_list.Category.firstCategoryId})}><Translate>{productDetailData.product_list.Category.secondCategoryName} </Translate><span className="noti_spn transition drop_value blue_bg">{productDetailData.product_list.Category.secondCategoryCount}</span> > </a></li>
                  :""}
                  {productDetailData.product_list.Category.thirdCategoryId !=='' ?
                  <li><a className="subCatText" onClick={() => jumpToData('/advance-search/',{category:productDetailData.product_list.Category.firstCategoryId,name:'category',subcategoryone:productDetailData.product_list.Category.secondCategoryId,subcategorytwo:productDetailData.product_list.Category.thirdCategoryId,code:productDetailData.product_list.Category.firstCategoryId})}><Translate>{productDetailData.product_list.Category.thirdCategoryName}</Translate><span className="noti_spn transition drop_value blue_bg">{productDetailData.product_list.Category.thirdCategoryCount}</span> > </a></li>
                  :""}
                  {productDetailData.product_list.Category.fourthCategoryId !=='' ?
                  <li><a className="subCatText" onClick={() => jumpToData('/advance-search/',{category:productDetailData.product_list.Category.firstCategoryId,name:'category',subcategoryone:productDetailData.product_list.Category.secondCategoryId,subcategorytwo:productDetailData.product_list.Category.thirdCategoryId,subcategorythree:productDetailData.product_list.Category.fourthCategoryId,code:productDetailData.product_list.Category.firstCategoryId})}><Translate>{productDetailData.product_list.Category.fourthCategoryName}</Translate><span className="noti_spn transition drop_value blue_bg">{productDetailData.product_list.Category.fourthCategoryCount}</span> > </a></li>
                  :""}
                </ol>
               
              <div className="pro_detail">
                <div className="row margin_10">
                  <div className="col-sm-7 col-md-8 ">
                    <div className="pro_imgbox">
                    <div className="info_box1 padd_15">
                        <h3 className="sidebar_head blue_txt uprcase semi ltr_spacing  tooltip_box">
                          <div className="no_wrap" onClick={() => jumpTo('/vendor-store/'+productDetailData.product_list.CompanyURL)}>
                                <Translate>{productDetailData.product_list.VendorInfo}</Translate>
                                <span className="tooltiptext_box tooltip-top">
                                <p><Translate>Click here to see</Translate><br/>{productDetailData.product_list.VendorProductCount}<br/><Translate>products posted by this vendor</Translate></p>
                              </span>
                          </div>
                        </h3>
                    </div>  
                      <h2 className="pro_head"><Translate>{productDetailData.product_list.ProductTitle}</Translate></h2>
                      <div className="pro_main_img">
                        <span className="cell">
                          <ProductDetailsSlider productImage={productDetailData.product_list.Images} productUrl={productDetailData.product_list.Image_path} productVideo={productDetailData.product_list.Video} productUrlVideo={productDetailData.product_list.video_path} />
                        </span>
                      </div>
                    </div>
                    <div className="pro_thumb">
                    </div>
                    {isMobile === true ?
                      <div className="pro_imgbox">
                       <div className="info_box1 padd_15">                              
                                <h5 className="lowest_price uprcase"><Translate>Lowest Available Price:</Translate></h5>
                        <table className="table_box">
                                <tbody>
                                  <tr className="first">
                                    <td><a className="blue tooltip_box" onClick={() => jumpToData('/product-result/'+productDetailData.product_list.CountryVendorName,{countryname:productDetailData.product_list.Country,name:productDetailData.product_list.CountryVendorName,code:productDetailData.product_list.Country})}>
                                      <img src={productDetailData.product_list.Icon_path+productDetailData.product_list.VandorCountry} title=""/>
                                      <span className="tooltiptext_box tooltip-top">
                                      <p><Translate>Click here to see</Translate><br/>{productDetailData.product_list.CountryProductCount}<br/><Translate>products supplied form</Translate><br/><Translate>{productDetailData.product_list.VendorCountryName}</Translate></p>
                                      </span>
                                      </a>
                                    </td>
                                    <td><a href="#" className="Red_box">
                                      <span className="red">{productDetailData.product_list.VandorCur+" "+productDetailData.product_list.VandorPrice}</span>
                                      </a>
                                    </td>
                                  </tr>
                                  <tr className="first">
                                    <td><a className="blue tooltip_box" onClick={() => jumpToData('/product-result/United States',{countryname:'US',name:'United States'.CountryVendorName,code:'US'})}>
                                      <img src={productDetailData.product_list.Icon_path +productDetailData.product_list.USFlag} title=""/>
                                      <span className="tooltiptext_box tooltip-top">
                                        <p><Translate>Click here to see</Translate><br/>{productDetailData.product_list.USProductCount}<br/><Translate>products supplied form</Translate><br/><Translate>{productDetailData.product_list.USCountryName}</Translate></p>
                                      </span>
                                    </a></td>
                                    <td>
                                      <a href="#" className="Red_box">
                                      <span className="black">{productDetailData.product_list.USCurrency+" "+productDetailData.product_list.USPrice}</span>
                                      </a>
                                    </td>
                                  </tr>
                                  <tr className="first">
                                    <td><a className="blue tooltip_box" onClick={() => jumpToData('/product-result/'+productDetailData.product_list.UserCountryName,{countryname:productDetailData.product_list.UserCountryCode,name:productDetailData.product_list.UserCountryName,code:productDetailData.product_list.UserCountryCode})}>
                                      <img src={productDetailData.product_list.Icon_path+productDetailData.product_list.UserCountry} title=""/>
                                      <span className="tooltiptext_box tooltip-top">
                                        <p><Translate>Click here to see</Translate><br/>{productDetailData.product_list.UserCountryCount}<br/><Translate>products supplied form</Translate><br/><Translate>{productDetailData.product_list.UserCountryName}</Translate></p>
                                      </span>
                                    </a></td>
                                    <td>
                                      <a href="#" className="Red_box">
                                      <span className="blue">{productDetailData.product_list.Buyer_currency +" "+productDetailData.product_list.UserPriceMain}</span>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                                    <a href="javascript:void(0)" className="btn_dflt blk_btn mrgn_t5 grey_btn tooltip_box"><Translate>Volume Discount</Translate>
                                  {productDetailData.product_list.HasDiscount === 1 ?
                                  <span className="tooltiptext_box tooltip-top" >
                                        <p><Translate>Availabe Price by Volume for this Product</Translate></p>
                                        <table className="tooltip_table">
                                          <tbody><tr>
                                            <td><Translate>Up to</Translate></td>
                                            <td><Translate>From</Translate></td>
                                            <td><Translate>to</Translate></td>
                                            <td><Translate>More than</Translate></td>
                                          </tr>
                                          <tr>
                                            <td>{productDetailData.product_list.UpToQuantity1 === 0 ? 1 : productDetailData.product_list.UpToQuantity1}</td>
                                            <td>{productDetailData.product_list.UpToQuantity1 == 0 ? productDetailData.product_list.HasMinimumQuantity : productDetailData.product_list.UpToQuantity1 + 1}</td>
                                            <td>{productDetailData.product_list.UpToQuantity2}</td>
                                            <td>{productDetailData.product_list.MoreThanQuantity2}</td>
                                          </tr>
                                          <tr>
                                            <td>{productDetailData.product_list.UserPrice !== null ? "N/A" : productDetailData.product_list.UserPrice}</td>
                                            <td colspan="2">{productDetailData.product_list.UserPrice_one}</td>
                                            <td>{productDetailData.product_list.UserPrice_sec}</td>
                                          </tr>
                                        </tbody></table>
                                        <div className="Price_txt text-left">
                                          <p><Translate>Above Prices are in</Translate> {productDetailData.product_list.Buyer_currency}<img src={productDetailData.product_list.Icon_path+productDetailData.product_list.UserCountry} title=""/></p>
                                        </div>
                                      </span>
                                      :
                                      <span className="tooltiptext_box tooltip-top">
                                          <p><Translate>No Volume Discount Available for this Product</Translate></p>
                                      </span>
                                  }

                                </a>
                       
                              <div className="info_box1 info_box2 padd_15">
                                  <h3 className="des_head"><Translate>Purchase Quantity:</Translate></h3>
                                  <div className="mrgn_t10">        
                                    <div className="input_group d-flex">
                                        <div className="input-group-addon blue_btn" onClick={this.handleClickMinus}><i className="fas fa-minus"></i></div>
                                          <input type="number" className="form-control text-center" onChange ={this.handleChange} value={this.state.countProduct} min={this.state.countProduct}/>
                                        <div className="input-group-addon blue_btn" onClick={this.handleClickPlus}><i className="fas fa-plus"></i></div>
                                    </div>
                                    {productDetailData.product_list.QTYAvailable === true && productDetailData.product_list.CountryAva === true ?
                                      <span>
                                        {productDetailData.product_list.addedshop === true ?
                                          <a className="btn_dflt darkcyan blk_btn dark_ntn tooltip_box"><i className="fas fa-check"></i>&nbsp;Added<span className="tooltiptext_box">
                                          <p><Translate>{productDetailData.product_list.UserType !=='' && productDetailData.product_list.UserType === 'Buyer' ? "This Item is in your shopping cart":"Please login as Buyer in order to add this item to your shopping cart"}</Translate></p>
                                          </span></a>
                                        :
                                          <a className="btn_dflt darkcyan blk_btn dark_ntn tooltip_box" onClick={() => this.handleClickCart(productDetailData.product_list.GBXProductID)}><i className={this.state.addcarttext}></i>&nbsp;{this.state.addcart}<span className="tooltiptext_box">
                                          <p><Translate>{productDetailData.product_list.UserType !=='' && productDetailData.product_list.UserType === 'Buyer' ? "Click here in order to add this item to your shopping cart":"Please login as Buyer in order to add this item to your shopping cart"}</Translate></p></span></a>
                                        }
                                        {productDetailData.product_list.addedwish === true ?
                                          <a className="btn_dflt blue_btn blk_btn mrgn_t5 dark_ntn tooltip_box"><i className="far fa-check"></i>&nbsp;Wishlisted<span className="tooltiptext_box">
                                          <p><Translate>{productDetailData.product_list.UserType !=='' && productDetailData.product_list.UserType === 'Buyer' ? "This Item is in your wishlist":"Please login as Buyer in order to add this item to your wishlist"}</Translate></p></span></a>
                                        :
                                          <a className="btn_dflt blue_btn blk_btn mrgn_t5 dark_ntn tooltip_box" onClick={() => this.handleClickWish(productDetailData.product_list.GBXProductID)}><i className={this.state.addwishtext}></i>&nbsp;{this.state.addwish}
                                          <span className="tooltiptext_box">
                                          <p><Translate>{productDetailData.product_list.UserType !=='' && productDetailData.product_list.UserType === 'Buyer' ? "Click here in order to add this item to your wishlist":"Please login as Buyer in order to add this item to your wishlist"}</Translate>
                                          </p>
                                          </span>
                                          </a>
                                        }
                                    </span>
                                    :""}
                                  </div>
                              </div>
                          </div>
                         </div>
                       :""}
                    <div className="pro_detail_sec wht_box">
                      <div className="pro_des">
                        <h3 className="des_head"><Translate>Product Description</Translate></h3>
                        <p className="des_txt">
                        <Translate>{this.state.showMore ? productDetailData.product_list.ProdDescription : productDetailData.product_list.ProdDescription.substring(0,300)}</Translate>
                        <button className="btn ReadMoreLessBtn" onClick={() => this.setShowMore(!this.state.showMore)}>{this.state.showMore ? 'READ LESS' : "READ MORE"}</button>
                       
                        </p>
                      </div>
                      <div className="pro_des">
                        <h3  className="des_head"><Translate>Terms and Conditions</Translate></h3>
                        <p className="des_txt">
                        <Translate>{this.state.showMoreTerm ? productDetailData.product_list.TermsConditions : productDetailData.product_list.TermsConditions.substring(0,300)}</Translate>
                        <button className="btn ReadMoreLessBtn" onClick={() => this.setShowMoreTerm(!this.state.showMoreTerm)}>{this.state.showMoreTerm ? 'READ LESS' : "READ MORE"}</button>
                        </p>
                      </div>

                      <div className="modal fade" id="exampleModalCountry" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel"><Translate>PRODUCTS and VENDORS in </Translate><b className="upperClass"><Translate>{productDetailData.product_list.CountryVendorName}</Translate></b></h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-sm-12 col-xs-12 Grid_column">
                                <p>View<br/>{productDetailData.product_list.CountryProductCount}<br/><Translate>PRODUCTS LOCATED in </Translate><br/><b className="upperClass"><Translate>{productDetailData.product_list.CountryVendorName}</Translate></b></p>
                                <button type="button" data-dismiss="modal" onClick={() => jumpToData('/product-result/'+productDetailData.product_list.CountryVendorName,{countryname:productDetailData.product_list.Country,name:productDetailData.product_list.CountryVendorName,code:productDetailData.product_list.Country})} className="btn btn-success"><Translate>Continue</Translate></button>
                              </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-xs-12 Grid_column">
                                <p>View<br/>{productDetailData.product_list.CountryVendorCount}<br/><Translate>VENDORS LOCATED in </Translate><br/><b className="upperClass"><Translate>{productDetailData.product_list.CountryVendorName}</Translate></b></p>
                               
                                <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => jumpToData('/vendor-result/'+productDetailData.product_list.CountryVendorName,{countryname:productDetailData.product_list.Country,name:productDetailData.product_list.CountryVendorName,code:productDetailData.product_list.Country})}><Translate>Continue</Translate></button>
                              </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="modal fade" id="exampleModal" role="dialog">
                       
                         <div className="modal-dialog" role="document" >
                           <div className="modal-content">
                             <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel"><Translate>PRODUCTS and VENDORS in </Translate><b className="upperClass"><Translate>{productDetailData.product_list.VandorCity}</Translate></b></h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                             </div>
                            <div className="modal-body">
                              <div className="row">
                                
                                <div className="col-sm-12 col-xs-12 Grid_column">
                                    <p>View<br/>{productDetailData.product_list.CityProductCount}<br/><Translate>PRODUCTS LOCATED in </Translate><br/><b className="upperClass"><Translate>{productDetailData.product_list.VandorCity}</Translate></b></p>
                                    <button type="button" data-dismiss="modal" onClick={() => jumpToData('/product-result/'+productDetailData.product_list.VandorCity,{cityname:productDetailData.product_list.VandorCity,name:productDetailData.product_list.VandorCity,code:productDetailData.product_list.Country})} className="btn btn-success"><Translate>Continue</Translate></button>
                                </div>
                              </div>
                              <div className="row">
                                
                                <div className="col-sm-12 col-xs-12 Grid_column">
                                    <p>View<br/>{productDetailData.product_list.CityVendorCount}<br/><Translate> VENDORS LOCATED in </Translate><br/><b className="upperClass"><Translate>{productDetailData.product_list.VandorCity}</Translate></b></p>
                                    <button type="button" data-dismiss="modal" onClick={() => jumpToData('/vendor-result/'+productDetailData.product_list.VandorCity,{cityname:productDetailData.product_list.VandorCity,name:productDetailData.product_list.VandorCity,code:productDetailData.product_list.Country})} className="btn btn-success"><Translate>Continue</Translate></button>
                                </div>
                              </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                      <div className="modal fade" id="exampleModalState" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel"><Translate>PRODUCTS and VENDORS in</Translate><b className="upperClass"><Translate>{productDetailData.product_list.VandorProvinceState}</Translate></b></h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-sm-4 col-xs-4 Grid_column">
                                </div>
                                <div className="col-sm-4 col-xs-4 Grid_column">
                                <p>View<br/>{productDetailData.product_list.ProvinceStateCodeProductCount}<br/><Translate>PRODUCTS LOCATED in</Translate><br/><b className="upperClass"><Translate>{productDetailData.product_list.VandorProvinceState}</Translate></b></p>
                                <button type="button" data-dismiss="modal" onClick={() => jumpToData('/product-result/'+productDetailData.product_list.VandorProvinceState,{statename:productDetailData.product_list.VandorProvinceState,name:productDetailData.product_list.VandorProvinceState,code:productDetailData.product_list.Country})} className="btn btn-success"><Translate>Continue</Translate></button>
                              </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-4 col-xs-4 Grid_column">
                                </div>
                                <div className="col-sm-4 col-xs-4 Grid_column">
                                <p>View<br/>{productDetailData.product_list.ProvinceStateVendorCount}<br/><Translate>VENDORS LOCATED in</Translate><br/><b className="upperClass"><Translate>{productDetailData.product_list.VandorProvinceState}</Translate></b></p>
                                <button type="button" data-dismiss="modal" onClick={() => jumpToData('/vendor-result/'+productDetailData.product_list.VandorProvinceState,{statename:productDetailData.product_list.VandorProvinceState,name:productDetailData.product_list.VandorProvinceState,code:productDetailData.product_list.Country})} className="btn btn-success"><Translate>Continue</Translate></button>
                              </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal fade" id="exampleModalPostal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel"><Translate>PRODUCTS and VENDORS in </Translate><b className="upperClass">{productDetailData.product_list.VandorPostalCode}</b></h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-sm-4 col-xs-4 Grid_column">
                                </div>
                                <div className="col-sm-4 col-xs-4 Grid_column">
                                <p>View<br/>{productDetailData.product_list.PostalCodeProductCount}<br/><Translate>PRODUCTS LOCATED in </Translate><br/><b className="upperClass">{productDetailData.product_list.VandorPostalCode}</b></p>
                                <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => jumpToData('/product-result/'+productDetailData.product_list.VandorPostalCode,{postalname:productDetailData.product_list.VandorPostalCode,name:productDetailData.product_list.VandorPostalCode,code:productDetailData.product_list.Country})}><Translate>Continue</Translate></button>
                              </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-4 col-xs-4 Grid_column">
                                </div>
                                <div className="col-sm-4 col-xs-4 Grid_column">
                                <p>View<br/>{productDetailData.product_list.PostalCodeVendorCount}<br/><Translate>VENDORS LOCATED in </Translate><br/><b className="upperClass">{productDetailData.product_list.VandorPostalCode}</b></p>
                                <button type="button" data-dismiss="modal" onClick={() => jumpToData('/vendor-result/'+productDetailData.product_list.VandorPostalCode,{postalname:productDetailData.product_list.VandorPostalCode,name:productDetailData.product_list.VandorPostalCode,code:productDetailData.product_list.Country})} className="btn btn-success"><Translate>Continue</Translate></button>
                              </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                            </div>
                          </div>
                        </div>
                      </div>
            
                      <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                          <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="headingOne">
                              <h4 className="panel-title">
                                <a className="" role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" ng-click="pdc.CheckClassData($event)" aria-controls="collapseOne">
                                  <Translate>Additional Product Info</Translate>
                                </a>
                              </h4>
                            </div>
                            <div id="collapseOne" className="panel-collapse" role="tabpanel" aria-labelledby="headingOne" aria-expanded="false">
                              <div className="panel-body AdditionalInfobody">
                                  {productDetailData.product_list.AdditionalProductInfo !== '' ? 
                                    <span className="newHtml" dangerouslySetInnerHTML={{__html: productDetailData.product_list.AdditionalProductInfo}}></span>
                                    :
                                    <span><Translate>No Information Available</Translate></span>
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div className="panel-heading" role="tab" id="headingTwo">
                                <h4 className="panel-title">
                                  <a className="" role="button" data-toggle="collapse" data-parent="#accordion"  aria-expanded="false" ng-click="pdc.CheckClassData($event)" aria-controls="collapseTwo">
                                  <Translate>Product Reviews</Translate>
                                  </a>
                                </h4>
                              </div>
                              <div id="collapseTwo" className="panel-collapse" role="tabpanel" aria-labelledby="headingTwo">
                                <div className="panel-body">
                                    <div className="reviews_sec">
                                      <div className="review_head">
                                        <div className="row">
                                          <div className="col-sm-7">
                                            <h4 className="pnlbody_head"> <span className="ltr_spacing"> <Translate>Product Rating: </Translate></span>
                                              <span className="orng_txt">
                                                {productDetailData.product_list.ProductRatingArr !=='' ? 
                                                productDetailData.product_list.ProductRatingArr.map((index) => <i key={index} className="far fa-star"></i>)
                                                  :
                                                    <i className="far fa-star grey"></i>
                                                }
                                                {productDetailData.product_list.TotalNumberRatings} Reviews </span>
                                             </h4>
                                          </div>
                                          <div className="col-sm-5 textRight">
                                            <a title="Click Here to Report Product" className="blue_txt uprcase semi"><Translate>Report Product</Translate></a>
                                           
                                          </div>
                                        </div>
                                      </div>
                                      {productDetailData.product_list.productComments === undefined ?
                                        <div align="center">
                                           <h4><b><Translate>no reviews available</Translate></b></h4> 
                                        </div>
                                        :""}
                                        {productCommentData === '' ?
                                      <div className="review_row1">
                                        <h4 className="pnlbody_head"> <span className="orng_txt">
                                                <span>
                                                  <i className="far fa-thumbs-up"></i>
                                                </span>
                                                <span>
                                                    <i className="far fa-thumbs-down"></i>
                                                </span> 
                                            </span>
                                          <span className="heading_txt" align="center">  {productDetailData.product_list.FirstName} </span>&nbsp;&nbsp;<span className="grey text_grey"> { productDetailData.product_list.created_at } </span>

                                         </h4>
                                         <p className="review_txt">
                                           {productDetailData.product_list.ProductRatingComment} ...
                                         </p>
                                      </div>
                                      :""}
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div className="panel-heading" role="tab" id="headingThree">
                                <h4 className="panel-title">
                                  <a className="" role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="collapseThree"><Translate>Vendor Details</Translate></a>
                                </h4>
                              </div>
                              <div id="collapseThree" className="panel-collapse" role="tabpanel" aria-labelledby="headingThree">
                                <div className="panel-body">
                                  <div className="row">
                                    <div className="col-sm-7">
                                      <ul className="list-unstyled">
                                        <li><Translate>Vendor: </Translate><span>&nbsp;<a className="sidebar_head blue_txt uprcase semi ltr_spacing  tooltip_box" onClick={() => jumpTo('/vendor-store/'+productDetailData.product_list.CompanyURL)}>
                                          <span className="Direct_txt"><Translate>{productDetailData.product_list.VendorInfo}</Translate></span>
                                            <span className="tooltiptext_box tooltip-bottom">
                                              <p><Translate>Click here to see</Translate><br/>{productDetailData.product_list.VendorProductCount}<br/><Translate>products posted by this vendor</Translate></p>
                                            </span>
                                            </a> 
                                        </span> 
                                      </li>
                                      <li><Translate>City:  </Translate><a  className="sidebar_head blue_txt uprcase semi ltr_spacing  tooltip_box" data-toggle="modal" data-target="#exampleModal"><span className="Direct_txt">&nbsp;{productDetailData.product_list.VandorCity}</span><span className="tooltiptext_box tooltip-bottom">
                                          <p><Translate>Click here to see</Translate><br/><Translate>PRODUCTS and VENDORS</Translate><br/> <Translate>in {productDetailData.product_list.VandorCity}</Translate></p>
                                        </span></a> 
                                      </li>



                                        <li><Translate>Province/State: </Translate><span><a data-toggle="modal" data-target="#exampleModalState" className="sidebar_head blue_txt uprcase semi ltr_spacing  tooltip_box"><span className="Direct_txt">&nbsp;{productDetailData.product_list.VandorProvinceState}</span><span className="tooltiptext_box tooltip-top">
                                          <p><Translate>Click here to see</Translate><br/><Translate>PRODUCTS and VENDORS</Translate><br/> <Translate>in {productDetailData.product_list.VandorProvinceState}</Translate></p>
                                          </span></a></span> 
                                        </li>



                                        <li><Translate>Country: </Translate><span><a data-toggle="modal" data-target="#exampleModalCountry" className="sidebar_head blue_txt uprcase semi ltr_spacing  tooltip_box"><span className="Direct_txt">&nbsp;{productDetailData.product_list.VendorCountryName}</span><span className="tooltiptext_box tooltip-top">
                                          <p><Translate>Click here to see</Translate><br/><Translate>PRODUCTS and VENDORS</Translate><br/> <Translate>in {productDetailData.product_list.VendorCountryName}</Translate></p>
                                          </span></a></span> 
                                        </li>
                                       
                                        <li><Translate>Postal Code:</Translate> <span><a data-toggle="modal" data-target="#exampleModalPostal" className="sidebar_head blue_txt uprcase semi ltr_spacing  tooltip_box">   <span className="Direct_txt">{productDetailData.product_list.VandorPostalCode}</span><span className="tooltiptext_box tooltip-top">
                                            <p><Translate>Click here to see</Translate><br/><Translate>PRODUCTS and VENDORS</Translate><br/><Translate> in </Translate>{productDetailData.product_list.VandorPostalCode}</p>
                                          </span></a></span> 
                                        </li>

                                        

                                        <li><Translate>Member Since: </Translate> <span>&nbsp;{productDetailData.product_list.VandorDate} </span> </li>
                                        <li><Translate>Total amount transacted by vendor:  </Translate><span>&nbsp;USD $2,536 </span> </li>
                                        <li className="medium"><Translate>VENDOR RATING:</Translate>
                                          &nbsp; 
                                          <span className="rating">
                                            <i className="far fa-star grey"></i>
                                            <i className="far fa-star grey"></i>
                                            <i className="far fa-star grey"></i>
                                            <i className="far fa-star grey"></i>
                                            <i className="far fa-star grey"></i>
                                          </span> 
                                        </li>
                                        
                                      </ul>
                                      <ul className="list-unstyled">
                                      <li className="contectClass">
                                            {productDetailData.product_list.UserType !=='' && productDetailData.product_list.UserType === 'Buyer' ?
                                            <a className="blue_txt semi uprcase"><Translate>Contact Vendor </Translate></a>
                                            :
                                            <a className="blue_txt semi uprcase tooltip_box"><Translate>Contact Vendor</Translate><span className="tooltiptext_box tooltip-top"><p><Translate>Please log in as buyer to contact this vendor</Translate></p></span></a>
                                            }
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-sm-5 textRight">
                                     <div className="Chart_box text-center">           
                                          <h2><Translate>Vendor Main Distribution Markets</Translate></h2>
                                          <a href="#"><img src={chartlogo} /></a>
                                        </div>
                                    </div>
                                    <div className="row">
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="panel panel-default">
                                <div className="panel-heading" role="tab" id="headingThree">
                                  <h4 className="panel-title">
                                    <a className="" role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="collapseFour">
                                      <Translate>Vendor Reviews</Translate>
                                    </a>
                                  </h4>
                                </div>
                                <div id="collapseFour" className="panel-collapse" role="tabpanel" aria-labelledby="collapseFour">
                                  <div className="panel-body">
                                    <div className="reviews_sec">
                                        <div className="review_head">
                                          <div className="row">
                                            <div className="col-sm-7">
                                              <h4 className="pnlbody_head"> <span className="ltr_spacing"><Translate>VENDOR RATING:</Translate></span>
                                                <span className="orng_txt">
                                                      <i className="far fa-star grey"></i>
                                                      <i className="far fa-star grey"></i>
                                                      <i className="far fa-star grey"></i>
                                                      <i className="far fa-star grey"></i>
                                                      <i className="far fa-star grey"></i>
                                                  {productDetailData.product_list.TotalNumVendorRatings} <Translate>Reviews</Translate></span>
                                               </h4>
                                            </div>
                                            <div className="col-sm-5 textRight">
                                              <a  title="Click Here to Report Product" className="uprcase semi rprtText"><Translate>Report Product</Translate></a>
                                            </div>
                                          </div>
                                        </div>
                                        {productDetailData.product_list.vendorComments === '' ?
                                        <div align="center">
                                           <h4><b><Translate>no reviews available</Translate></b></h4> 
                                        </div>
                                        :""}
                                        {productDetailData.product_list.vendorComments !== undefined ?
                                        <div className="review_row1" ng-if="pdc.productDetails.VendorComments != ''" ng-repeat="VendorRatingData in pdc.productDetails.VendorComments ">
                                          <h4 className="pnlbody_head"> <span className="orng_txt">
                                              <span ng-if="VendorRatingData.ThumbsUp == 1">
                                                  <i className="far fa-thumbs-up"></i>
                                                  </span> 
                                                  <span ng-if="VendorRatingData.ThumbsDown == 1">
                                                      <i class="far fa-thumbs-down"></i>
                                                  </span> 
                                              </span>
                                            <span className="heading_txt" align="center">  {productDetailData.product_list.FirstName} </span>&nbsp;&nbsp;<span class="grey text_grey"> {productDetailData.product_list.created_at } </span>

                                           </h4>
                                           <p class="review_txt">
                                             {productDetailData.product_list.VendorRatingComment} ...
                                           </p>
                                        </div>
                                        :""}
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" col-sm-5 col-md-4">
                        <div className="pro_sidebar">
                          {isMobile === false ?
                          <div className="info_box1 padd_15">
                            <h3 className="sidebar_head blue_txt uprcase semi ltr_spacing  tooltip_box">
                            <h6> VENDOR: </h6>
                              <div className="no_wrap" onClick={() => jumpTo('/vendor-store/'+productDetailData.product_list.CompanyURL)}>
                                <Translate>{productDetailData.product_list.VendorInfo.substring(0,30)}</Translate>
                              </div>
                              <span className="tooltiptext_box tooltip-top tooltipIPPVendor">
                                <p><Translate>Click here to see</Translate><br/>{productDetailData.product_list.VendorProductCount}<br/><Translate>products posted by this vendor</Translate></p>
                              </span>
                            </h3>
                            <h5 className="lowest_price uprcase"><Translate>Lowest Available Price:</Translate></h5>
                              <table className="table_box">
                                <tbody>
                                  <tr className="first">
                                    <td><a className="blue tooltip_box" onClick={() => jumpToData('/product-result/'+productDetailData.product_list.CountryVendorName,{countryname:productDetailData.product_list.Country,name:productDetailData.product_list.CountryVendorName,code:productDetailData.product_list.Country})}>
                                      <img src={productDetailData.product_list.Icon_path+productDetailData.product_list.VandorCountry} title=""/>
                                      <span className="tooltiptext_box tooltip-top">
                                      <p><Translate>Click here to see</Translate><br/>{productDetailData.product_list.CountryProductCount}<br/><Translate>products supplied form</Translate><br/><Translate>{productDetailData.product_list.VendorCountryName}</Translate></p>
                                      </span>
                                      </a>
                                    </td>
                                    <td><a href="#" className="Red_box">
                                      <span className="red">{productDetailData.product_list.VandorCur+" "+productDetailData.product_list.VandorPrice}</span>
                                      </a>
                                    </td>
                                  </tr>
                                  <tr className="first">
                                    <td><a className="blue tooltip_box" onClick={() => jumpToData('/product-result/United States',{countryname:'US',name:'United States'.CountryVendorName,code:'US'})}>
                                      <img src={productDetailData.product_list.Icon_path +productDetailData.product_list.USFlag} title=""/>
                                      <span className="tooltiptext_box tooltip-top">
                                        <p><Translate>Click here to see</Translate><br/>{productDetailData.product_list.USProductCount}<br/><Translate>products supplied form</Translate><br/><Translate>{productDetailData.product_list.USCountryName}</Translate></p>
                                      </span>
                                    </a></td>
                                    <td>
                                      <a href="#" className="Red_box">
                                      <span className="black">{productDetailData.product_list.USCurrency+" "+productDetailData.product_list.USPrice}</span>
                                      </a>
                                    </td>
                                  </tr>
                                  <tr className="first">
                                    <td><a className="blue tooltip_box" onClick={() => jumpToData('/product-result/'+productDetailData.product_list.UserCountryName,{countryname:productDetailData.product_list.UserCountryCode,name:productDetailData.product_list.UserCountryName,code:productDetailData.product_list.UserCountryCode})}>
                                      <img src={productDetailData.product_list.Icon_path+productDetailData.product_list.UserCountry} title=""/>
                                      <span className="tooltiptext_box tooltip-top">
                                        <p><Translate>Click here to see</Translate><br/>{productDetailData.product_list.UserCountryCount}<br/><Translate>products supplied form</Translate><br/><Translate>{productDetailData.product_list.UserCountryName}</Translate></p>
                                      </span>
                                    </a></td>
                                    <td>
                                      <a href="#" className="Red_box">
                                      <span className="blue">{productDetailData.product_list.Buyer_currency +" "+productDetailData.product_list.UserPriceMain}</span>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              </div>
                              :""}
                                 <div className="mrgn_t10 padd_15">
                                  {isMobile == false ?
                                  <div className="info_box1 info_box2 padd_15">
                                      <h3 className="des_head"><Translate>Purchase Quantity:</Translate></h3>
                                       <div className="mrgn_t10">        
                                        <div className="input_group d-flex">
                                            <div className="input-group-addon blue_btn" onClick={this.handleClickMinus}><i className="fas fa-minus"></i></div>
                                            <input type="number" className="form-control text-center quantityIpp" onChange ={this.handleChange} value={this.state.countProduct} min={this.state.countProduct}/>
                                            <div className="input-group-addon blue_btn" onClick={this.handleClickPlus}><i className="fas fa-plus"></i></div>
                                        </div>
                                        {productDetailData.product_list.QTYAvailable === true && productDetailData.product_list.CountryAva === true ?
                                          <span>
                                           {productDetailData.product_list.addedshop === true ?
                                          <a className="btn_dflt darkcyan blk_btn dark_ntn tooltip_box"><i className="fas fa-check"></i>&nbsp;Added<span className="tooltiptext_box">
                                          <p><Translate>{productDetailData.product_list.UserType !=='' && productDetailData.product_list.UserType === 'Buyer' ? "This Item is in your shopping cart":"Please login as Buyer in order to add this item to your shopping cart"}</Translate></p>
                                          </span></a>
                                        :
                                          <a className="btn_dflt darkcyan blk_btn dark_ntn tooltip_box" onClick={() => this.handleClickCart(productDetailData.product_list.GBXProductID)}><i className={this.state.addcarttext}></i>&nbsp;{this.state.addcart}<span className="tooltiptext_box">
                                          <p><Translate>{productDetailData.product_list.UserType !=='' && productDetailData.product_list.UserType === 'Buyer' ? "Click here in order to add this item to your shopping cart":"Please login as Buyer in order to add this item to your shopping cart"}</Translate></p></span></a>
                                        }
                                        {productDetailData.product_list.addedwish === true ?
                                          <a className="btn_dflt blue_btn blk_btn mrgn_t5 dark_ntn tooltip_box"><i className="far fa-check"></i>&nbsp;Wishlisted<span className="tooltiptext_box">
                                          <p><Translate>{productDetailData.product_list.UserType !=='' && productDetailData.product_list.UserType === 'Buyer' ? "This Item is in your wishlist":"Please login as Buyer in order to add this item to your wishlist"}</Translate></p></span></a>
                                        :
                                          <a className="btn_dflt blue_btn blk_btn mrgn_t5 dark_ntn tooltip_box" onClick={() => this.handleClickWish(productDetailData.product_list.GBXProductID)}><i className={this.state.addwishtext}></i>&nbsp;{this.state.addwish}
                                          <span className="tooltiptext_box">
                                          <p><Translate>{productDetailData.product_list.UserType !=='' && productDetailData.product_list.UserType === 'Buyer' ? "Click here in order to add this item to your wishlist":"Please login as Buyer in order to add this item to your wishlist"}</Translate>
                                          </p>
                                          </span>
                                          </a>
                                        }
                                    </span>
                                    :""}
                                  </div>
                                </div> :
                                ""}
                                  {isMobile == false ?
                                <a href="javascript:void(0)" className="btn_dflt blk_btn mrgn_t5 grey_btn tooltip_box"><Translate>Volume Discount</Translate>
                                  {productDetailData.product_list.HasDiscount === 1 ?
                                  <span className="tooltiptext_box tooltip-top" >
                                        <p><Translate>Availabe Price by Volume for this Product</Translate></p>
                                        <table className="tooltip_table">
                                          <tbody><tr>
                                            <td><Translate>Up to</Translate></td>
                                            <td><Translate>From</Translate></td>
                                            <td><Translate>to</Translate></td>
                                            <td><Translate>More than</Translate></td>
                                          </tr>
                                          <tr>
                                            <td>{productDetailData.product_list.UpToQuantity1 === 0 ? 1 : productDetailData.product_list.UpToQuantity1}</td>
                                            <td>{productDetailData.product_list.UpToQuantity1 == 0 ? productDetailData.product_list.HasMinimumQuantity : productDetailData.product_list.UpToQuantity1 + 1}</td>
                                            <td>{productDetailData.product_list.UpToQuantity2}</td>
                                            <td>{productDetailData.product_list.MoreThanQuantity2}</td>
                                          </tr>
                                          <tr>
                                            <td>{productDetailData.product_list.UserPrice !== null ? "N/A" : productDetailData.product_list.UserPrice}</td>
                                            <td colspan="2">{productDetailData.product_list.UserPrice_one}</td>
                                            <td>{productDetailData.product_list.UserPrice_sec}</td>
                                          </tr>
                                        </tbody></table>
                                        <div className="Price_txt text-left">
                                          <p><Translate>Above Prices are in </Translate>{productDetailData.product_list.Buyer_currency}<img src={productDetailData.product_list.Icon_path+productDetailData.product_list.UserCountry} title=""/></p>
                                        </div>
                                      </span>
                                      :
                                      <span className="tooltiptext_box tooltip-top">
                                        <p><Translate>No Volume Discount Available for this Product</Translate></p>
                                      </span>
                                  }
  
                                </a>
                                :""}
                                <a className="btn_dflt grey_btn blk_btn" data-toggle="modal" data-target="#myModalGeo"> Geographic Availability</a>
                                <div className="modal" id="myModalGeo">
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h4 className="modal-title"><Translate>Geographic Availability</Translate></h4>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                      </div>
                                      <div className="modal-body" style={{height: "50vh",overflowY:'auto',textAlign:'left'}}>
                                        {countryAvailability !== '' ?
                                          countryAvailability.map(({ name }, index) => 
                                            <div className="md-list-item-text" key={name} layout="column"><h3>{name} </h3></div>)
                                      :"" }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="side_form mrgn_t10 padd_15">
                                <div className="form-group">
                                  <label className="label_txt"><Translate>Select Product Type:</Translate></label>
                                  <select className="form-control">
                                  {productType.length > 0  ?
                                      productType.map(({ name,key }, index) => <option key={key} value={name} >{name}</option>)
                                    :
                                    <option value="" >N/A</option>
                                  }
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label className="label_txt"><Translate>Select Product Size:</Translate></label>
                                  <select className="form-control">
                                  {productSize.length > 0  ?
                                      productSize.map(({ name,key }, index) => <option key={key} value={name} >{name}</option>)
                                    :
                                    <option value="">N/A</option>
                                  }
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label className="label_txt"><Translate>Select Product Colour:</Translate></label>
                                  <select className="form-control">
                                  {productColor.length > 0  ?
                                    productColor.map(({ name,key }, index) => <option key={key} value={name} >{name}</option>)
                                    :
                                    <option value="" >N/A</option>
                                  }
                                  </select>
                                </div>                    
                              </div>
                                <div className="info_box1 info_box2 padd_15">
                                  <h3 className="des_head"><Translate>Product Attributes:</Translate></h3>
                                  <div className="row margin_2">
                                    <div className="col-md-3 col-sm-12 col-xs-6">
                                      <div className="custom_check">
                                        <label className={productDetailData.product_list.IsUsed === 'New' ? "check_boxbtbn active" : "check_boxbtbn"}>
                                            <Translate>New</Translate>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-12 col-xs-6" >
                                      <div className="custom_check">
                                        
                                        <label className={productDetailData.product_list.IsUsed === 'Used' ? "check_boxbtbn active" : "check_boxbtbn"}>
                                            <Translate>Used</Translate>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-xs-6">
                                      <div className="custom_check">
                                       
                                        <label className={productDetailData.product_list.IsUsed === 'Refurbished' ? "check_boxbtbn active" : "check_boxbtbn"}>
                                            <Translate>Refurbished</Translate>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-xs-6">
                                      <div className="custom_check">
                                       
                                        <label className={productDetailData.product_list.GenuineProduct === 'Genuine' ? "check_boxbtbn active" : "check_boxbtbn"}>
                                            <Translate>GENUINE</Translate>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-xs-6">
                                      <div className="custom_check">
                                        <label className={productDetailData.product_list.GenuineProduct === 'Replica' ? "check_boxbtbn active" : "check_boxbtbn"}>
                                            <Translate>Replica</Translate>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="row margin_2 info_box">
                                      <div className="col-md-6 col-sm-6 col-xs-6">
                                        <a className="info_txt"><Translate>Age Restricted:</Translate></a>
                                      </div>                    
                                      <div className="col-md-4 col-sm-4 col-xs-4">                
                                        <div className="custom_check">
                                          <label className="check_boxbtbn active">
                                          {productDetailData.product_list.AgeRestricted === 'YES' ? "yes" : "no"}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-2 col-sm-2 col-xs-2 text-center">
                                        {productDetailData.product_list.AgeRestricted === 'YES' ? <a href="#" className="info_txt semi">{productDetailData.product_list.AgeLimit} <Translate>Year</Translate></a> : <a href="#" className="info_txt"></a>}
                                      </div>
                                    </div>
                                    <div className="row margin_2 info_box info_Last_box">
                                      <div className="col-md-6 col-sm-6 col-xs-6">
                                        <a href="#" className="info_txt"><Translate>Warranty Available:</Translate></a>
                                      </div>                    
                                      <div className="col-md-4 col-sm-4 col-xs-4">                
                                        <div className="custom_check">
                                          <label className="check_boxbtbn active">
                                            <Translate>{productDetailData.product_list.HasWarranty === 'YES' ? "yes" : "no"}</Translate>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-2 col-sm-2 col-xs-2 text-center">
                                        {productDetailData.product_list.HasWarranty === 'YES' ?
                                          <a className="info_txt semi">{productDetailData.product_list.WarrantyMonths} <Translate>Months</Translate></a> : ""
                                        }
                                      </div>
                                    </div>
                                    {productDetailData.product_list.ProductCustomization === 'YES' ?
                                    <div className="row margin_2 info_box info_Last_box">
                                      <div className="col-md-6 col-sm-6 col-xs-6">
                                        <a href="#" className="info_txt"><Translate>Customization Available:</Translate></a>
                                      </div>                    
                                      <div className="col-md-4 col-sm-4 col-xs-4">                
                                        <div className="custom_check">
                                          <label className="check_boxbtbn active">
                                            <Translate>{productDetailData.product_list.ProductCustomization === 'YES' ? "yes" : "no"}</Translate>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-2 col-sm-2 col-xs-2 text-center">
                                        <a className="info_txt semi"></a>
                                      </div>
                                    </div> 
                                    :""}
                                    {productDetailData.product_list.HasMinimumQuantity === 1 ?
                                    <div className="row margin_2 info_box info_Last_box">
                                      <div className="col-md-6 col-sm-6 col-xs-6">
                                        <a href="#" className="info_txt"><Translate>Sample Available:</Translate></a>
                                      </div>                    
                                      <div className="col-md-4 col-sm-4 col-xs-4">                
                                        <div className="custom_check">
                                          <label className="check_boxbtbn active">
                                            <Translate>{productDetailData.product_list.SampleAvailable === 'YES' ? "yes" : "no"}</Translate>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-2 col-sm-2 col-xs-2 text-center">
                                        <a className="info_txt semi">{productDetailData.product_list.SamplePrice}</a>
                                      </div>
                                    </div> 
                                    : "" }  
                                    {productDetailData.product_list.LeadTimeRequired === 'YES' ?
                                    <div className="row margin_2 info_box info_Last_box" ng-if="pdc.productDetails.LeadTimeRequired == 'YES'">
                                      <div className="col-md-6 col-sm-6 col-xs-6">
                                        <a href="#" className="info_txt"><Translate>Lead Days Required:</Translate></a>
                                      </div>                    
                                      <div className="col-md-4 col-sm-4 col-xs-4">                
                                        <div className="custom_check">
                                          <label className="check_boxbtbn active">
                                            <Translate>{productDetailData.product_list.LeadTimeRequired === 'YES' ? "yes" : "no"}</Translate>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-2 col-sm-2 col-xs-2 text-center">
                                      {productDetailData.product_list.LeadTimeRequired === 'YES' ?
                                        <a className="info_txt semi">{productDetailData.product_list.LeadDays} <Translate>Days</Translate></a>
                                        : ""}
                                      </div>
                                    </div>
                                    : ""}                  
                                  </div>              
                                </div>
                                <div className="info_box1 info_box2 padd_15">
                                  <h3 className="des_head"><Translate>Product Info:</Translate></h3>                 
                                  <div className="form-group">
                                    <label className="label_txt"><Translate>GBXProductID</Translate></label>
                                    <input type="text" className="form-control" value={productDetailData.product_list.GBXProductID} readOnly="readOnly"/>
                                  </div>
                                  <div className="form-group">
                                    <label className="label_txt"><Translate>SKU Number</Translate></label>
                                    <input type="text" className="form-control" value={productDetailData.product_list.VendorSKU} readOnly="readOnly" />
                                  </div>
                                  <div className="form-group">
                                    <label className="label_txt"><Translate>OEM Number</Translate></label>
                                    <input type="text" className="form-control" value={productDetailData.product_list.OEMNumber} readOnly="readOnly" />
                                  </div>                  
                                </div>

                                <div className="info_box1 info_box2 padd_15">
                                  <h3 className="des_head"><Translate>Delivery Methods:</Translate></h3>                 
                                  <div className="row margin_2 info_box">
                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                      <a className="info_txt"><Translate>Shipping Required:</Translate></a>
                                    </div>                    
                                    <div className="col-md-3 col-sm-3 col-xs-3">                
                                      <div className="custom_check">
                                        <label className={productDetailData.product_list.RequiresShipping === 'YES' ? "check_boxbtbn active" : "check_boxbtbn"}>
                                          <Translate>yes</Translate> 
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-3">                
                                      <div className="custom_check">
                                        <label className={productDetailData.product_list.RequiresShipping === 'NO' ? "check_boxbtbn active" : "check_boxbtbn"}>
                                          <Translate>no</Translate>  
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row margin_2 info_box">
                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                      <a className="info_txt">Pickup Available:</a>
                                    </div>                    
                                    <div className="col-md-3 col-sm-3 col-xs-3">                
                                      <div className="custom_check">
                                        <label className={productDetailData.product_list.PickupAvailable === 'YES' ? "check_boxbtbn active" : "check_boxbtbn"}>
                                          <Translate>yes</Translate>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-3">                
                                      <div className="custom_check">
                                        <label className={productDetailData.product_list.PickupAvailable === 'NO' ? "check_boxbtbn active" : "check_boxbtbn"}>
                                          <Translate>no</Translate>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row margin_2 info_box info_Last_box">
                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                      <a className="info_txt"><Translate>Product Download:</Translate></a>
                                    </div>                    
                                    <div className="col-md-3 col-sm-3 col-xs-3">                
                                      <div className="custom_check">
                                        <label className="check_boxbtbn">
                                          <Translate>yes</Translate>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-3">                
                                      <div className="custom_check">
                                        <label className="check_boxbtbn active">
                                          <Translate>no</Translate> 
                                        </label>
                                      </div>
                                    </div>
                                  </div>                  
                                </div>
                                <div className="info_box1 info_box2 padd_15 new_tab">
                                  <h3 className="des_head"><Translate>Other products from this vendor</Translate></h3>
                                  <div className="mrgn_t10">
                                  {vendorOtherProductData.length > 0 ?
                                    <span>
                                    {vendorOtherProductData.map(({product_id,GBXProductID,ProductTitle,UserImageName},index) => <div key={index} className="rlatd_pro">
                                      <a onClick={() => this.handlePageClick(GBXProductID)} title={ProductTitle} className="cell"> <img src={productDetailData.product_list.Image_path + UserImageName} className="rlted_pro"/>   </a><span className="titleClassdata vendor_product"><Translate>{ProductTitle}</Translate></span>
                                      </div>
                                      )}</span>
                                     : <span><Translate>No other products from this vendor</Translate></span>}
                                    {vendorOtherProductData.length > 0 ?
                                    <div className="text-right mrgn_t10">
                                      <a onClick={() => jumpTo('/vendor-store/'+productDetailData.product_list.CompanyURL)} className="blue_txt semi uprcase"><Translate>See All</Translate>&nbsp;
                                        <i className="long_arrow fas fa-long-arrow-alt-right"></i>
                                      </a>
                                    </div>
                                    : ""}
                                    <div className="add_section">
                                      <h3 className="des_head">Promoted Vendor</h3>
                                      <div className="rlatd_pro"><a onClick={() => jumpTo('/vendor-store/Vodka_Templar')} title="Vodka Templar" className="cell">
                                      <img src="https://globalbox.world/product/productChangeIMG/1577962929Presentation vodka templar Wine and Sipirits Club1-page-002.jpg.jpg" className="rlted_pro"/> </a><span className="vendor_product"><Translate>Vodka Templar</Translate></span></div>
                                    </div>
                                  </div>
                                </div>             



                          </div>


                        </div>
                    </div>
                </div>
              </div>



              <div className="related_product">
                <h4 className="related_heading"><Translate>Related Products</Translate></h4>
                <div className="row margin_10">
                    {reletedProduct.length > 0 ?
                      <span>
                      {reletedProduct.map(({product_id,GBXProductID,ProductTitle,UserImageName},index) =><div className="col-sm-6 col-md-4 col-lg-3" key={index}> <div  className="rlatd_pro">
                      <a onClick={() => this.handlePageClick(GBXProductID)} title={ProductTitle} className="titleClassdata cell"> <img src={productDetailData.product_list.Image_path + UserImageName} className="rlted_pro"/>   </a><span className="titleClassdata vendor_product"><Translate>{ProductTitle}</Translate></span>
                </div>
              </div>
                      )}</span>: <span><Translate>No Related Product</Translate></span>}
                </div>
                {reletedProduct.length > 0 ?
                <div className="text-right mrgn_t10">
                  {productDetailData.product_list.Category.firstCategoryId !=='' && productDetailData.product_list.Category.secondCategoryId ==='' && productDetailData.product_list.Category.thirdCategoryId ==='' && productDetailData.product_list.Category.fourthCategoryId ===''?
                  <a className="blue_txt semi uprcase" onClick={() => jumpToData('/advance-search/',{category:productDetailData.product_list.Category.firstCategoryId,name:'category',code:productDetailData.product_list.Category.firstCategoryId})}> See More Related Products <i className="fas fa-long-arrow-alt-right"></i> </a>
                  :""}
                  {productDetailData.product_list.Category.secondCategoryId !=='' && productDetailData.product_list.Category.thirdCategoryId ==='' && productDetailData.product_list.Category.fourthCategoryId ==='' ?
                  <a className="blue_txt semi uprcase" onClick={() => jumpToData('/advance-search/',{category:productDetailData.product_list.Category.firstCategoryId,name:'category',subcategoryone:productDetailData.product_list.Category.secondCategoryId,code:productDetailData.product_list.Category.firstCategoryId})}><Translate>See More Related Products</Translate><i className="fas fa-long-arrow-alt-right"></i> </a>
                  :""}
                  {productDetailData.product_list.Category.thirdCategoryId !=='' && productDetailData.product_list.Category.fourthCategoryId ==='' ?
                  <a className="blue_txt semi uprcase" onClick={() => jumpToData('/advance-search/',{category:productDetailData.product_list.Category.firstCategoryId,name:'category',subcategoryone:productDetailData.product_list.Category.secondCategoryId,subcategorytwo:productDetailData.product_list.Category.thirdCategoryId,code:productDetailData.product_list.Category.firstCategoryId})}><Translate>See More Related Products</Translate><i className="fas fa-long-arrow-alt-right"></i> </a>
                  :""}
                  {productDetailData.product_list.Category.fourthCategoryId !=='' ?
                  <a className="blue_txt semi uprcase" onClick={() => jumpToData('/advance-search/',{category:productDetailData.product_list.Category.firstCategoryId,name:'category',subcategoryone:productDetailData.product_list.Category.secondCategoryId,subcategorytwo:productDetailData.product_list.Category.thirdCategoryId,subcategorythree:productDetailData.product_list.Category.fourthCategoryId,code:productDetailData.product_list.Category.firstCategoryId})}><Translate>See More Related Products</Translate><i className="fas fa-long-arrow-alt-right"></i> </a>
                  :""}
                </div>
                :""}
              </div>
            </div>
           <FooterContainer />
          </Translator>
          :
              <span>
                   <div align="center" className="loadingDataDetail">
                    <img src={loaderImg} height="240px" width="180px"/>
                    <br/>
                   </div>
                </span>
        }
        {/* Footer */}
         

      </span>
    )
  }
}





