import {
  GET_SUB_CATEGORY_BEGIN,
  GET_SUB_CATEGORY_SUCCESS,
  GET_SUB_CATEGORY_FAIL
} from '../action/subcategoryAction'

const initialState = {
  loading: false,
  subcategorys: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SUB_CATEGORY_BEGIN:
      return {
        loading: true,
        error: null
      }
    case GET_SUB_CATEGORY_SUCCESS:
      return {
        loading: false,
        subcategorys: action.payload.data
      }
    case GET_SUB_CATEGORY_FAIL:
      return {
        loading: false,
        error: action.payload.data
      }
    default:
      return state
  }
}