import React, { Component } from 'react'
import './stylesheets/footer.css'
import jumpTo from '../../modules/Navigation'
import {isMobile} from 'react-device-detect';
import serverUrl from '../../configs/constant'
import {Translator, Translate} from 'react-auto-translate';


export default class Footer extends Component {

  constructor(props) {
    super(props);
        let requestdata = window.location.href;
        let requestdataMain = requestdata.split("/");
        var urlData = requestdataMain[3];
    this.state = {
      selectedLanguage:localStorage.getItem('selectedLanguage'),
      preselectedLanguage:localStorage.getItem('preselectedLanguage'),
      urlData:urlData,
    };
  }

  render() {

     const newDate = new Date();
     const thisYear = newDate.getFullYear();

     const FedExlogo = require('../../assets/images/FedEx_logo.jpg');
     const Paytmforbusinessapp = require('../../assets/images/Paytm-for-business-app.jpg');
     const masterCard = require('../../assets/images/1529744317274.png');
     const VISALogo = require('../../assets/images/VISA_Logo.png');
     const InstagramImg = require('../../assets/images/instagramRound.png');
     const FaceBookImg  = require('../../assets/images/facebook-rounded-small.png');
     const LinkDinImg = require('../../assets/images/linkedin-rounded-small.png');
     const youtubeImg = require('../../assets/images/youtube-rounded-small.png');
     const emailImg = require('../../assets/images/email-rounded-small.png');
     const whatsAppImg = require('../../assets/images/whatsapp-rounded-small.png');
     const weChatImg = require('../../assets/images/wechat-rounded-small.png');
     const twitterImg = require('../../assets/images/x-twitter.png');
     const rugby = require('../../assets/images/Rugby-Romania-Logo.png');
     const globalcom = require('../../assets/images/globalcom.jpeg');
      
    return (
        <span>
        <Translator 
                from={this.state.preselectedLanguage}
                to={this.state.selectedLanguage}
                googleApiKey={serverUrl.googleApiTranslation}
              >
              <div className="partner_slogan">
                {this.state.urlData !== '' ?
              <div className="partner_footer_label">
                <p className="partner_contentCookies">
                  OFFICIAL PARTNER OF THE ROMANIAN RUGBY FEDERATION
                </p>
              </div>
              :""}
              </div>
          <div className="below_hombann">
            <div className="container ">
              <div className="row">
                    <div className="col-xs-6 text-right first_slogn">
                        <div className="slogn_txt first_slogndata">
                        {isMobile === false ?
                            <Translate>Our Platform - Your Business</Translate>
                            :
                            <Translate>Our Platform Your Business</Translate>
                        }
                          </div>
                      </div>
                      <div className="col-xs-6 sec_slogn">
                        <div className="slogn_txt">
                        {isMobile === false ? 
                            <Translate>Your Success - Our Priority</Translate>
                            :
                            <Translate>Your Success  Our Priority</Translate>
                        }

                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="footer_mainlink">
            <div className="container" >
              <span href="#" title="BUY" className="main_fea bdr_nn">
                 <Translate> BUY</Translate>
              </span>
              <span href="#" className="main_fea" title="SELL">
                  <Translate>SELL</Translate>
              </span>
              <span href="#" className="main_fea" title="PROMOTE">
                  <Translate>PROMOTE</Translate>
              </span>
              <br/>
              <span href="#" className="main_fea" title="ANYTHING">
                  <Translate>ANYTHING*</Translate>
              </span>
              <span className="main_fea" href="#" title="ANYTIME ">
                  <Translate>ANYTIME</Translate>
              </span>
              <span href="#" title="ANYWHERE" className="main_fea">
                  <Translate>ANYWHERE</Translate>
              </span>
              <p className="foot_txt"> * <strong>{serverUrl.appname}</strong> <Translate>will not permit or tolerate the commercialization of any illegal or inappropriate items </Translate></p>
            </div>
          </div>
          <footer className="footer">
            <div className="container" >
            {isMobile === false ?
              <div className="row sm_space">
                <div className="col-sm-5 col-md-5 SocialBtn">
                  <div className="foot_box linkbox"> <a  className="foot_link"><Translate>Home</Translate></a> <a onClick={() => jumpTo('/about-us')} className="foot_link"><Translate>About us</Translate></a> <a onClick={() => jumpTo('/terms-condition')} className="foot_link"><Translate>Terms Of Use</Translate></a> <a onClick={() => jumpTo('/contact-us')} className="foot_link"><Translate>Contact us</Translate></a><a onClick={() => jumpTo('/privacy-policy')} className="foot_link"><Translate>Privacy Policy</Translate></a></div>
                </div>
                  <div className="col-sm-2 col-md-2 text-center social_con SocialLogo">
                    <div className="social_box"> 
                      <a href="https://www.twitter.com/OfficialGlobalBox/" title="twitter" className="soacil_link"><img src={twitterImg} width="36" height="30"/></a> 
                      <a href="" title="WhatsApp coming soon" className="soacil_link"><img src={whatsAppImg} width="36" height="30"/></a>
                      <a href="https://www.instagram.com/globalbox_world/?hl=en" title="instagram" className="soacil_link"><img src={InstagramImg} width="36" height="30"/></a> 
                      <a href="https://www.linkedin.com/company/globalbox-world" title="linkedin" className="soacil_link"><img src={LinkDinImg} width="36" height="30"/></a> 
                      <a href="https://www.facebook.com/OfficialGlobalBox/" title="facebook" className="soacil_link"><img src={FaceBookImg} width="36" height="30"/> </a> 
                      <a href="https://www.youtube.com/channel/UC-jROZQGqqDkqltU-qHeLmw" title="youtube" className="soacil_link"><img src={youtubeImg} width="36" height="30"/></a>
                      <a href="" title="WeChat coming soon" className="soacil_link"><img src={weChatImg} width="36" height="30"/></a>
                      <a href="" title="email" className="soacil_link"><img src={emailImg} width="36" height="30"/></a>
                    </div>
                  </div>
                <div className="col-sm-5 col-md-5 text-right Socialright">
                  <div className="foot_box copy_text"> © 2015-{thisYear} {serverUrl.servername} All rights reserved </div>
                </div>
              </div>
              :
              <div className="row sm_space">
                <div className="col-sm-5 col-md-5 ">
                  <div className="foot_box linkbox"> <a  className="foot_link"><Translate>Home</Translate></a> <a onClick={() => jumpTo('/about-us')} className="foot_link"><Translate>About us</Translate></a> <a onClick={() => jumpTo('/terms-condition')} className="foot_link"><Translate>Terms Of Use</Translate></a> <a onClick={() => jumpTo('/contact-us')} className="foot_link"><Translate>Contact us</Translate></a><a onClick={() => jumpTo('/privacy-policy')} className="foot_link"><Translate>Privacy Policy</Translate></a></div>
                </div>
                  <div className="col-sm-2 col-md-2 text-center social_con ">
                    <div className="social_box"> 
                      <a href="https://www.twitter.com/OfficialGlobalBox/" title="twitter" className="soacil_link"><img src={twitterImg} width="36" height="30"/></a> 
                      <a href="" title="WhatsApp coming soon" className="soacil_link"><img src={whatsAppImg} width="36" height="30"/></a>
                      <a href="https://www.instagram.com/globalbox_world/?hl=en" title="instagram" className="soacil_link"><img src={InstagramImg} width="36" height="30"/></a> 
                      <a href="https://www.linkedin.com/company/globalbox-world" title="linkedin" className="soacil_link"><img src={LinkDinImg} width="36" height="30"/></a> 
                      <a href="https://www.facebook.com/OfficialGlobalBox/" title="facebook" className="soacil_link"><img src={FaceBookImg} width="36" height="30"/> </a> 
                      <a href="https://www.youtube.com/channel/UC-jROZQGqqDkqltU-qHeLmw" title="youtube" className="soacil_link"><img src={youtubeImg} width="36" height="30"/></a>
                      <a href="" title="WeChat coming soon" className="soacil_link"><img src={weChatImg} width="36" height="30"/></a>
                      <a href="" title="email" className="soacil_link"><img src={emailImg} width="36" height="30"/></a>
                    </div>
                  </div>
                <div className="col-sm-5 col-md-5 text-right ">
                  <div className="foot_box copy_text"> © 2015-{thisYear} {serverUrl.servername} All rights reserved </div>
                </div>
              </div>
            }
            </div>
          </footer>
          <footer className="footer_new">
            <div className="container ">
              <div className="row sm_space">
                <div className="col-sm-5 col-md-4">
                  <h4><Translate>Our Trusted Partners</Translate></h4>
                </div>
                <div className="col-sm-3 col-md-4 text-center social_con">
                  <div className="social_box_other">
                   <a href="#" title="FedEx" className="soacil_link_new"><img src={FedExlogo} width="72" height="41" /></a>
                   <a href="#" title="PayTm" className="soacil_link_new"><img src={Paytmforbusinessapp} width="72" height="41" /></a>
                   <a href="#" title="Master Card" className="soacil_link_new"><img src={masterCard} width="72" height="41" /></a>
                   <a href="#" title="Visa" className="soacil_link_new"><img src={VISALogo} width="72" height="41" /></a>
                   <a href="https://www.rugbyromania.ro/" title="Rugby Romania" className="soacil_link_new"><img src={rugby} width="72" height="41" /></a>
                   <a href="https://www.gcci.live/" title="Global Chamber of Commerce & Industry" className="soacil_link_new"><img src={globalcom} width="72" height="41" /></a>

                  </div>
                </div>
              </div>
            </div>
          </footer>
          </Translator>
        </span>  
    )
  }
}



