import serverCall from '../../modules/serverCall'

export const postCookieData=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_COOKIE_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/home/cookies-condition`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_COOKIE_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_COOKIE_FAIL,
      payload: error
    })
    return error
  })
}

export const GET_COOKIE_BEGIN = 'GET_COOKIE_BEGIN'
export const GET_COOKIE_SUCCESS='GET_COOKIE_SUCCESS'
export const GET_COOKIE_FAIL='GET_COOKIE_FAIL'