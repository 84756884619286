import {
  GET_MENU_BEGIN,
  GET_MENU_SUCCESS,
  GET_MENU_FAIL
} from '../action/menuAction'

const initialState = {
  loading: false,
  menus: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MENU_BEGIN:
      return {
        loading: true,
        error: null
      }
    case GET_MENU_SUCCESS:
      return {
        loading: false,
        menus: action.payload.data
      }
    case GET_MENU_FAIL:
      return {
        loading: false,
        error: action.payload.error.response.data
      }
    default:
      return state
  }
}