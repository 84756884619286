import serverCall from '../../modules/serverCall'

export const getBannerData=(requestdata)=>dispatch=>{
  dispatch({
    type:GET_TOPBAR_BEGIN,
  })
  return serverCall({
    method:'POST',
    url:`/api/marketplace/featured-vendor`,
    data:requestdata
  })
  .then(res=>{
    dispatch({
      type: GET_TOPBAR_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_TOPBAR_FAIL,
      payload: {error}
    })
    return error
  })
}

export const GET_TOPBAR_BEGIN = 'GET_TOPBAR_BEGIN'
export const GET_TOPBAR_SUCCESS='GET_TOPBAR_SUCCESS'
export const GET_TOPBAR_FAIL='GET_TOPBAR_FAIL'